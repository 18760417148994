import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthHelper} from '../helpers/auth-helper';
import {LangHelper} from '../helpers/lang-helper';

import {AuthService} from './auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api.instagram.com')) {
      return next.handle(req.clone());
    }
    req = req.clone({
      setHeaders: {
        // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': LangHelper.getLang()
      }
    });

    return next.handle(req);
  }
}
