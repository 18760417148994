import {zip as observableZip, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Collection} from '../race-sims/models/collection.model';
import {Translation} from './models/translation.model';
import {HttpClient} from '@angular/common/http';
import {AppDataServcice} from './services/app-data.service';

import {SettingIndex} from '../race-sims/models/settings/setting.index.model';
import {LanguageService} from './services/language.service';
import {BookingService} from '../race-sims/services/booking.service';
import {AcademyLocation} from '../race-sims/models/academy-location.model';

export class KeyVal {
  key: string;
  val: string;

  constructor(key, val) {
    this.key = key;
    this.val = val;
  }
}

@Injectable()
export class AppDataProvider {
  private translations: any;
  private settings: Collection<SettingIndex>;
  private locations: Collection<AcademyLocation>;

  constructor(private http: HttpClient, private service: AppDataServcice, private bookingService: BookingService, private langService: LanguageService) {
  }

  public static getValByKey(array: KeyVal[], key: string) {
    for (const item of array) {
      if (item.key === key) {
        return item.val;
      }
    }
    return null;
  }

  public getTranslations(): any {
    return this.translations;
  }

  public getTranslation(key: string) {
    const tokens = key.split('.');
    const cat = tokens[0];
    const subCat = tokens[1];
    const itemKey = tokens[2];
    // console.log(cat + ' ' + subCat + ' ' + itemKey);
    // console.log(this);
    if (this.translations.hasOwnProperty(cat) &&
      this.translations[cat].hasOwnProperty(subCat) &&
      this.translations[cat][subCat].hasOwnProperty(itemKey)
    ) {
      return this.translations[cat][subCat][itemKey];
    } else {
      console.error('undefined translation key: ' + key);
      return undefined;
    }
  }

  public getSettings(): Collection<SettingIndex> {
    return this.settings;
  }

  public getSetting(key: string): string {
    for (const setting of this.settings.data) {
      if (setting.key === key) {
        return setting.value;
      }
    }
    console.error('missing setting: ' + key);
    return '';
  }

  public getSettingGroup(key: string): KeyVal[] {
    const res = [];
    for (const setting of this.settings.data) {
      if (setting.key.substring(0, key.length) === key) {
        res.push(new KeyVal(setting.key, setting.value));
      }
    }
    return res;
  }

  public settingExists(key: string) {
    for (const setting of this.settings.data) {
      if (setting.key === key) {
        return true;
      }
    }
    return false;
  }

  private parseTranslations(translations: Collection<Translation>): any {
    const array = translations.data;
    const parsed = {};
    for (const item of array) {
      const splitIndex = item.category_key.lastIndexOf('.');
      const cat = item.category_key.substr(0, splitIndex);
      const subCat = item.category_key.substr(splitIndex + 1, item.category_key.length);
      if (parsed.hasOwnProperty(cat)) {
        if (parsed[cat].hasOwnProperty(subCat)) {
          parsed[cat][subCat][item.key] = item.value;
        } else {
          parsed[cat][subCat] = {};
          parsed[cat][subCat][item.key] = item.value;
        }
      } else {
        parsed[cat] = {};
        parsed[cat][subCat] = {};
        parsed[cat][subCat][item.key] = item.value;
      }
    }
    return parsed;
  }

  load() {
    this.langService.languageChanged$.subscribe(() => this.load());
    return new Promise((resolve, reject) => {
      observableZip(
        this.service.settings(),
        this.service.siteItems(),
        this.bookingService.locations()
      ).subscribe(res => {
        this.settings = res[0];
        this.translations = this.parseTranslations(res[1]);
        this.locations = res[2];
        this.clearStorageAfterAppUpdate();
        resolve(true);
      });
    });
  }

  public getLocations() {
    return this.locations.data;
  }

  private clearStorageAfterAppUpdate() {
    const version = this.getSetting('app_version');
    if (version !== '') {
      const storedVersion = localStorage.getItem('app_version');
      if (storedVersion === null || storedVersion !== version) {
        localStorage.clear();
        localStorage.setItem('app_version', version);
      }
    }
  }

}
