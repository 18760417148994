import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';
import {Observable} from 'rxjs';
import {Collection} from '../../../race-sims/models/collection.model';
import {CourseDetail} from '../../courses/course-detail.model';
import {IndexService} from '../../../race-sims/shared/services/index.service';

@Injectable()
export class CourseService implements IndexService<CourseDetail> {

  private baseUrl = `${AppConfig.serverUrl}/api/courses`;

  usePublicApi() {
    this.baseUrl = `${AppConfig.serverUrl}/api/v1/courses`;
  }

  usePrivateApi() {
    this.baseUrl = `${AppConfig.serverUrl}/api/courses`;
  }

  constructor(private http: HttpClient) {
  }

  all(): Observable<Collection<CourseDetail>> {
    return this.http.get<Collection<CourseDetail>>(this.baseUrl);
  }

  post(model): Observable<CourseDetail> {
    return this.http.post<CourseDetail>(this.baseUrl, model);
  }

  put(model): Observable<CourseDetail> {
    return this.http.put<CourseDetail>(`${this.baseUrl}/${model.id}`, model);
  }

  destroy(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  get(id: number): Observable<CourseDetail> {
    return this.http.get<CourseDetail>(`${this.baseUrl}/${id}`);
  }
}
