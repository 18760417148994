import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {isNull, isNullOrUndefined} from 'util';
import {AppDataProvider} from '../common/app-data.provider';

export class SiteBase {

  baseFlagDir = 'img/flags/';
  baseFlagExt = '.svg';

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
  }

  translate(key: string): string {
    return this.provider.getTranslation(key);
  }

  // TODO: URLS

  safeUrl(key: string): SafeUrl {
    // return this.sanitizer.bypassSecurityTrustUrl('http://via.placeholder.com/50x50');
    const res = this.translate(key);
    if (isNullOrUndefined(res)) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustUrl(res);
  }


  safeResourceUrl(key: string): SafeResourceUrl {
    // return this.sanitizer.bypassSecurityTrustResourceUrl('http://via.placeholder.com/50x50');
    const res = this.translate(key);
    if (isNullOrUndefined(res)) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(res);
  }

  getCountryFlag(country: string): SafeUrl {
    // return this.sanitizer.bypassSecurityTrustUrl('img/ico-flag-de.svg');
    let img = 'en';
    switch (country.toLowerCase()) {
      case 'de':
        img = '162-germany';
        break;
      case 'en':
        img = '260-united-kingdom';
        break;
      default:
        img = '162-germany';
    }

    const url = this.baseFlagDir + img + this.baseFlagExt;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
