import {AfterViewInit, Component, forwardRef, OnInit} from '@angular/core';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {BookingSettingService} from '../../../../common/simulator-booking/services/booking-setting.service';
import {PriceMetaService} from '../../../../common/simulator-booking/services/meta/price-meta.service';
import {BookingServiceConnectors} from '../../../../common/simulator-booking/booking-service-connectors';

@Component({
  selector: 'app-price-meta',
  templateUrl: './price-meta.component.html',
  styleUrls: ['./price-meta.component.less']
})
export class PriceMetaComponent implements OnInit, AfterViewInit {

  private service: PriceMetaService;

  t = {
    label: 'label'
  };

  price: string;

  constructor(private managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    this.service = new PriceMetaService(this.managerService, this.bookingSettingService);
    // this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.initTranslations();
  }

  initTranslations(){
    this.t.label = this.service.getLabel();
  }

  ngOnInit() {
    this.reload();
    this.bindReloadHandler();
  }

  bindReloadHandler() {
    this.service.isComponentReloadNeeded.subscribe(() => this.reload());
  }

  reload() {
    // console.log('reloading');
    this.price = this.service.getTotalPrice();
  }

  ngAfterViewInit() {

  }
}
