import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {BookingSettingService} from '../booking-setting.service';
import {PriceMetaValue} from '../../models/meta/values/price-meta-value.model';
import {Subject} from 'rxjs/internal/Subject';
import {PriceMetaInterface} from './contracts/price-meta.interface';
import {AmountMetaInterface} from './contracts/amount-meta.interface';
import {CountMetaInterface} from './contracts/count-meta.interface';
import {LangHelper} from '../../../../race-sims/helpers/lang-helper';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';

export interface PriceMetaContract {
  key: string;
  price: number;
  is_multiplied_by_amount: boolean;
  is_multiplied_by_booking_count: true;
  translations: {
    en: {
      label: string
    },
    de: {
      label: string
    }
  };
}

export class PriceMetaService extends CommonMetaService<PriceMetaContract, PriceMetaValue>
  implements PriceMetaInterface, ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.price;
  static changeEventIdentifier = 'meta.' + PriceMetaService.metaType + '.change';

  private listeners: { name: string, handler: any }[] = [];
  private componentReloadSubject = new Subject();
  isComponentReloadNeeded = this.componentReloadSubject.asObservable();

  constructor(protected managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    super(managerService);
    this.managerService.metaManager.registerService(this, this.getMetaType());
    this.activateListeners();
    this.setMetaValue(new PriceMetaValue(this.getPrice()), false);
  }

  static getPriceFromMetaValue(metaValue) {
    return metaValue.price;
  }

  getChangeEventIdentifier(): string {
    return PriceMetaService.changeEventIdentifier;
  }

  getMetaType(): string {
    return PriceMetaService.metaType;
  }

  getLabel() {
    return this.getTranslatedAttr('label');
  }

  getTotalPrice(): string {
    const priceMetas = this.managerService.metaManager.getPriceMetas();
    let price = 0;
    for (const priceMeta of priceMetas) {
      const priceMetaService = this.managerService.metaManager.getService<PriceMetaInterface>(priceMeta.type);
      price += priceMetaService.getPrice();
    }
    return LangHelper.getLocalizedNumberFormatter().format(price);
  }

  induceComponentReload() {
    this.componentReloadSubject.next();
  }

  getPrice(isChecked = null): number {
    const setting = this.getMetaSetting();
    let price = setting.price;
    if (setting.is_multiplied_by_amount) {
      const amountType = this.managerService.metaManager.getAmountMetaType();
      const amountMetaService = this.managerService.metaManager.getService<AmountMetaInterface>(amountType);
      const amount = amountMetaService.getAmount();
      price *= amount;
    }
    if (setting.is_multiplied_by_booking_count) {
      const countType = this.managerService.metaManager.getCountMetaType();
      const countMetaService = this.managerService.metaManager.getService<CountMetaInterface>(countType);
      const count = countMetaService.getCount();
      price *= count;
    }
    this.setMetaValueSilently(new PriceMetaValue(price));
    return price;
  }

  setMetaValueSilently(val) {
    this.managerService.metaManager.setMeta(this.getMetaType(), val).then();
  }

  activateListeners() {
    const amountMetaType = this.managerService.metaManager.getAmountMetaType();
    const countMetaType = this.managerService.metaManager.getCountMetaType();
    const priceMetaTypes = this.managerService.metaManager.getPriceMetas();

    const amountMetaChangeIdentifier = this.managerService.metaManager
      .getService<AmountMetaInterface>(amountMetaType).getChangeEventIdentifier();
    const countMetaChangeIdentifier = this.managerService.metaManager
      .getService<CountMetaInterface>(countMetaType).getChangeEventIdentifier();
    this.addListener(amountMetaChangeIdentifier);
    this.addListener(countMetaChangeIdentifier);

    for (const priceMeta of priceMetaTypes) {
      const changeIdentifier = this.managerService.metaManager
        .getService<PriceMetaInterface>(priceMeta.type).getChangeEventIdentifier();
      this.addListener(changeIdentifier);
    }
  }

  addListener(eventName: string) {
    const handler = () => this.handleListenedEvent();
    document.addEventListener(eventName, handler);
    this.listeners.push({name: eventName, handler: handler});
  }

  handleListenedEvent() {
    // console.log('handling listened event');
    this.componentReloadSubject.next();
  }

  isValid() {
    return this.getMetaValue().price !== undefined;
  }
}
