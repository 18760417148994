import {BookingCustomer} from '../models/booking-customer.model';
import {BookingCreate, BookingCreateEvent} from '../models/booking-create.model';

export class BookingCreateBuilder {

  private _type: string;
  private _location: number;
  private _date: string;
  private _simulators: number;
  private _price: number;
  private _customer: BookingCustomer;
  private _times: number[];

  private constructor() {
  }

  static get() {
    return new BookingCreateBuilder();
  }

  type(type: string): BookingCreateBuilder {
    this._type = type;
    return this;
  }

  location(location: number): BookingCreateBuilder {
    this._location = location;
    return this;
  }

  date(date: string): BookingCreateBuilder {
    this._date = date;
    return this;
  }

  simulators(amount: number): BookingCreateBuilder {
    this._simulators = amount;
    return this;
  }

  price(price: number): BookingCreateBuilder {
    this._price = price;
    return this;
  }

  customer(customer: BookingCustomer): BookingCreateBuilder {
    this._customer = customer;
    return this;
  }

  times(times: number[]): BookingCreateBuilder {
    this._times = times;
    return this;
  }

  build(): BookingCreate {
    const booking = new BookingCreate();
    // booking.simulator_amount = this._simulators;
    // booking.price = this._price;
    booking.customer = this._customer;
    booking.type = this._type;
    // booking.events = this.buildEvents();
    return booking;
  }

  private buildEvents(): BookingCreateEvent[] {
    const events: BookingCreateEvent[] = [];
    for (const time of this._times) {
      const event = new BookingCreateEvent();
      event.date = this._date;
      event.type = this._type;
      event.location = {id: this._location};
      event.event_time = {hour: time};
      events.push(event);
    }
    return events;
  }
}
