import {HttpErrorResponse} from '@angular/common/http';
import {AppConfig} from '../../app.config';

declare const $: any;

export enum NotificationType {
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Dark = 'dark',
}

export class NotificationHelper {

  static bindClick(selector: string, title: string, message: string, type: NotificationType) {
    $(document).on('click', selector, function () {
      NotificationHelper.popNotification(title, message, type);
    });
  }

  static popNotification(title: string, message: string, type: NotificationType) {
    $.gritter.add({
      title: title,
      text: message,
      time: AppConfig.notificationDisplayLength,
      class_name: `color ${type}`
    });
  }

  static popError(error: HttpErrorResponse) {
    const e = error.error;
    // let message = 'There were errors while processing the request:';
    const message = NotificationHelper.parseErrorResponseMessage(e, '<br>');
    NotificationHelper.popNotification('Error!', message, NotificationType.Danger);

  }

  static popSuccess() {
    NotificationHelper.popNotification('Success!', 'Action has been performed successfully.', NotificationType.Success);
  }

  static parseErrorResponseMessage(error, delimiter): string {
    let message = '';
    for (const line in error) {
      if (error.hasOwnProperty(line)) {
        message += error[line] + delimiter;
      }
    }
    return message;
  }
}
