import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../site-base';
import {DomSanitizer, Title, Meta} from '@angular/platform-browser';
import {AppDataProvider} from '../../common/app-data.provider';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']

})
export class ContactComponent extends SiteBase implements OnInit {

  constructor(public meta: Meta, private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('meta.contact.title'));
    this.meta.updateTag({ name: 'title', content:this.translate('meta.contact.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.contact.description') });
}

}
