import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ScriptHelper} from '../../shared/helpers/scripts.helper';
import {SiteBase} from '../site-base';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {AppDataProvider} from '../../common/app-data.provider';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent extends SiteBase implements OnInit, AfterViewInit {

  constructor(private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private meta: Meta) {
    super(provider, sanitizer);
  }

  ngOnInit() {
      this.titleService.setTitle(this.translate('meta.about_us.title'));
      this.meta.updateTag({ name: 'title', content:this.translate('meta.about_us.name')});
      this.meta.updateTag({ name: 'description', content: this.translate('meta.about_us.description') });
}

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }

}
