import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfiguratorDataManager} from '../configurator.data-manager';
import {ConfiguratorFormManager} from '../configurator.form-manager';
import {ConfiguratorBase} from '../configurator-base';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {OrderCreate} from '../../../race-sims/models/orders/order-create.model';
import {OrderService} from '../../../race-sims/services/order.service';
import {ProductService} from '../../../race-sims/services/product.service';
import {NotificationHelper} from '../../../race-sims/helpers/notification.helper';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-configurator-step-4',
  templateUrl: './configurator-step-4.component.html',
  styleUrls: ['./configurator-step-4.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,

  animations: [
    trigger('state', [
      state('active', style({height: '*', opacity: 1})),
      state('inactive', style({height: 0, opacity: 0})),
      transition('active => inactive', [
        animate('0.2s 0.1s ease-out', style({opacity: 0})),
        animate(250, style({height: 0}))
      ]),
      transition('inactive => active', [
        animate(250, style({height: '*'})),
        animate('0.2s 0.1s ease-in', style({opacity: 1}))

      ])
    ])
  ]

})
export class ConfiguratorStep4Component extends ConfiguratorBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('configurator.form.title'),
    totalCost: this.translate('configurator.form.total_cost'),
    selectedOptions: this.translate('configurator.form.selected_options'),
    stepTitle: this.translate('configurator.form.step_4_title'),
    stepSubtitle: this.translate('configurator.form.step_4_subtitle'),
    prev: this.translate('configurator.form.previous_step'),
    next: this.translate('configurator.form.next_step'),

    shipping: this.translate('configurator.form.choose_shipping'),
    payment: this.translate('configurator.form.choose_payment_method'),
    shippingCost: this.translate('configurator.form.shipping_cost'),

    billingDetails: this.translate('configurator.form.billing_details'),
    name: this.translate('configurator.form.name'),
    surname: this.translate('configurator.form.surname'),
    mail: this.translate('configurator.form.email'),
    country: this.translate('configurator.form.country'),
    city: this.translate('configurator.form.city'),
    street: this.translate('configurator.form.street'),
    streetNo: this.translate('configurator.form.street_number'),
    zip: this.translate('configurator.form.zip'),
    company: this.translate('configurator.form.company_name'),
    vat: this.translate('configurator.form.vat_number'),
    phoneNum: this.translate('configurator.form.phone_number'),
    phoneCode: this.translate('configurator.form.phone_country_code'),

    deliverOnDifferentAddress: this.translate('configurator.form.deliver_on_different_address'),
    tax: this.translate('configurator.form.tax')

  };

  @Input() dataManager: ConfiguratorDataManager;
  @Input() formManager: ConfiguratorFormManager;
  @Input() imgUrl: string;

  @Output() next = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();
  @Output() onImgUrlChanged = new EventEmitter<string>();

  public paymentSelectId = 'sel-payment';
  public deliverySelectId = 'sel-delivery';
  private $delivery: any;
  private $payment: any;
  differentDeliveryHidden: boolean;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private service: OrderService, private productService: ProductService ) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const that = this;
    this.differentDeliveryHidden = this.formManager.isDeliverySelected('personal');
    this.$delivery = $(`#${this.deliverySelectId}`).selectpicker();
    this.$payment = $(`#${this.paymentSelectId}`).selectpicker();

    this.$payment.on('changed.bs.select', function (e, clickedIndex) {
      that.formManager.setPayment(that.formManager.paymentTypes[clickedIndex].value);
    });

    this.$delivery.on('changed.bs.select', function (e, clickedIndex) {
      that.formManager.setDelivery(that.formManager.deliveryTypes[clickedIndex].value);
      that.differentDeliveryHidden = that.formManager.isDeliverySelected('personal');
      if (that.differentDeliveryHidden) {
        that.formManager.differentDelivery = false;
      }
      setTimeout(function () {

        that.$payment.selectpicker('refresh');
      }, 50);
    });
  }


  actionNext(e) {
    e.preventDefault();
    this.formManager.store();
    this.formManager.createDraft(this.service, () => super.actionNext(e), (err) => this.onCreateDraftError(err));
  }

  onCreateDraftError(e) {
    const msg = NotificationHelper.parseErrorResponseMessage(e.error, '\n');
    alert(msg);
  }

  actionPrev(e) {
    this.formManager.store();
    super.actionPrev(e);
  }

  disabled() {
    // return 5 === 6;
  }

  /**
   * Overriding base to skip img DOM changes
   */
  actionLoadConfigurationImage() {
    // const url = this.dataManager.getConfigurationImageUrl(this.formManager.getConfigurationCode());
    this.imgUrlChanged();
  }
}
