import {AvailabilitySchedule} from './availability-schedule.interface';

export class InDayHourlyBookingAvailabilityScheduleItem {
  hour: number;
  available_simulators: number;
  text_interval: string;
}

export class InDayHourlyBookingAvailabilitySchedule implements AvailabilitySchedule {
  availableList: InDayHourlyBookingAvailabilityScheduleItem[];

}
