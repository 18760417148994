import {Component, OnInit} from '@angular/core';
import {SiteBase} from "../../site-base";
import {DomSanitizer} from "@angular/platform-browser";
import {AppDataProvider} from "../../../common/app-data.provider";

@Component({
  selector: 'app-sae-models',
  templateUrl: './sae-models.component.html',
  styleUrls: ['./sae-models.component.less']
})
export class SaeModelsComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
