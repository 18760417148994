import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-home-academy',
  templateUrl: './home-academy.component.html',
  styleUrls: ['./home-academy.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class HomeAcademyComponent implements OnInit {

  constructor(private provider: AppDataProvider) { }

  translate(key: string): string {
    return this.provider.getTranslation(key);
  }
  ngOnInit() {
  }

}
