import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {CourseService} from '../../../common/course-bookings/services/course.service';
import {CourseDetail} from '../../../common/courses/course-detail.model';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-academy-courses',
  templateUrl: './academy-courses.component.html',
  styleUrls: ['./academy-courses.component.less'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class AcademyCoursesComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('academy.courses.title'),
    bookNow: this.translate('general.variables.btn_book_now'),
    noCourses: this.translate('academy.courses.no_courses_available')
  };

  isEmpty = true;
  courses: CourseDetail[];
  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private courseService: CourseService) {
    super(provider, sanitizer);
    this.courseService.usePublicApi();
  }

  ngOnInit() {
    this.courseService.all().subscribe(collection => this.onCourseLoadSuccess(collection));
  }

  onCourseLoadSuccess(collection) {
    const data = collection.data;
    if (data.length !== 0) {
      this.isEmpty = false;
      this.courses = data;
    }
  }

}
