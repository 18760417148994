import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SiteBase} from '../site-base';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {CourseService} from '../../common/course-bookings/services/course.service';
import {CourseBookingService} from '../../common/course-bookings/services/course-booking.service';
import {ActivatedRoute} from '@angular/router';
import {CourseDetail} from '../../common/courses/course-detail.model';
import {CourseBookingFormManager} from '../../common/course-bookings/api/course-booking.form-manager';
import {CourseBookingCreateFormManager} from '../../common/course-bookings/api/course-booking-create.form-manager';
import {CourseBookingForm} from '../../common/course-bookings/models/course-booking-form.model';
import {Form, NgForm} from '@angular/forms';
import {CourseBookingCreateFrontendFormManager} from '../../common/course-bookings/api/course-booking-create.frontend.form-manager';
import {AppDataProvider} from '../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-course-booking',
  templateUrl: './course-booking.component.html',
  styleUrls: ['./course-booking.component.less']
})
export class CourseBookingComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('booking.form.title'),
    step1: this.translate('booking.form.step_1'),
    step2: this.translate('booking.form.step_2'),
    step1Subtitle: this.translate('booking.form.step_1_subtitle'),
    step2Subtitle: this.translate('booking.form.step_2_subtitle'),
    selEvtType: this.translate('booking.form.sel_event_type'),
    selNumSims: this.translate('booking.form.sel_num_simulators'),
    selAcademy: this.translate('booking.form.sel_academy'),
    selDate: this.translate('booking.form.sel_date'),
    selTime: this.translate('booking.form.choose_time'),
    infoNote: this.translate('booking.form.info_note'),
    discountNote: this.translate('booking.form.discount_note'),
    totalPrice: this.translate('booking.form.total_price'),
    name: this.translate('booking.form.name'),
    surname: this.translate('booking.form.surname'),
    countryCode: this.translate('booking.form.country_code'),
    phoneNum: this.translate('booking.form.phone_num'),
    email: this.translate('booking.form.email'),
    aggreeTermsText: this.translate('booking.form.aggree_with_terms'),
    aggreeTermsLinkText: this.translate('booking.form.aggree_with_terms_link_text'),
    aggreeTermsLinkUrl: this.safeUrl('booking.form.aggree_with_terms_link_url'),
    noteSelectedDate: this.translate('booking.form.note_selected_date'),
    btnSendMsg: this.translate('booking.form.btn_send_message'),
    noteBookingResponse: this.translate('booking.form.note_booking_response'),

    saveSuccess: this.translate('booking.form.on_send_success_message'),
    saveError: this.translate('booking.form.on_send_fail_message')
  };

  course: CourseDetail;
  simulatorCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  formManager: CourseBookingCreateFrontendFormManager;
  simulatorsSelectId = 'num-sims-sel';
  $simulators: any;

  @ViewChild('form') form: NgForm;

  agreed = false;

  constructor(private titleService: Title,
              protected provider: AppDataProvider,
              protected sanitizer: DomSanitizer,
              private courseService: CourseService,
              private courseBookingService: CourseBookingService,
              private route: ActivatedRoute) {
    super(provider, sanitizer);

  }

  ngOnInit() {
    this.course = this.route.snapshot.data.course;
    this.courseBookingService.usePublicApi();
    this.titleService.setTitle(this.course.name + ' - ' + this.provider.getSetting('site_name'));
    this.formManager = new CourseBookingCreateFrontendFormManager();
    this.formManager.Course = this.course;
    this.formManager.Model = new CourseBookingForm(this.course.id);
    this.formManager.Form = this.form;
  }

  ngAfterViewInit() {
    const that = this;
    this.$simulators = $(`#${this.simulatorsSelectId}`).selectpicker();


    this.$simulators.on('changed.bs.select', function (e, clickedIndex) {
      that.onSimulatorsChange(clickedIndex);
    });
  }

  onSimulatorsChange(clickedIndex) {
    this.formManager.Model.simulator_amount = this.simulatorCount[clickedIndex];
    this.formManager.calculatePrice();
  }

  toggleAggreement() {
    this.agreed = !this.agreed;
  }

  submitBooking() {
    this.courseBookingService.post(this.formManager.Model).subscribe(detail => this.onSubmitSuccess(), error => this.onSubmitError(error));
  }

  onSubmitSuccess() {
    alert('Your booking was successfully submitted');
  }

  onSubmitError(error) {
    if (error.status === 422) {
      alert('The booking could not be processed. Please check that you have filled the form correctly.');
    } else {
      alert('We\'re sorry, an error occured. Please try again later.');
    }

  }
}
