import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {AppDataProvider} from '../../../app-data.provider';
import {CarClassMetaValue} from '../../models/meta/values/car-class-meta-value.model';
import {CustomStickerMetaValue} from '../../models/meta/values/custom-sticker-meta.value';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';

interface CustomStickerMetaContract {
  key: string;
  is_checked: boolean;
  translations: {
    en: {
      label: string,
      description: string
    },
    de: {
      label: string,
      description: string
    }
  };
}

export class CustomStickersMetaService extends CommonMetaService<CustomStickerMetaContract, CustomStickerMetaValue>
  implements ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.custom_stickers;
  static changeEventIdentifier = 'meta.' + CustomStickersMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService) {
    super(managerService);
    if (this.getMetaValue() === undefined) {
      this.setMetaValue(new CustomStickerMetaValue(this.getMetaSetting().is_checked));
    }
  }

  getMetaType(): string {
    return CustomStickersMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return CustomStickersMetaService.changeEventIdentifier;
  }

  getLabel() {
    return this.getTranslatedAttr('label');
  }

  getDescription() {
    return this.getTranslatedAttr('description');
  }

  isValid() {
    return this.getMetaValue().is_checked !== undefined;
  }
}
