import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {LocationMetaValue} from '../../models/meta/values/location-meta-value.model';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';
import {AmountMetaValue} from '../../models/meta/values/amount-meta-value.model';

export interface LocationMetaContract {
  key: string;
  is_hidden: boolean;
  is_forced: boolean;
  forced_location_id?: number;
  translations: {
    en: {
      label: string;
    },
    de: {
      label: string;
    }
  };
}

export class LocationMetaService extends CommonMetaService<LocationMetaContract, LocationMetaValue>
  implements ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.location;
  static changeEventIdentifier = 'meta.' + LocationMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService) {
    super(managerService);
    const val = this.getMetaValue() !== undefined ? this.getMetaValue() : new LocationMetaValue(this.getLocations()[0].id);
    this.setMetaValue(val, false);
    if (this.getMetaSetting().is_forced) {
      this.setMetaValue(new LocationMetaValue(this.getMetaSetting().forced_location_id), false);
    }
  }

  getMetaType(): string {
    return LocationMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return LocationMetaService.changeEventIdentifier;
  }

  getLabel() {
    return this.getTranslatedAttr('label');
  }

  getLocations() {
    let locations = this.managerService.dataManager.locations;
    locations = locations.filter(i => !i.is_hidden);
    return locations;
  }

  isValid() {
    return this.getMetaValue().location_id !== undefined;
  }
}
