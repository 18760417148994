import {Component, OnInit} from '@angular/core';
import {LangHelper} from '../../../race-sims/helpers/lang-helper';
import {SiteBase} from '../../../site/site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {LanguageService} from '../../../common/services/language.service';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent extends SiteBase implements OnInit {

  t = {
    btnSimulator: this.translate('general.variables.menu_simulator'),
    btnAcademy: this.translate('general.variables.menu_academy'),
    btnChampionship: this.translate('general.variables.menu_championship'),
    btnContact: this.translate('general.variables.menu_contact'),
    btnSupport: this.translate('general.variables.menu_support'),
    btnAboutUs: this.translate('about_us.landing.title'),
    btnConfigurator: this.translate('general.variables.menu_configurator'),
  };

  nextLangLower = 'de';
  nextLang = 'DE';

  window = window;

  constructor(private langService: LanguageService, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    if (LangHelper.getLang() === 'de') {
      this.nextLang = 'EN';
      this.nextLangLower = 'en';
    }
  }

  changeLang(e) {
    // e.preventDefault();
    // todo: reload data and components
    const desiredLang = LangHelper.getLang() === 'de' ? 'en' : 'de';

    LangHelper.setLang(desiredLang);
    this.langService.changeLanguage(desiredLang);
    // this.provider.load();
  }
}
