import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../site-base';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {AppDataProvider} from '../../common/app-data.provider';
declare const $: any;
@Component({
  selector: 'app-academy',
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.less'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class AcademyComponent extends SiteBase implements OnInit {

  constructor(private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('general.variables.menu_academy') + ' - ' + this.provider.getSetting('site_name'));
  }

  //
  // ngAfterViewInit() {
  //   // $('#navbarMain a').click(function () {
  //   //   $('.navbar-collapse').collapse('hide');
  //   // });
  //   //
  //   // $('#navbarMain').collapse('hide');
  //   //
  //   // $('.navbar-toggler').off().on('click', function(){
  //   //   $('#navbarMain').slideToggle();
  //   // });
  // }
}
