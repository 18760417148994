import { Component, OnInit } from '@angular/core';
import {SiteBase} from '../site-base';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {AppDataProvider} from '../../common/app-data.provider';

@Component({
  selector: 'app-championship',
  templateUrl: './championship.component.html',
  styleUrls: ['./championship.component.less']
})
export class ChampionshipComponent extends SiteBase implements OnInit {

  constructor(private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('general.variables.menu_championship') + ' - ' + this.provider.getSetting('site_name'));
  }


}
