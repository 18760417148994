import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {CustomStickerMetaValue} from '../../models/meta/values/custom-sticker-meta.value';

interface NoteMetaContract {
  key: string;
  translations: {
    en: {
      note: string
    },
    de: {
      note: string
    }
  };
}

export class NoteMetaService extends CommonMetaService<NoteMetaContract, CustomStickerMetaValue> {

  static metaType = BookingDefinitions.metaTypes.note;
  static changeEventIdentifier = 'meta.' + NoteMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService) {
    super(managerService);
  }

  getMetaType(): string {
    return NoteMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return NoteMetaService.changeEventIdentifier;
  }

  getNote() {
    return this.getTranslatedAttr('note');
  }

}
