import {Injectable} from '@angular/core';
import {AppConfig} from '../../app.config';
import {Observable} from 'rxjs';
import {Collection} from '../models/collection.model';
import {Booking} from '../models/booking.model';
import {HttpClient} from '@angular/common/http';
import {IndexService} from '../shared/services/index.service';
import {AcademyLocation} from '../models/academy-location.model';
import {AvailableTimeRequest} from '../models/available-time-request.model';
import {AvailableTimeResponse} from '../models/available-time-response.model';
import {BookingTime} from '../models/booking-time.model';
import {BookingPriceRequest} from '../models/booking-price-request.model';
import {BookingPriceResponse} from '../models/booking-price-response.model';
import {BookingCreate} from '../models/booking-create.model';
import {BookingDetail} from '../models/booking-detail.model';
import {BookingUpdate} from '../models/booking-update.model';

@Injectable()
export class BookingService implements IndexService<Booking> {


  private baseUrl = `${AppConfig.serverUrl}/api/v1/bookings`;
  private locationsBaseUrl = `${AppConfig.serverUrl}/api/v1/locations`;

  constructor(private http: HttpClient) {
  }

  all(): Observable<Collection<Booking>> {
    return this.http.get<Collection<Booking>>(this.baseUrl);
  }

  locations(): Observable<Collection<AcademyLocation>> {
    return this.http.get<Collection<AcademyLocation>>(this.locationsBaseUrl);
  }

  availableTimes(request: AvailableTimeRequest): Observable<BookingTime[]> {
    return this.http.post<BookingTime[]>(`${this.baseUrl}/get-available-hours`, request);
  }

  price(request: BookingPriceRequest): Observable<BookingPriceResponse> {
    return this.http.post<BookingPriceResponse>(`${this.baseUrl}/get-price`, request);
  }



  get(id: number): Observable<BookingDetail> {
    return this.http.get<BookingDetail>(`${this.baseUrl}/${id}`);
  }

  post(booking: BookingCreate): Observable<BookingCreate> {
    return this.http.post<BookingCreate>(`${this.baseUrl}`, booking);
  }

  put(booking: BookingUpdate): Observable<BookingDetail> {
    return this.http.put<BookingDetail>(`${this.baseUrl}/${booking.id}`, booking);
  }

  destroy(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

}
