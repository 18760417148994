import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteBase} from '../../site-base';

import {DomSanitizer} from '@angular/platform-browser';
import {ConfiguratorDataManager} from '../configurator.data-manager';
import {ConfiguratorFormManager} from '../configurator.form-manager';
import {ConfiguratorBase} from '../configurator-base';
import {isNullOrUndefined} from 'util';
import {OrderService} from '../../../race-sims/services/order.service';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-configurator-step-5',
  templateUrl: './configurator-step-5.component.html',
  styleUrls: ['./configurator-step-5.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfiguratorStep5Component extends ConfiguratorBase implements OnInit {

  t = {
    title: this.translate('configurator.form.title'),
    totalCost: this.translate('configurator.form.total_cost'),
    stepTitle: this.translate('configurator.form.step_5_title'),
    stepSubtitle: this.translate('configurator.form.step_5_subtitle'),
    prev: this.translate('configurator.form.previous_step'),
    next: this.translate('configurator.form.next_step'),
    confirm: this.translate('configurator.form.confirm'),
    shipping: this.translate('configurator.form.shipping'),
    selectedOptions: this.translate('configurator.form.selected_options'),
    selectedShipping: this.translate('configurator.form.selected_shipping'),
    selectedPayment: this.translate('configurator.form.selected_payment'),

    payment: this.translate('configurator.form.choose_payment_method'),
    shippingCost: this.translate('configurator.form.shipping_cost'),

    billingDetails: this.translate('configurator.form.billing_details'),

    deliveryAddress: this.translate('configurator.form.delivery_address'),
    pickupAddress: this.translate('configurator.form.pickup_address'),

    aggreeTermsText: this.translate('configurator.form.terms_agreement_text'),
    aggreeTermsLinkUrl: this.safeUrl('configurator.form.terms_agreement_url'),
    aggreeTermsLinkText: this.translate('configurator.form.terms_agreement_url_text'),
    tax: this.translate('configurator.form.tax')

  };

  s = {
    pickupName: this.provider.getSetting('configurator_pickup_name'),
    pickupStreet: this.provider.getSetting('configurator_pickup_street'),
    pickupStreetNo: this.provider.getSetting('configurator_pickup_street_number'),
    pickupZip: this.provider.getSetting('configurator_pickup_zip'),
    pickupCity: this.provider.getSetting('configurator_pickup_city'),
    pickupCountry: this.provider.getSetting('configurator_pickup_country')
  };

  isNullOrUndefined = isNullOrUndefined;
  @Input() dataManager: ConfiguratorDataManager;
  @Input() formManager: ConfiguratorFormManager;

  @Output() next = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();

  termsConsent = false;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private service: OrderService) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

  actionNext(e) {
    e.preventDefault();
    this.formManager.confirmOrder(this.service, () => super.actionNext(e));
  }

  hasAgreedToTermsAndConditions() {
    return this.termsConsent;
  }

  actionPrev(e) {
    e.preventDefault();
    this.prev.emit(true);
  }

  getDeliveryString() {
    if (this.formManager.isDeliverySelected('personal')) {
      return this.t.pickupAddress;
    } else {
      return this.t.deliveryAddress;
    }
  }
}
