import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {IMetaComponent} from '../../../../common/simulator-booking/IMetaComponent';
import {BookingDefinitions} from '../../../../common/simulator-booking/booking-definitions';
import {InDayHourlyBookingMetaService} from '../../../../common/simulator-booking/services/meta/in-day-hourly-booking-meta.service';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {InDayHourlyBookingMetaValue} from '../../../../common/simulator-booking/models/meta/values/in-day-hourly-booking-meta.value';
import {DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent} from '@angular/material';
import {LangHelper} from '../../../../race-sims/helpers/lang-helper';
import * as moment from 'moment';
import {Moment} from 'moment';
import {SimulatorBookingService} from '../../../../common/simulator-booking/services/simulator-booking.service';
import {SimulatorBooking} from '../../../../common/simulator-booking/models/simulator-booking.model';
import {
  InDayHourlyBookingAvailabilitySchedule,
  InDayHourlyBookingAvailabilityScheduleItem
} from '../../../../common/simulator-booking/models/in-day-hourly-booking-availability-schedule.model';
import {AmountMetaValueInterface} from '../../../../common/simulator-booking/models/meta/values/contracts/amount-meta-value.interface';
import {BookingRelations} from '../../../../common/simulator-booking/booking-relations';

declare const $: any;

@Component({
  selector: 'app-in-day-hourly-booking-meta',
  templateUrl: './in-day-hourly-booking-meta.component.html',
  styleUrls: ['./in-day-hourly-booking-meta.component.less'],
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD'
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})

export class InDayHourlyBookingMetaComponent implements OnInit, OnDestroy, AfterViewInit, IMetaComponent {

  datepickerId = 'app-in-day-hourly-booking-meta-datepicker';

  t = {
    datepickerLabel: 'label',
    selectionInfo: 'label',
    timepickerLabel: 'label'
  };

  isDateSelected = false;
  isScheduleInitialized = false;
  model: SimulatorBooking;
  schedule: InDayHourlyBookingAvailabilitySchedule;
  metaValue: InDayHourlyBookingMetaValue;
  amount: AmountMetaValueInterface;

  eventListeners: any[] = [];

  private service: InDayHourlyBookingMetaService;

  constructor(private managerService: BookingManagerProviderService,
              private dateAdapter: DateAdapter<Moment>,
              private simulatorBookingService: SimulatorBookingService) {
    this.service = new InDayHourlyBookingMetaService(this.managerService);
    // this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.dateAdapter.setLocale(LangHelper.getLang());
    this.initTranslations();
  }

  initTranslations() {
    this.t.datepickerLabel = this.service.getDateLabel();
    this.t.selectionInfo = this.service.getSelectionInfo();
    this.t.timepickerLabel = this.service.getTimeLabel();
  }

  get metaType() {
    return BookingDefinitions.metaTypes.in_day_hourly_booking;
  }

  ngOnInit() {
    this.setTranslations();
    this.setEventListeners();
  }

  ngOnDestroy() {
    this.unsetEventListeners();
  }

  ngAfterViewInit() {
    this.amount = this.managerService.metaManager.getAmountMeta();
  }

  setEventListeners() {
    this.setAmountChangeListeners();
    this.setLocationChangeListeners();
  }

  unsetEventListeners() {
    for (const item of this.eventListeners) {
      document.removeEventListener(item.name, item.listener);
    }
  }

  setEventListenersForEventNames(eventNames: string[], callback: (event: Event) => void) {
    for (const eventName of eventNames) {
      const listener = (event: Event) => callback(event);
      this.eventListeners.push({name: eventName, listener: listener});
      document.addEventListener(eventName, listener);
    }
  }

  setAmountChangeListeners() {
    this.setEventListenersForEventNames(BookingRelations.getAmountMetaEventNames(), this.onAmountChange.bind(this));
  }

  setLocationChangeListeners() {
    this.setEventListenersForEventNames(BookingRelations.getLocationMetaEventNames(), this.onLocationChange.bind(this));
  }

  setTranslations() {
    this.t.datepickerLabel = this.service.getDateLabel();
    this.t.timepickerLabel = this.service.getTimeLabel();
    this.t.selectionInfo = this.service.getSelectionInfo();
  }

  onDateChange(event: MatDatepickerInputEvent<Moment>) {
    this.isDateSelected = true;

    const date = event.value.format(LangHelper.dateInternalFormat);
    this.metaValue = new InDayHourlyBookingMetaValue(date, []);
    this.service.setMetaValue(this.metaValue);
    this.service.onChange();
    this.loadTimes();
  }

  onLocationChange(event: Event) {
    // console.log('Catching: ' + event.type);
    if (this.isDateSelected) {
      this.clearTimes();
      this.loadTimes();
    }
  }

  onAmountChange(event: Event) {
    // console.log('Catching: ' + event.type);

    this.amount = this.managerService.metaManager.getAmountMeta();

    if (this.isDateSelected) {
      this.clearTimes();
      this.loadTimes();
    }
  }

  clearTimes() {
    this.service.clearTimes();
  }

  loadTimes() {
    this.model = new SimulatorBooking();
    this.model.type = this.service.getEventType();
    this.model.meta = this.managerService.metaManager.getAll();

    this.simulatorBookingService
      .getAvailabilitySchedule<InDayHourlyBookingAvailabilitySchedule>(
        this.model,
        InDayHourlyBookingAvailabilitySchedule)
      .subscribe(res => this.onLoadTimesSuccess(res), err => this.onLoadTimesErr(err));
  }

  onLoadTimesSuccess(schedule: InDayHourlyBookingAvailabilitySchedule) {
    this.schedule = schedule;
    this.metaValue = this.service.getMetaValue();
    this.isScheduleInitialized = true;
  }

  onLoadTimesErr(err) {
    console.error(err);
  }

  isAvailable(time: InDayHourlyBookingAvailabilityScheduleItem) {
    return time.available_simulators >= this.amount.num;
  }

  onTimeChecked(time: InDayHourlyBookingAvailabilityScheduleItem) {
    if (this.isAvailable(time)) {
      this.metaValue = this.service.toggleTimeSelection(time);
    }
  }

  futureDateFilter = (date: Moment): boolean => {
    const now = moment();
    return now.isBefore(date);
  };

}
