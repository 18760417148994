import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ConfigImage} from './config-image.model';
import {ConfiguratorFormManager} from '../configurator.form-manager';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-config-image',
  templateUrl: './config-image.component.html',
  styleUrls: ['./config-image.component.less']
})
export class ConfigImageComponent implements OnInit, AfterViewInit {

  constructor(private sanitizer: DomSanitizer) {
  }

  configSelector = '#config-image-stack';
  configImage: ConfigImage;
  @Input() provider: AppDataProvider;
  @Input() formManager: ConfiguratorFormManager;

  ngOnInit() {
  }

  ngAfterViewInit() {
    const that = this;
    setTimeout(function () {
      const $configImageStack = $(that.configSelector);
      that.configImage = new ConfigImage(
        that.provider.getSetting('configurator_product_type_order'),
        that.formManager.getSelection(),
        $configImageStack,
        that.sanitizer
      );
    }, 0);
  }

  reload(selection) {
    this.configImage.render(selection);
  }

}
