import {CourseBookingFormManager} from './course-booking.form-manager';
import {Form, NgForm} from '@angular/forms';
import {CourseBookingDetail} from '../models/course-booking-detail.model';
import {CourseBookingForm} from '../models/course-booking-form.model';
import {CourseDetail} from '../../courses/course-detail.model';
import {LangHelper} from '../../../race-sims/helpers/lang-helper';

export class CourseBookingCreateFormManager implements CourseBookingFormManager {


  private _form: NgForm;
  private _detail: CourseBookingDetail;
  private _model: CourseBookingForm;
  private _course: CourseDetail;
  private _formattedPrice: string;

  get Form(): NgForm {
    return this._form;
  }

  set Form(value: NgForm) {
    this._form = value;
  }

  get Model(): CourseBookingForm {
    return this._model;
  }

  set Model(value: CourseBookingForm) {
    this._model = value;
    this.calculatePrice();
  }

  get Course(): any {
    return this._course;
  }

  set Course(value: any) {
    this._course = value;
  }

  get Detail(): CourseBookingDetail {
    return this._detail;
  }

  set Detail(value: CourseBookingDetail) {
    this._detail = value;
  }

  get FormattedPrice(): string {
    return this._formattedPrice;
  }

  updateFormattedPrice() {
    this._formattedPrice = LangHelper.getLocalizedNumberFormatter().format(this.Model.price);
  }

  setSimulatorCount(): number {
    return this.Model.price;
  }

  getPrice(): number {
    return this.Model.price;
  }

  getFormattedPrice(): string {
    return LangHelper.getLocalizedNumberFormatter().format(this.Model.price);
  }


  calculatePrice(): number {
    this.Model.price = this.Course.price * this.Model.simulator_amount;
    this.updateFormattedPrice();
    return this.getPrice();
  }

  isValid(): boolean {
    return true;
  }

}
