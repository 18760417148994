import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-about-us-details',
  templateUrl: './about-us-details.component.html',
  styleUrls: ['./about-us-details.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsDetailsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('about_us.about_us.title'),
    text_1: this.translate('about_us.about_us.text_1'),
    text_2: this.translate('about_us.about_us.text_2'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
