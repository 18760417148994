export class AvailableTimeRequest {
  type: string;
  location_id: number;
  date: string;

  static fromSelectionConfig(selectionConfig: { eventType: string; numSims: number; location: any; date: any }): AvailableTimeRequest {
    const self = new AvailableTimeRequest();
    self.type = selectionConfig.eventType;
    self.date = selectionConfig.date;
    self.location_id = Number(selectionConfig.location);
    return self;
  }
}
