export class BookingPriceRequest {
  simulator_amount: number;
  type: string;
  location_id: number;
  date: string;
  selected_start_times: number[];
  static fromSelectionConfig(selectionConfig: { eventType: string; numSims: number; location: any; date: any }, startTimes: number[]): BookingPriceRequest {
    const self = new BookingPriceRequest();
    self.type = selectionConfig.eventType;
    self.date = selectionConfig.date;
    self.location_id = Number(selectionConfig.location);
    self.simulator_amount = Number(selectionConfig.numSims);
    self.selected_start_times = startTimes;
    return self;
  }
}
