import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-championship-sponsors',
  templateUrl: './championship-sponsors.component.html',
  styleUrls: ['./championship-sponsors.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ChampionshipSponsorsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('championship.sponsors.title')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
