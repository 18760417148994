import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-stage3-pro-photos',
  templateUrl: './stage3-pro-photos.component.html',
  styleUrls: ['./stage3-pro-photos.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Stage3ProPhotosComponent extends SiteBase implements OnInit {

  t = {
    showAll: this.translate('stage3_pro.photos.show_all'),
    photos: this.translate('stage3_pro.photos.title')
  };

  photoAmount;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.photoAmount = Number.parseInt(this.provider.getSetting('academy_displayed_photos_count'));
  }

}
