import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ScriptHelper} from '../../shared/helpers/scripts.helper';
import {SiteBase} from '../site-base';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {AppDataProvider} from '../../common/app-data.provider';
import {AppConfig} from '../../app.config';
import {LangHelper} from '../../race-sims/helpers/lang-helper';

@Component({
  selector: 'app-stage3-pro',
  templateUrl: './stage3-pro.component.html',
  styleUrls: ['./stage3-pro.component.less']
})
export class Stage3ProComponent extends SiteBase implements OnInit, AfterViewInit {

  constructor(private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private meta: Meta) {
    super(provider, sanitizer);

    const contents = JSON.parse(this.provider.getSetting('app_share_data'));

    const image = '/' + AppConfig.productsPath + 'share-config.jpg';

    // this.meta.addTags([
    //   {property: 'og:title', content: contents[LangHelper.getLang()].title},
    //   {property: 'og:description', content: contents[LangHelper.getLang()].text},
    //   {property: 'og:url', content: this.provider.getSetting('app_share_url')},
    //   {property: 'og:image', content: image},
    //   {property: 'og:type', content: 'website'},
    // ]);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('meta.stage3_pro.title'));
    this.meta.updateTag({ name: 'title', content:this.translate('meta.stage3_pro.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.stage3_pro.description') });

 }


  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }
}
