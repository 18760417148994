import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-academy-landing',
  templateUrl: './academy-landing.component.html',
  styleUrls: ['./academy-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcademyLandingComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    bookNow: this.translate('general.variables.btn_book_now'),
    title: this.translate('academy.landing.title'),
    text: this.translate('academy.landing.text'),
    showOptions: this.translate('general.variables.btn_show_options'),
    driverTraining: this.translate('academy.training.title'),
    events: this.translate('academy.events.title'),
    courses: this.translate('academy.courses.title'),
    videos: this.translate('academy.videos.title'),
    map: this.translate('academy.map.title'),
    booking: this.translate('academy.booking.title')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }


}
