import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-academy-driver-training',
  templateUrl: './academy-driver-training.component.html',
  styleUrls: ['./academy-driver-training.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AcademyDriverTrainingComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('academy.training.title'),
    text1: this.translate('academy.training.text_1'),
    text2: this.translate('academy.training.text_2'),
    subtitle: this.translate('academy.training.subtitle'),
    bookNow: this.translate('general.variables.btn_book_now')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }
  ngOnInit() {
  }


}
