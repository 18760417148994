import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-general-build-configurator',
  templateUrl: './general-build-configurator.component.html',
  styleUrls: ['./general-build-configurator.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class GeneralBuildConfiguratorComponent extends SiteBase implements OnInit {

  t = {
    text: this.translate('general.build_configurator.text'),
    configurator: this.translate('general.variables.btn_configurator')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }
  ngOnInit() {
  }

}
