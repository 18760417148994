import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { SiteBase } from "../../site-base";
import { AppDataProvider } from "../../../common/app-data.provider";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfiguratorDataManager } from "../configurator.data-manager";
import { ConfiguratorFormManager } from "../configurator.form-manager";
import { ConfiguratorBase } from "../configurator-base";
import {ProductService} from '../../../race-sims/services/product.service';

declare const $: any;

@Component({
  selector: "app-configurator-step-1",
  templateUrl: "./configurator-step-1.component.html",
  styleUrls: ["./configurator-step-1.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorStep1Component extends ConfiguratorBase
  implements OnInit, AfterViewInit {
  t = {
    title: this.translate("configurator.form.title"),
    totalCost: this.translate("configurator.form.total_cost"),
    selectedOptions: this.translate("configurator.form.selected_options"),
    stepTitle: this.translate("configurator.form.step_1_title"),
    stepSubtitle: this.translate("configurator.form.step_1_subtitle"),
    simulator: this.translate("configurator.form.simulator"),
    wheel: this.translate("configurator.form.steering_wheel"),
    pedals: this.translate("configurator.form.pedals"),
    previous: this.translate("configurator.form.previous_step"),
    next: this.translate("configurator.form.next_step"),
    tax: this.translate("configurator.form.tax"),
    computer: this.translate("configurator.form.computer"),
  };

  @Input() dataManager: ConfiguratorDataManager;
  @Input() formManager: ConfiguratorFormManager;
  @Input() imgUrl: string;

  @Output() next = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();
  @Output() onImgUrlChanged = new EventEmitter<string>();

  constructor(
    protected provider: AppDataProvider,
    public sanitizer: DomSanitizer,
    private productService: ProductService
  ) {
    super(provider, sanitizer);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // this.actionLoadConfigurationImage();
  }
}
