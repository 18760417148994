import {Component, OnInit} from '@angular/core';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {LiveTimingMetaService} from '../../../../common/simulator-booking/services/meta/live-timing-meta.service';
import {CoachMetaValue} from '../../../../common/simulator-booking/models/meta/values/coach-meta-value.model';
import {LiveTimingMetaValue} from '../../../../common/simulator-booking/models/meta/values/live-timing-meta.value';

@Component({
  selector: 'app-live-timing-meta',
  templateUrl: './live-timing-meta.component.html',
  styleUrls: ['./live-timing-meta.component.less']
})
export class LiveTimingMetaComponent implements OnInit {

  t = {
    label: 'label',
    description: 'description'
  };

  private service: LiveTimingMetaService;
  isChecked: boolean;

  constructor(private managerService: BookingManagerProviderService) {
    this.service = new LiveTimingMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.isChecked = this.service.getMetaSetting().is_checked;
    this.initTranslations();
  }

  initTranslations() {
    this.t.label = this.service.getLabel();
    this.t.description = this.service.getDescription();
  }

  ngOnInit() {
  }

  onLiveTimingChange(value: boolean) {
    this.service.setMetaValue(new LiveTimingMetaValue(value));
  }
}
