import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class HomeLandingComponent extends SiteBase implements OnInit {


  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
  super(provider, sanitizer);
  }

  ngOnInit() {
  }

  // photoUrl(num: number){
  //   return this.sanitizer.bypassSecurityTrustUrl(this.translate('home.landing.driver_' + num + '_img'));
  // }
}
