import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {InstagramService} from '../services/instagram.service';
import {Observable} from 'rxjs';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-home-instagram',
  templateUrl: './home-instagram.component.html',
  styleUrls: ['./home-instagram.component.less']
  // changeDetection: ChangeDetectionStrategy.OnPush

})
export class HomeInstagramComponent extends SiteBase implements OnInit, AfterViewInit {

  igPosts?: any[];
  igUrl: any;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private instagramService: InstagramService) {
    super(provider, sanitizer);
  }

  translate(key: string): string {
    return this.provider.getTranslation(key);
  }

  ngOnInit() {
    const that = this;
    this.igUrl = this.sanitizer.bypassSecurityTrustUrl(this.translate('home.instagram.profile_link'));
    this.instagramService.getData().subscribe(res => {
      that.igPosts = res;
      setTimeout(() => that.imgsLoaded(), 100);
    });
  }

  loadData(data: any) {
  }

  bypassUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  bypassStyle(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
  }

  ngAfterViewInit(): void {

  }

  imgsLoaded() {
    const docs = document.getElementsByClassName('insta-img');
    for (let i = 0; i < docs.length; i++) {
      const doc: any = docs.item(i);
      const padding = 30;
      const height = doc.clientWidth - padding;
      doc.style.height = height + 'px';
    }
  }
}
