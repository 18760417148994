import {Component, OnInit} from '@angular/core';
import {SiteBase} from "../../site-base";
import {AppDataProvider} from "../../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-wmtd-advantages',
  templateUrl: './wmtd-advantages.component.html',
  styleUrls: ['./wmtd-advantages.component.less']
})
export class WmtdAdvantagesComponent extends SiteBase implements OnInit {

  icons = [
    '',
    'site/img/ico-steering-motor.svg',
    'site/img/ico-frame.svg',
    'site/img/ico-academy-preparation.svg',
    'site/img/ico-software.svg',
    'site/img/ico-mobility.svg',
    'site/img/ico-dimensions.svg',
    'site/img/ico-phone.svg'
  ];

  images = [
    '',
    'site/img/5c.webp',
    'site/img/5d.webp',
    'site/img/5e.webp',
    'site/img/5f.webp',
    'site/img/5g.webp',
    'site/img/5h.webp',
    'site/img/5i.webp',
  ];

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
