import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {ContactForm} from '../contact-form.model';
import {ContactFormService} from '../services/contact-form.service';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ContactFormComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('contact.form.title'),
    message: this.translate('contact.form.message'),
    name: this.translate('contact.form.name'),
    phone: this.translate('contact.form.phone'),
    email: this.translate('contact.form.email'),
    send: this.translate('contact.form.send'),
    note: this.translate('contact.form.note')
  };

  model: ContactForm;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private contactFormService: ContactFormService) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.model = new ContactForm();
  }

  isValid(form: any) {
    return (
      form.valid &&
      this.model.message !== '' &&
      this.model.phone !== '' &&
      this.model.email !== '' &&
      this.model.name !== ''
    );
  }

  sendMessage(form: any) {
    this.contactFormService.post(form).subscribe(data => this.onSendMessageSuccess(data), err => this.onSendMessageError(err));
  }

  onSendMessageSuccess(data) {
    alert('Your message was delivered successfully.');
  }

  onSendMessageError(err) {
    alert('Your message could not be delivered.');
  }

}
