import {BookingDataManager} from './booking.data-manager';
import {BookingTime} from '../../race-sims/models/booking-time.model';
import {LangHelper} from '../../race-sims/helpers/lang-helper';
import {AcademyLocation} from '../../race-sims/models/academy-location.model';
import {isNullOrUndefined} from 'util';
import {BookingCreateBuilder} from '../../race-sims/helpers/booking.create.builder';
import {BookingCustomer} from '../../race-sims/models/booking-customer.model';

export class BookingForm {
  name: string;
  surname: string;
  phoneCountryCode: string;
  phoneNumber: string;
  email: string;

  constructor() {
    this.name = '';
    this.surname = '';
    this.phoneCountryCode = '41';
    this.phoneNumber = '';
    this.email = '';
  }

  isFilled() {
    return (
      this.name !== '' &&
      this.surname !== '' &&
      this.phoneCountryCode !== '' &&
      this.phoneNumber !== '' &&
      this.email !== ''
    );
  }
}

export class BookingFormManager {


  private _dataManager: BookingDataManager;

  private _eventType: string;
  private _simulatorCount: number;
  private _academy: number;
  private _date: string;
  private _times: number[];
  private _price: number;
  private _form: BookingForm;
  private _agreement: boolean;
  private _id: number;

  constructor(dataManager: BookingDataManager) {
    this._dataManager = dataManager;
    this.init(dataManager);
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  isUpdate(): boolean {
    return this.id !== undefined;

  }

  get eventType(): string {
    return this._eventType;
  }

  set eventType(value: string) {
    this._eventType = value;
  }

  get simulatorCount(): number {
    return this._simulatorCount;
  }

  set simulatorCount(value: number) {
    this._simulatorCount = value;
  }

  get academy(): number {
    return this._academy;
  }

  set academy(value: number) {
    this._academy = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }

  get agreement(): boolean {
    return this._agreement;
  }

  set agreement(value: boolean) {
    this._agreement = value;
  }

  toggleAgreement() {
    this._agreement = !this._agreement;
  }

  get form(): BookingForm {
    return this._form;
  }

  get formattedPrice(): string {
    return this.formatPrice(this._price);
  }

  formatPrice(price: number) {
    return LangHelper.getLocalizedNumberFormatter().format(price);
  }

  set price(value: number) {
    this._price = value;
  }

  get times(): number[] {
    return this._times;
  }

  init(dataManager: BookingDataManager) {
    this._eventType = dataManager.eventTypes[0].key;
    this._academy = dataManager.locations[0].id;
    this._simulatorCount = dataManager.simulators[0];
    this._times = [];
    this._date = null;
    this._form = new BookingForm();
    this._agreement = false;
  }

  isEventTypeSelected(type: string) {
    return type === this._eventType;
  }

  isLocationSelected(location: AcademyLocation) {
    return location.id === this._academy;
  }

  private getTimeIndex(time: BookingTime) {
    for (const idx in this._times) {
      if (this._times[idx] === time.start) {
        return idx;
      }
    }

  }

  isTimeSelected(time: BookingTime) {
    for (const item of this._times) {
      if (time.start === item) {
        return true;
      }
    }
    return false;
  }

  toggleTime(time: BookingTime) {
    if (!this.isTimeInterferingWithSelection(time) && time.available) {
      if (this.isTimeSelected(time)) {
        this.removeTime(time);
      } else {
        this.addTime(time);
      }
    }
  }

  addTime(time: BookingTime) {
    this._times.push(time.start);
  }

  removeTime(time: BookingTime) {
    const idx = Number.parseInt(this.getTimeIndex(time));
    this._times.splice(idx, 1);
  }

  isTimeInterferingWithSelection(time: BookingTime) {
    const intervalLength = time.end - time.start;
    for (const selectedTime of this._times) {
      const start = selectedTime;
      const end = selectedTime + intervalLength;
      const timeStart = time.start;
      const timeEnd = time.end;
      if (
        (start < timeStart && end > timeStart) ||
        (start < timeEnd && end > timeEnd) ||
        (start > timeStart && end < timeEnd)
      ) {
        return true;
      }
    }
    return false;
  }

  isDateSelected() {
    return this._date !== null;
  }

  isBookingFilled() {
    return (
      !isNullOrUndefined(this._times) &&
      (this._times.length !== 0) &&
      !isNullOrUndefined(this._date) &&
      !isNullOrUndefined(this._academy) &&
      !isNullOrUndefined(this._simulatorCount) &&
      !isNullOrUndefined(this._eventType)
    );
  }

  isFormFilled() {
    return this._form.isFilled();
  }

  emptyTimes() {
    this._times = [];
  }

  isComplete() {
    return this.isFormFilled() && this.agreement;
  }

  getModel() {
    const customer = new BookingCustomer();
    customer.first_name = this._form.name;
    customer.last_name = this._form.surname;
    customer.email = this._form.email;
    customer.phone.country_code = this._form.phoneCountryCode;
    customer.phone.number = this._form.phoneNumber;
    return BookingCreateBuilder.get()
    // .price(this._price)
      .customer(customer)
      // .date(this._date)
      // .simulators(this._simulatorCount)
      // .location(this._academy)
      .type(this._eventType)
      // .times(this._times)
      .build();

  }
}
