import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactForm } from '../contact-form.model';

@Injectable()
export class ContactFormService {

  private baseUrl = `${AppConfig.serverUrl}/api/v1/contact-form`;
  private supportUrl = `${AppConfig.serverUrl}/api/v1/support-form`;


  constructor(private http: HttpClient) {
  }

  post(contactForm: ContactForm): Observable<any> {
    return this.http.post<any>(this.baseUrl, contactForm);
  }

  postSupport(contactForm: ContactForm): Observable<any> {
    return this.http.post<any>(this.supportUrl, contactForm);
  }
}
