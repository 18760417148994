import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-simulator-faq',
  templateUrl: './simulator-faq.component.html',
  styleUrls: ['./simulator-faq.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulatorFaqComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('simulator.faq.title')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

}
