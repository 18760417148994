import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {BookingSettingService} from '../booking-setting.service';
import {ExclusiveAmountMetaValue} from '../../models/meta/values/exclusive-amount-meta-value.model';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';
import {AmountMetaInterface} from './contracts/amount-meta.interface';

export interface ExclusiveAmountMetaContract {
  key: string;
  amount: number;
  translations: {
    en: {
      label: string;
    },
    de: {
      label: string;
    }
  };
}

export class ExclusiveAmountMetaService extends CommonMetaService<ExclusiveAmountMetaContract, ExclusiveAmountMetaValue>
  implements AmountMetaInterface, ValidableMetaServiceInterface{

  static metaType = BookingDefinitions.metaTypes.exclusive_amount;
  static changeEventIdentifier = 'meta.' + ExclusiveAmountMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    super(managerService);
    this.setMetaValue(new ExclusiveAmountMetaValue(this.getAmount()));
  }

  getChangeEventIdentifier(): string {
    return ExclusiveAmountMetaService.changeEventIdentifier;
  }

  getMetaType(): string {
    return ExclusiveAmountMetaService.metaType;
  }

  getLabel(): string {
    return this.getTranslatedAttr('label');
  }

  getAmount(): number {
    return this.getMetaSetting().amount;
  }

  isValid(){
    return this.getMetaValue().num !== undefined;
  }
}
