import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-simulator-landing',
  templateUrl: './simulator-landing.component.html',
  styleUrls: ['./simulator-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulatorLandingComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('simulator.landing.title'),
    description: this.translate('simulator.landing.description'),
    showFeatures: this.translate('general.variables.btn_show_features'),
    configurator: this.translate('general.variables.btn_configurator'),
    techDetails: this.translate('general.variables.btn_tech_details'),
    photos: this.translate('simulator.photos.title'),
    videos: this.translate('simulator.videos.title'),
    faq: this.translate('simulator.faq.title'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }
  ngOnInit() {
  }

}
