import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-contact-landing',
  templateUrl: './contact-landing.component.html',
  styleUrls: ['./contact-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ContactLandingComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('contact.landing.title'),
    addressTitle: this.translate('contact.landing.address_title'),
    addressLine1: this.translate('contact.landing.address_line_1'),
    addressLine2: this.translate('contact.landing.address_line_2'),
    addressLine3: this.translate('contact.landing.address_line_3'),
    phone: this.translate('contact.landing.phone'),
    phoneSafe: this.sanitizer.bypassSecurityTrustUrl('tel:' + this.translate('contact.landing.phone')),
    mail: this.translate('contact.landing.mail'),
    hours1: this.translate('contact.landing.hours_line_1'),
    hours2: this.translate('contact.landing.hours_line_2'),

    address2Title: this.translate('contact.landing.address2_title'),
    address2Line1: this.translate('contact.landing.address2_line_1'),
    address2Line2: this.translate('contact.landing.address2_line_2'),
    address2Line3: this.translate('contact.landing.address2_line_3'),
    phone2: this.translate('contact.landing.phone2'),
    phoneSafe2: this.sanitizer.bypassSecurityTrustUrl('tel:' + this.translate('contact.landing.phone2')),
    mail2: this.translate('contact.landing.mail2'),
    hours2_1: this.translate('contact.landing.hours_line_1_2'),
    hours2_2: this.translate('contact.landing.hours_line_2_2'),

    contacts: this.translate('contact.landing.btn_contacts'),
    location: this.translate('contact.landing.btn_where_u_can_find_us'),
    form: this.translate('contact.landing.btn_form'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }
}
