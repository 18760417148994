import {ProductListItem} from '../../race-sims/models/products/product-list-item.model';
import {Collection} from '../../race-sims/models/collection.model';
import {DomSanitizerImpl} from '@angular/platform-browser/src/security/dom_sanitization_service';
import {DomSanitizer} from '@angular/platform-browser';
import {AppConfig} from '../../app.config';

export enum ProductType {
  Base = 'base',
  Wheel = 'wheel',
  Pedals = 'pedals',
  Shifter = 'shifter',
  Handbrake = 'handbrake',
  Motion = 'motion',
  Accessory = 'accessory',
  AccessoryBelt = 'accessory-belt',
  AccessoryDashboard = 'accessory-dashboard',
  Monitor = 'monitor',
  Computer = 'pc',
  Software = 'software',
  Support = 'support',
  License = 'license'
}

export class ConfiguratorDataManager {

  private static _instance: ConfiguratorDataManager = null;

  private items: {
    all: ProductListItem[],
    bases: ProductListItem[],
    wheels: ProductListItem[],
    pedals: ProductListItem[],
    shiftersAndHandbrakes: ProductListItem[],
    motionAndAccessories: ProductListItem[],
    displays: ProductListItem[],
    computers: ProductListItem[],
    softwareAndSupport: ProductListItem[],
  };

  private constructor() {
    ConfiguratorDataManager._instance = this;
  }

  public static getInstance() {
    if (ConfiguratorDataManager._instance === null) {
      return new ConfiguratorDataManager();
    }
    return ConfiguratorDataManager._instance;
  }

  public setItems(items: Collection<ProductListItem>): void {
    const list = items.data;
    this.items = {
      all: list,
      bases: this.parseByTypes([ProductType.Base], list),
      wheels: this.parseByTypes([ProductType.Wheel], list),
      pedals: this.parseByTypes([ProductType.Pedals], list),
      shiftersAndHandbrakes: this.parseByTypes([ProductType.Shifter, ProductType.Handbrake], list),
      motionAndAccessories: this.parseByTypes([ProductType.Motion, ProductType.AccessoryBelt, ProductType.AccessoryDashboard, ProductType.Accessory], list),
      displays: this.parseByTypes([ProductType.Monitor], list),
      computers: this.parseByTypes([ProductType.Computer], list),
      softwareAndSupport: this.parseByTypes([ProductType.Software, ProductType.Support, ProductType.License], list)
    };
  }

  public getItems() {
    return this.items;
  }

  private parseByTypes(types: string[], items: ProductListItem[]): ProductListItem[] {
    const parsed: ProductListItem[] = [];
    for (const type of types) {
      for (const item of items) {
        if (item.type === type) {
          parsed.push(item);
        }
      }
    }
    return parsed;
  }

  public getConfigurationImageUrl(configurationCode: string){
    return AppConfig.productsPath + configurationCode + '.jpg';
  }

  public getImageUrl(configurationCode: string){
    return AppConfig.productsPath + configurationCode + '.jpg';
  }
}
