import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';
import {Observable} from 'rxjs';
import {Collection} from '../../../race-sims/models/collection.model';
import {IndexService} from '../../../race-sims/shared/services/index.service';
import {CourseBookingIndexDetail} from '../models/course-booking-index-detail.model';
import {CourseBookingDetail} from '../models/course-booking-detail.model';

@Injectable()
export class CourseBookingService implements IndexService<CourseBookingIndexDetail> {

  private baseUrl = `${AppConfig.serverUrl}/api/courses`;

  private course: number;

  constructor(private http: HttpClient) {
  }

  usePublicApi() {
    this.baseUrl = `${AppConfig.serverUrl}/api/v1/courses`;
  }

  usePrivateApi() {
    this.baseUrl = `${AppConfig.serverUrl}/api/courses`;
  }

  setCourse(id: number) {
    this.course = id;
  }

  all(): Observable<Collection<CourseBookingIndexDetail>> {
    return this.http.get<Collection<CourseBookingIndexDetail>>(`${this.baseUrl}/${this.course}/bookings`);
  }

  post(model): Observable<CourseBookingDetail> {
    return this.http.post<CourseBookingDetail>(`${this.baseUrl}/booking`, model);
  }

  put(model): Observable<CourseBookingDetail> {
    return this.http.put<CourseBookingDetail>(`${this.baseUrl}/booking/${model.id}`, model);
  }

  destroy(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  get(id: number): Observable<CourseBookingDetail> {
    return this.http.get<CourseBookingDetail>(`${this.baseUrl}/${this.course}/bookings/${id}`);
  }

  setStatus(id: number, status: string){
    return this.http.get<CourseBookingDetail>(`${AppConfig.serverUrl}/api/bookings/set-status/${id}/${status}`);

  }
}
