import {Injectable} from '@angular/core';
import {forEach} from '@angular/router/src/utils/collection';
import {AppDataProvider} from '../../app-data.provider';

export interface BaseMetaSettingContract{
  key: string;
  name_en: string;
  name_de: string;
}

@Injectable()
export class BookingSettingService {

    private readonly setting: any;

    constructor(private provider: AppDataProvider) {
        this.setting = JSON.parse(this.provider.getSetting('booking'));
    }

    getSetting() {
        return this.setting;
    }

    getMetaListForBookingType(type: string): BaseMetaSettingContract[] {
        for (const item of this.getSetting().booking_types) {
            if (item.type === type) {
                return item.meta;
            }
        }
        return undefined;
    }

    getMetaKeys(metaList: any): string[] {
        const keys = [];
        for (const item of metaList) {
            keys.push(item.key);
        }
        return keys;
    }

    getMeta<T>(type: string, meta: string): T {
        const list = this.getMetaListForBookingType(type);
        for (const item of list) {
            if (item.key === meta) {
                return <any>item;
            }
        }
        return undefined;
    }

    getBookingTypes(lang: string) {
        const list = this.getSetting().booking_types;
        return list.map(i => {
            return {key: i.type, value: i['name_' + lang]};
        });
    }
}
