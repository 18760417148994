import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {CoachMetaContract, CoachMetaService} from '../../../../common/simulator-booking/services/meta/coach-meta.service';
import {CoachMetaValue} from '../../../../common/simulator-booking/models/meta/values/coach-meta-value.model';
import {
  PitBoxMetaContract,
  PitBoxMetaService
} from '../../../../common/simulator-booking/services/meta/pit-box-meta.service';
import {PitBoxMetaValue} from '../../../../common/simulator-booking/models/meta/values/pit-box-meta-value.model';

@Component({
  selector: 'app-pit-box-meta',
  templateUrl: './pit-box-meta.component.html',
  styleUrls: ['./pit-box-meta.component.less']
})
export class PitBoxMetaComponent implements OnInit, AfterViewInit {

  t = {
    label: 'label',
    description: 'description'
  };
  service: PitBoxMetaService;
  metaSetting: PitBoxMetaContract;
  metaValue: PitBoxMetaValue;

  isChecked: boolean;
  constructor(private managerService: BookingManagerProviderService) {
    this.service = new PitBoxMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.metaSetting = this.service.getMetaSetting();
    this.metaValue = new PitBoxMetaValue(this.metaSetting.is_checked, this.metaSetting.price);
    this.isChecked = this.metaSetting.is_checked;

  }

  ngOnInit() {
    this.setTranslations();
  }

  setTranslations() {
    this.t.label = this.service.getLabel();
    this.t.description = this.service.getDescription();
  }

  ngAfterViewInit() {

  }

  onPitBoxMetaChange(value: boolean) {
    this.service.setMetaValue(new PitBoxMetaValue(value, this.service.getPrice(value)));
  }
}
