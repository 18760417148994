import {isNullOrUndefined} from 'util';

export class LangHelper {
  private static defaultLang = 'en';

  static dateInternalFormat = 'YYYY-MM-DD';
  static dateOutputFormat = 'LL';

  static getLang(): string {
    const urlLang = new URLSearchParams(window.location.search).get('lang');
    // check for lang parameter in url and set it as language
    if (!isNullOrUndefined(urlLang) && ['en', 'de'].includes(urlLang)) {
      const lang = LangHelper.setLang(urlLang);
      window.history.pushState({}, document.title, window.location.pathname);
      return lang;
    }
    const storedLang = localStorage.getItem('lang-site');
    if (isNullOrUndefined(storedLang)) {
      return LangHelper.setLang(LangHelper.defaultLang);
    }
    return storedLang;
  }

  static setLang(lang: string): string {
    localStorage.setItem('lang-site', lang);
    return lang;
  }

  static getLangLong() {
    switch (this.getLang()) {
      case 'en':
        return 'English';
      case 'de':
        return 'Deutsch';
    }
  }

  static getLocalizedNumberFormatter(currency = "EUR") {
    const style = currency == "EUR" ? "de-DE" :"de-CH"
    return Intl.NumberFormat(style, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }

}
