import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {AppDataProvider} from '../../../app-data.provider';
import {CarClassMetaValue} from '../../models/meta/values/car-class-meta-value.model';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';
import {RaceTrackMetaValue} from '../../models/meta/values/race-track-meta-value.model';

interface CarClassMetaContract {
  key: string;
  data_source: string;
  data_source_identifier: string;
  translations: {
    en: {
      label: string;
    },
    de: {
      label: string;
    }
  };
}

export class CarClassMetaService extends CommonMetaService<CarClassMetaContract, CarClassMetaValue>
  implements ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.car_class;
  static changeEventIdentifier = 'meta.' + CarClassMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService, private appProvider: AppDataProvider) {
    super(managerService);
    let metaVal = this.getMetaValue();
    metaVal = metaVal === undefined ? new CarClassMetaValue('--') : metaVal;
    this.setMetaValue(metaVal, false);
  }

  getMetaType(): string {
    return CarClassMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return CarClassMetaService.changeEventIdentifier;
  }

  getLabel() {
    return this.getTranslatedAttr('label');
  }

  getCars() {
    const meta = this.getMetaSetting();
    if (meta.data_source === 'settings') {
      const setting = this.appProvider.getSetting(meta.data_source_identifier);
      const tracks = JSON.parse(setting);
      tracks.unshift('--');
      return tracks;
    }
  }

  isValid() {
    return this.getMetaValue().selected_car_class !== undefined;
  }
}
