import {AmountMetaComponent} from '../site/booking/meta/amount-meta/amount-meta.component';
import {CoachMetaComponent} from '../site/booking/meta/coach-meta/coach-meta.component';
import {LiveTimingMetaComponent} from '../site/booking/meta/live-timing-meta/live-timing-meta.component';
import {CustomStickersMetaComponent} from '../site/booking/meta/custom-stickers-meta/custom-stickers-meta.component';
import {RaceTrackMetaComponent} from '../site/booking/meta/race-track-meta/race-track-meta.component';
import {CarClassMetaComponent} from '../site/booking/meta/car-class-meta/car-class-meta.component';
import {LocationMetaComponent} from '../site/booking/meta/location-meta/location-meta.component';
import {ExclusiveAmountMetaComponent} from '../site/booking/meta/exclusive-amount-meta/exclusive-amount-meta.component';
import {InDayHourlyBookingMetaComponent} from '../site/booking/meta/in-day-hourly-booking-meta/in-day-hourly-booking-meta.component';
import {DateRangeBookingMetaComponent} from '../site/booking/meta/date-range-booking-meta/date-range-booking-meta.component';
import {PriceMetaComponent} from '../site/booking/meta/price-meta/price-meta.component';
import {BookingDefinitions} from '../common/simulator-booking/booking-definitions';
import {AmountMetaService} from '../common/simulator-booking/services/meta/amount-meta.service';
import {NoteMetaComponent} from '../site/booking/meta/note-meta/note-meta.component';
import {PitBoxMetaComponent} from '../site/booking/meta/pit-box/pit-box-meta.component';
import {EnergyDrinkMetaComponent} from '../site/booking/meta/energy-drink/energy-drink-meta.component';
import {CateringMetaComponent} from '../site/booking/meta/catering-meta/catering-meta.component';

export class BookingConnectors {

  static metaConnectors = [
    {
      name: BookingDefinitions.metaTypes.catering,
      component: CateringMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.pit_box,
      component: PitBoxMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.energy_drink,
      component: EnergyDrinkMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.coach,
      component: CoachMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.live_timing,
      component: LiveTimingMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.custom_stickers,
      component: CustomStickersMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.race_track,
      component: RaceTrackMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.car_class,
      component: CarClassMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.location,
      component: LocationMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.amount,
      component: AmountMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.exclusive_amount,
      component: ExclusiveAmountMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.in_day_hourly_booking,
      component: InDayHourlyBookingMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.date_range_booking,
      component: DateRangeBookingMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.price,
      component: PriceMetaComponent
    },
    {
      name: BookingDefinitions.metaTypes.note,
      component: NoteMetaComponent
    }
  ];

  static metaServiceConnectors = [
    {
      name: BookingDefinitions.metaTypes.amount,
      service: AmountMetaService
    }
  ];

  // : { name: string, component: any }
  static getConnector(metaName: string): any {
    for (const item of BookingConnectors.metaConnectors) {
      if (item.name === metaName) {
        return item;
      }
    }
  }

  static getServiceAccessor(metaName: string): any {
    for (const item of BookingConnectors.metaServiceConnectors) {
      if (item.name === metaName) {
        return item.service;
      }
    }
  }

}
