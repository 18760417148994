import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-stage3-pro-technical-details',
  templateUrl: './stage3-pro-technical-details.component.html',
  styleUrls: ['./stage3-pro-technical-details.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Stage3ProTechnicalDetailsComponent extends SiteBase implements OnInit {

  t = {
    titleLine1: this.translate('stage3_pro.tech_details.title_line_1'),
    titleLine2: this.translate('stage3_pro.tech_details.title_line_2'),
    rigTitle: this.translate('stage3_pro.tech_details.rig_title'),
    rigDescr: this.translate('stage3_pro.tech_details.rig_description'),
    platformTitle: this.translate('stage3_pro.tech_details.platform_title'),
    platformDescr1: this.translate('stage3_pro.tech_details.platform_paragraph_1'),
    platformDescr2: this.translate('stage3_pro.tech_details.platform_paragraph_2'),
    platformDescr3: this.translate('stage3_pro.tech_details.platform_paragraph_3'),
    platformDescr4: this.translate('stage3_pro.tech_details.platform_paragraph_4'),
    controlsTitle: this.translate('stage3_pro.tech_details.controls_title'),
    controlsSteeringWheelsTitle: this.translate('stage3_pro.tech_details.controls_steering_wheels_title'),
    controlsSteeringWheelsSubTitle: this.translate('stage3_pro.tech_details.controls_steering_wheels_subtitle'),
    controlsSteeringWheelsDescr: this.translate('stage3_pro.tech_details.controls_steering_wheels_text'),
    controlsSteeringMotorTitle: this.translate('stage3_pro.tech_details.controls_steering_motor_title'),
    controlsSteeringMotorDescr: this.translate('stage3_pro.tech_details.controls_steering_motor_text'),
    controlsPedalsTitle: this.translate('stage3_pro.tech_details.controls_pedals_title'),
    controlsPedalsDescr: this.translate('stage3_pro.tech_details.controls_pedals_text'),
    controlsHandbrakeTitle: this.translate('stage3_pro.tech_details.controls_handbrake_title'),
    controlsHandbrakeDescr: this.translate('stage3_pro.tech_details.controls_handbrake_text'),
    controlsSequentialShifterTitle: this.translate('stage3_pro.tech_details.controls_sequential_shifter_title'),
    controlsSequentialShifterDescr: this.translate('stage3_pro.tech_details.controls_sequential_shifter_text'),
    controlsManualShifterTitle: this.translate('stage3_pro.tech_details.controls_h_shifter_title'),
    controlsManualShifterDescr: this.translate('stage3_pro.tech_details.controls_h_shifter_text'),
    pc1Title: this.translate('stage3_pro.tech_details.pc_content_1_title'),
    pc1Text: this.translate('stage3_pro.tech_details.pc_content_1_text'),
    pc2Title: this.translate('stage3_pro.tech_details.pc_content_2_title'),
    pc2Text: this.translate('stage3_pro.tech_details.pc_content_2_text'),
    dimensionsTitle: this.translate('stage3_pro.other_details.dimensions_title'),
    dimensionsDescr: this.translate('stage3_pro.other_details.dimensions_text'),
    weightTitle: this.translate('stage3_pro.other_details.weight_title'),
    weightDescr: this.translate('stage3_pro.other_details.weight_text'),
    powerTitle: this.translate('stage3_pro.other_details.power_title'),
    powerDescr: this.translate('stage3_pro.other_details.power_text'),
    mobilityTitle: this.translate('stage3_pro.other_details.mobility_title'),
    mobilityDescr: this.translate('stage3_pro.other_details.mobility_text'),
    softwareTitle: this.translate('stage3_pro.other_details.software_title'),
    softwareDescr: this.translate('stage3_pro.other_details.software_text'),
    frameTitle: this.translate('stage3_pro.other_details.frame_title'),
    frameDescr: this.translate('stage3_pro.other_details.frame_text'),
    customTitle: this.translate('stage3_pro.other_details.custom_title'),
    customDescr: this.translate('stage3_pro.other_details.custom_text'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  otherSection:boolean = false;
  ngOnInit() {
  }

  otherSectionToggle(){
    this.otherSection = !this.otherSection;
  }

}
