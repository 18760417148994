import {OrderCreate} from '../../race-sims/models/orders/order-create.model';
import {isNullOrUndefined} from 'util';

export class ConfiguratorRepository {

  private static _instance: ConfiguratorRepository = null;

  private static storageKey = 'order';

  private constructor() {
    ConfiguratorRepository._instance = this;
  }

  public static getInstance() {
    if (ConfiguratorRepository._instance === null) {
      return new ConfiguratorRepository();
    }
    return ConfiguratorRepository._instance;
  }

  public store(data: any, key: string) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public retrieve(key: string): any {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  public clean(dto: OrderCreate): OrderCreate {
    // todo: implement
    return new OrderCreate();
  }

  public isStored(key: string) {
    const item = localStorage.getItem(key);
    if (isNullOrUndefined(item)) {
      return false;
    }
    return true;
  }
}
