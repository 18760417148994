export class ContactForm {
  message: string;
  name: string;
  phone: string;
  email: string;

  constructor() {
    this.message = '';
    this.name = '';
    this.phone = '';
    this.email = '';
  }
}
