import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-contact-contacts',
  templateUrl: './contact-contacts.component.html',
  styleUrls: ['./contact-contacts.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ContactContactsComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, public sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    
  }

  getMail(i) {
    const mailto = 'mailto:' + this.translate('contact.contacts.mail_' + i);
    return this.sanitizer.bypassSecurityTrustUrl(mailto);
  }
}
