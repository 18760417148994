
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';
import {Observable} from 'rxjs';


@Injectable()
export class InstagramService {
  url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${AppConfig.instagramApiToken}&count=8`;

  constructor(private http: HttpClient) {
  }

  getData(): Observable<any[]> {
    return this.http.get<any>(this.url).pipe(map(data => data.data));
  }
}
