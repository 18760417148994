import {Component, OnInit} from '@angular/core';
import {IMetaComponent} from '../../../../common/simulator-booking/IMetaComponent';
import {BookingDefinitions} from '../../../../common/simulator-booking/booking-definitions';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {BookingSettingService} from '../../../../common/simulator-booking/services/booking-setting.service';
import {AmountMetaService} from '../../../../common/simulator-booking/services/meta/amount-meta.service';
import {ExclusiveAmountMetaService} from '../../../../common/simulator-booking/services/meta/exclusive-amount-meta.service';

@Component({
  selector: 'app-exclusive-amount-meta',
  templateUrl: './exclusive-amount-meta.component.html',
  styleUrls: ['./exclusive-amount-meta.component.less']
})
export class ExclusiveAmountMetaComponent implements OnInit, IMetaComponent {

  get metaType() {
    return BookingDefinitions.metaTypes.exclusive_amount;
  }

  t = {
    label: 'Number of simulators'
  };

  amount;
  private service: ExclusiveAmountMetaService;
  constructor(private managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    this.service = new ExclusiveAmountMetaService(this.managerService, this.bookingSettingService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.initTranslations();
  }

  initTranslations(){
    this.t.label = this.service.getLabel();
  }

  ngOnInit() {
    this.t.label = this.service.getLabel();
    this.amount = this.service.getAmount();
  }

}
