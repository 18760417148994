import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DomSanitizer, Title, Meta} from '@angular/platform-browser';
import {SiteBase} from '../site-base';
import {AppDataProvider} from '../../common/app-data.provider';
import {ScriptHelper} from '../../shared/helpers/scripts.helper';

declare const Scroll: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']

})
export class HomeComponent extends SiteBase implements OnInit, AfterViewInit {


  constructor(public meta: Meta, private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('meta.home_racesims.title'));
    this.meta.updateTag({ name: 'title', content:this.translate('meta.home_racesims.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.home_racesims.description') });
  }

  ngAfterViewInit() {
    // Scroll.init();
    ScriptHelper.initScrollMenu();
  }
}
