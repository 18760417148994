import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer, Meta} from '@angular/platform-browser';
import {ConfiguratorDataManager} from '../configurator.data-manager';
import {ConfiguratorFormManager} from '../configurator.form-manager';
import {AppDataProvider} from '../../../common/app-data.provider';
import {AppConfig} from '../../../app.config';
import {LangHelper} from '../../../race-sims/helpers/lang-helper';

@Component({
  selector: 'app-configurator-step-6',
  templateUrl: './configurator-step-6.component.html',
  styleUrls: ['./configurator-step-6.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfiguratorStep6Component extends SiteBase implements OnInit {

  t = {
    title: this.translate('configurator.form.title'),
    stepTitle: this.translate('configurator.form.step_6_title'),
    stepSubtitle: this.translate('configurator.form.step_6_subtitle'),
    bankAccountNum: this.translate('configurator.form.bank_account_num'),
    variableSymbol: this.translate('configurator.form.variable_symbol'),
    amount: this.translate('configurator.form.amount'),
    noteOnCashPayment: this.translate('configurator.form.payment_note_on_cash_payment'),
    addressOnCashPayment: this.translate('configurator.form.address'),
    note1: this.translate('configurator.form.payment_note_1'),
    note2: this.translate('configurator.form.payment_note_2'),
    share: this.translate('configurator.form.share_your_order'),
    messaging: this.translate('configurator.form.social_media_messaging'),
    tax: this.translate('configurator.form.tax')

  };

  s = {
    pickupName: this.provider.getSetting('configurator_pickup_name'),
    address: this.provider.getSetting('configurator_pickup_street'),
    addressNo: this.provider.getSetting('configurator_pickup_street_number'),
    zip: this.provider.getSetting('configurator_pickup_zip'),
    city: this.provider.getSetting('configurator_pickup_city'),
    country: this.provider.getSetting('configurator_pickup_country'),

    bankNum: this.provider.getSetting('order_bank_account_number'),
    bankIban: this.provider.getSetting('order_bank_account_iban'),
    bankSwift: this.provider.getSetting('order_bank_account_swift'),
    messenger: this.sanitizer.bypassSecurityTrustUrl(this.provider.getSetting('social_media_messenger_link')),
    whatsapp: this.sanitizer.bypassSecurityTrustUrl(this.provider.getSetting('social_media_whatsapp_link')),

    // TODO: Change share links
    shareGPlus: this.sanitizer.bypassSecurityTrustUrl(this.provider.getSetting('social_media_whatsapp_link')),
    shareFacebook: this.sanitizer.bypassSecurityTrustUrl(this.provider.getSetting('app_share_facebook')),
    shareTwitter: this.sanitizer.bypassSecurityTrustUrl(this.provider.getSetting('app_share_twitter')),

  };

  @Input() dataManager: ConfiguratorDataManager;
  @Input() formManager: ConfiguratorFormManager;

  @Output() next = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);


  }

  ngOnInit() {

  }

  actionNext(e) {
    e.preventDefault();
    this.next.emit(true);
  }

  actionPrev(e) {
    e.preventDefault();
    this.prev.emit(true);
  }
}
