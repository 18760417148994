import {Component, OnInit} from '@angular/core';
import {SiteBase} from "../../site-base";
import {AppDataProvider} from "../../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-wmtd-products',
  templateUrl: './wmtd-products.component.html',
  styleUrls: ['./wmtd-products.component.less']
})
export class WmtdProductsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('products.events.title'),
    showdetails: this.translate('general.variables.btn_more_info'),
  };

  routerLinks = {
    1: '/stage1-pro',
    2: '/stage3-pro',
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
