import {CourseBookingFormManager} from './course-booking.form-manager';
import {Form} from '@angular/forms';
import {CourseBookingDetail} from '../models/course-booking-detail.model';
import {CourseBookingForm} from '../models/course-booking-form.model';
import {CourseDetail} from '../../courses/course-detail.model';
import {LangHelper} from '../../../race-sims/helpers/lang-helper';
import {CourseBookingCreateFormManager} from './course-booking-create.form-manager';

export class CourseBookingCreateFrontendFormManager extends CourseBookingCreateFormManager implements CourseBookingFormManager {


  isValid(): boolean {
    return this.Form.valid;
  }

  get UniversalDate(){
    let date = this.Course.date;
    date = date.replace(/-/g , '/');
    return date;
  }
}
