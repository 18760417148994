import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent} from '@angular/material';
import {Moment} from 'moment';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {LangHelper} from '../../../../race-sims/helpers/lang-helper';
import {SimulatorBookingService} from '../../../../common/simulator-booking/services/simulator-booking.service';
import {DateRangeBookingMetaService} from '../../../../common/simulator-booking/services/meta/date-range-booking-meta.service';
import {SimulatorBooking} from '../../../../common/simulator-booking/models/simulator-booking.model';
import {DateRangeBookingMetaValue} from '../../../../common/simulator-booking/models/meta/values/date-range-booking-meta.value';
import {DateRangeBookingAvailabilitySchedule} from '../../../../common/simulator-booking/models/date-range-booking-availability-schedule.model';

declare const $: any;

@Component({
  selector: 'app-date-range-booking-meta',
  templateUrl: './date-range-booking-meta.component.html',
  styleUrls: ['./date-range-booking-meta.component.less'],
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD'
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class DateRangeBookingMetaComponent implements OnInit, AfterViewInit {


  dpFromId = 'app-date-range-booking-meta-dp-from';
  dpToId = 'app-date-range-booking-meta-dp-to';

  t = {
    dpFromLabel: 'Select pickup date',
    dpToLabel: 'Select return date'
  };

  isPickupDateSelected = false;
  isStartDatepickerScheduleReady = false;
  isEndDatepickerScheduleReady = false;
  availableStartDateSchedule: DateRangeBookingAvailabilitySchedule;
  availableEndDateSchedule: DateRangeBookingAvailabilitySchedule;

  private service: DateRangeBookingMetaService;

  constructor(private managerService: BookingManagerProviderService,
              private dateAdapter: DateAdapter<Moment>,
              private simulatorBookingService: SimulatorBookingService) {
    this.service = new DateRangeBookingMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.dateAdapter.setLocale(LangHelper.getLang());
    this.initTranslations();
  }

  async ngOnInit() {
    const model = new SimulatorBooking();
    model.type = this.service.getEventType();
    this.service.setMetaValue(new DateRangeBookingMetaValue());
    model.meta = this.managerService.metaManager.getAll();
    this.simulatorBookingService
      .getAvailabilitySchedule(model, DateRangeBookingMetaValue)
      .subscribe(res => this.onInitSuccess(res), err => this.onInitErr(err));
  }

  initTranslations() {
    this.t.dpFromLabel = this.service.getFromLabel();
    this.t.dpToLabel = this.service.getToLabel();
  }

  ngAfterViewInit() {
  }

  startDateFilter = (date: Moment): boolean => {

    // TODO: FIX AMOUNT !!
    const dateStr = date.format(LangHelper.dateInternalFormat);
    return this.availableStartDateSchedule.availableList.map(item => item.date).includes(dateStr);
  };

  endDateFilter = (date: Moment): boolean => {
    // TODO: FIX AMOUNT !!
    const dateStr = date.format(LangHelper.dateInternalFormat);
    return this.availableEndDateSchedule.availableList.map(item => item.date).includes(dateStr);
  };

  onInitSuccess(schedule: DateRangeBookingAvailabilitySchedule) {
    this.availableStartDateSchedule = schedule;
    this.isStartDatepickerScheduleReady = true;
  }

  onInitErr(err) {
    // console.log(err);
  }

  notInPast(date: Date) {
    const now = new Date();
    return true;
  }

  inNearFuture(date: Date) {
    const now = new Date();
    // now.
  }

  onStartDateChange(event: MatDatepickerInputEvent<Moment>) {
    this.isPickupDateSelected = true;
    const date = event.value.format(LangHelper.dateInternalFormat);

    const metaVal = new DateRangeBookingMetaValue(date);
    this.service.setMetaValue(metaVal);

    const model = new SimulatorBooking();
    model.type = this.service.getEventType();
    model.meta = this.managerService.metaManager.getAll();
    this.simulatorBookingService
      .getAvailabilitySchedule(model, DateRangeBookingMetaValue)
      .subscribe(res => this.onEndDateScheduleRequestSuccess(res), err => this.onEndDateScheduleRequestErr(err));
  }

  onEndDateScheduleRequestSuccess(schedule: DateRangeBookingAvailabilitySchedule) {
    this.availableEndDateSchedule = schedule;
    this.isEndDatepickerScheduleReady = true;
  }

  onEndDateScheduleRequestErr(err) {
    console.error(err);
  }

  onEndDateChange(event: MatDatepickerInputEvent<Moment>) {
    const val = this.service.getMetaValue();
    val.to = event.value.format(LangHelper.dateInternalFormat);
    this.service.setMetaValue(val);
  }
}
