import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ScriptHelper} from '../../shared/helpers/scripts.helper';
import {DomSanitizer,Title, Meta} from '@angular/platform-browser';
import {SiteBase} from '../site-base';
import {AppDataProvider} from '../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsComponent extends SiteBase implements OnInit, AfterViewInit {


  constructor(public meta: Meta, private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('meta.products.title'));
    this.meta.updateTag({ name: 'title', content:this.translate('meta.products.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.products.description') });
}

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }


}
