import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-academy-booking',
  templateUrl: './academy-booking.component.html',
  styleUrls: ['./academy-booking.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AcademyBookingComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('academy.booking.title'),
    text: this.translate('academy.booking.text'),
    bookNow: this.translate('general.variables.btn_book_now')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
