export class BookingCustomer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: {
    country_code: string;
    number: string;
  };

  constructor() {
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.phone = {country_code: '', number: ''};
  }
}
