import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user.model';
import {AppConfig} from '../../app.config';
import {IndexService} from '../shared/services/index.service';
import {Collection} from '../models/collection.model';
import {DetailService} from '../shared/services/detail.service';
import {OrderIndex} from '../models/orders/order-index.model';
import {Order} from '../models/order.model';
import {OrderDetail} from '../models/orders/order-detail.model';
import {OrderCreate} from '../models/orders/order-create.model';
import {ProductListItem} from '../models/products/product-list-item.model';
import {ExchangeRate} from '../models/products/exchange-rate.model';

@Injectable()
export class ProductService {

  private baseUrl = `${AppConfig.serverUrl}/api/v1/products`;

  constructor(private http: HttpClient) {
  }


  private getProductsByType(type: string): Observable<Collection<ProductListItem>> {
    return this.http.get<Collection<ProductListItem>>(`${this.baseUrl}/type/${type}`);
  }

  getExchangeRates() {
    return this.http.get<Collection<ExchangeRate>>(`${this.baseUrl}/exchange-rate`);
  }

  getBases(): Observable<Collection<ProductListItem>> {
    return this.getProductsByType('base');
  }

  getWheels() {
    return this.getProductsByType('wheel');
  }

  getPedals() {
    return this.getProductsByType('pedals');
  }

  getMonitors() {
    return this.getProductsByType('monitor');
  }

  getOther() {
    return this.getProductsByType('other');
  }

  all() {
    return this.http.get<Collection<ProductListItem>>(`${this.baseUrl}`); 
  }
}
