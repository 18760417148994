import { Component, OnInit } from '@angular/core';
import {AppDataProvider} from "../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";
import {SiteBase} from '../site-base';

@Component({
  selector: 'app-test-drive',
  templateUrl: './test-drive.component.html',
  styleUrls: ['./test-drive.component.less']
})
export class TestDriveComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
