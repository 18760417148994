import { Component, OnInit } from '@angular/core';
import {SiteBase} from "../../site-base";
import {DomSanitizer} from "@angular/platform-browser";
import {AppDataProvider} from "../../../common/app-data.provider";

@Component({
  selector: 'app-sae-landing',
  templateUrl: './sae-landing.component.html',
  styleUrls: ['./sae-landing.component.less']
})
export class SaeLandingComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
