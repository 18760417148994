import { Component, OnInit } from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-academy-videos',
  templateUrl: './academy-videos.component.html',
  styleUrls: ['./academy-videos.component.less']
})
export class AcademyVideosComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('academy.videos.title'),
    vid1Title: this.translate('academy.videos.video_1_title'),
    vid1Text: this.translate('academy.videos.video_1_text'),
    vid1Link: this.safeResourceUrl('academy.videos.video_1_link'),
    vid2Title: this.translate('academy.videos.video_2_title'),
    vid2Text: this.translate('academy.videos.video_2_text'),
    vid2Link: this.safeResourceUrl('academy.videos.video_2_link'),
    vid3Title: this.translate('academy.videos.video_3_title'),
    vid3Text: this.translate('academy.videos.video_3_text'),
    vid3Link: this.safeResourceUrl('academy.videos.video_3_link')
  };


  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
