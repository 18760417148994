import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppDataProvider} from '../../../common/app-data.provider';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-home-event',
  templateUrl: './home-event.component.html',
  styleUrls: ['./home-event.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class HomeEventComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  bypassStyle(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
  }

  translate(key: string): string {
    return this.provider.getTranslation(key);
  }

  ngOnInit() {
  }

}
