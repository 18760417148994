import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PriceMetaService} from '../../../../common/simulator-booking/services/meta/price-meta.service';
import {BookingSettingService} from '../../../../common/simulator-booking/services/booking-setting.service';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {NoteMetaService} from '../../../../common/simulator-booking/services/meta/note-meta.service';

@Component({
  selector: 'app-note-meta',
  templateUrl: './note-meta.component.html',
  styleUrls: ['./note-meta.component.less']
})
export class NoteMetaComponent implements OnInit, AfterViewInit {

  private service: NoteMetaService;

  t = {
    note: 'label'
  };

  price: string;

  constructor(private managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    this.service = new NoteMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.initTranslations();
  }

  initTranslations(){
    this.t.note = this.service.getNote();
  }

  ngOnInit() {}

  ngAfterViewInit() {}
}
