import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-academy-events',
  templateUrl: './academy-events.component.html',
  styleUrls: ['./academy-events.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcademyEventsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('academy.events.title'),
    bookNow: this.translate('general.variables.btn_book_now'),
    exclusiveMoreInfoBtn: this.translate('academy.events.event_2_more_info_btn'),
    exclusiveMoreInfoUrl: this.sanitizer.bypassSecurityTrustUrl(this.translate('academy.events.event_2_more_info_url')),
  };

  routerLinks = {
    1: '/booking/teambuilding',
    2: '/booking/exclusive',
    3: '/booking/competition'
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private router: Router) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }


  navigateToBooking(e, id: number) {
    e.preventDefault();
    const setting = this.provider.getSetting(`academy_event_type_${id}`);
    this.router.navigate([`/booking/${setting}`]);
  }
}
