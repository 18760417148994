import {Collection} from './collection.model';
import {Booking} from './booking.model';
import {AcademyLocation} from './academy-location.model';
import {BookingCustomer} from './booking-customer.model';

export class BookingCreateEvent {
  type: string;
  location: {
    id: number
  };
  date: string;
  event_time: {
    hour: number
  };
}

export class BookingCreate {
  // simulator_amount: number;
  // price: number;
  // events: BookingCreateEvent[];
  customer: BookingCustomer;
  meta: any;
  type: string;
  // locations: AcademyLocation[];
}
