import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IMetaComponent} from '../../../../common/simulator-booking/IMetaComponent';
import {BookingDefinitions} from '../../../../common/simulator-booking/booking-definitions';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {RaceTrackMetaService} from '../../../../common/simulator-booking/services/meta/race-track-meta.service';
import {CarClassMetaService} from '../../../../common/simulator-booking/services/meta/car-class-meta.service';
import {CarClassMetaValue} from '../../../../common/simulator-booking/models/meta/values/car-class-meta-value.model';
import {AppDataProvider} from '../../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-car-class-meta',
  templateUrl: './car-class-meta.component.html',
  styleUrls: ['./car-class-meta.component.less']
})
export class CarClassMetaComponent implements OnInit, AfterViewInit, IMetaComponent {

  get metaType() {
    return BookingDefinitions.metaTypes.car_class;
  }

  BookingDataManager;
  BookingFormManager;

  t = {
    label: 'label'
  };

  selectIdentifier = 'app-car-class-meta-select';
  private service: CarClassMetaService;

  $cars: any;
  cars: string[] = ['c1', 'c2'];

  constructor(private managerService: BookingManagerProviderService, private appDataProvider: AppDataProvider) {
    this.service = new CarClassMetaService(this.managerService, this.appDataProvider);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.t.label = this.service.getLabel();
    this.cars = this.service.getCars();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const that = this;
    this.$cars = $(`#${this.selectIdentifier}`).selectpicker({liveSearch: true, size: 10});
    this.$cars.on('changed.bs.select', function (e, clickedIndex) {
      that.onChange(clickedIndex);
    });
  }

  onChange(idx: number) {
    this.service.setMetaValue(new CarClassMetaValue(this.cars[idx]));
  }
}
