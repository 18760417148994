import {NgModule} from '@angular/core';
import {PreloadAllModules, RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../site/home/home.component';
import {SimulatorComponent} from '../site/simulator/simulator.component';
import {Stage3ProComponent} from '../site/stage3-pro/stage3-pro.component';
import {AcademyComponent} from '../site/academy/academy.component';
import {ChampionshipComponent} from '../site/championship/championship.component';
import {ContactComponent} from '../site/contact/contact.component';
import {ConfiguratorComponent} from '../site/configurator/configurator.component';
import {BookingComponent} from '../site/booking/booking.component';
import {ConfiguratorResolver} from '../site/configurator/configurator.resolver';
import {CourseBookingComponent} from '../site/course-booking/course-booking.component';
import {CourseResolver} from '../site/course-booking/course.resolver';
import {ProductsComponent} from '../site/products/products.component';
import {SupportComponent} from '../site/support/support.component';
import {AboutUsComponent} from '../site/about-us/about-us.component';
import {ScienceAndEducationComponent} from '../site/science-and-education/science-and-education.component';
import {WhatMakesTheDifferenceComponent} from "../site/what-makes-the-difference/what-makes-the-difference.component";

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'simulator',
    redirectTo: 'stage1-pro',
    pathMatch: 'full'
  },
  {
    path: 'science-and-education',
    component: ScienceAndEducationComponent
  },
  {
    path: 'what-makes-the-difference',
    component: WhatMakesTheDifferenceComponent
  },
  {
    path: 'stage1-pro',
    component: SimulatorComponent,
  },
  {
    path: 'stage3-pro',
    component: Stage3ProComponent,
  }, {
    path: 'products',
    component: ProductsComponent,
  },
  {
    path: 'academy',
    component: AcademyComponent,
  },
  {
    path: 'championship',
    component: ChampionshipComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },

  {
    path: 'configurator',
    component: ConfiguratorComponent,
    resolve: {collection: ConfiguratorResolver}
  },
  {
    path: 'booking',
    component: BookingComponent,
  },
  {
    path: 'booking/:type',
    component: BookingComponent,
  },
  // COURSES
  // {
  //   path: 'course/:courseId/:courseSlug',
  //   component: CourseBookingComponent,
  //   resolve: {course: CourseResolver}
  // }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false} // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ],
  // providers: [
  //   {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy}
  // ]
})
export class AppRoutingModule {
}
