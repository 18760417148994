import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfiguratorDataManager} from '../configurator.data-manager';
import {ConfiguratorFormManager} from '../configurator.form-manager';
import {ConfiguratorBase} from '../configurator-base';
import {AppDataProvider} from '../../../common/app-data.provider';
import {ProductService} from '../../../race-sims/services/product.service';

@Component({
  selector: 'app-configurator-step-2',
  templateUrl: './configurator-step-2.component.html',
  styleUrls: ['./configurator-step-2.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfiguratorStep2Component extends ConfiguratorBase implements OnInit {

  t = {
    title: this.translate('configurator.form.title'),
    totalCost: this.translate('configurator.form.total_cost'),
    selectedOptions: this.translate('configurator.form.selected_options'),
    display: this.translate('configurator.form.display'),
    stepTitle: this.translate('configurator.form.step_2_title'),
    stepSubtitle: this.translate('configurator.form.step_2_subtitle'),
    pedals: this.translate('configurator.form.pedals'),
    shifters: this.translate('configurator.form.shifters_and_handbrake'),
    motion: this.translate('configurator.form.motion_and_accessories'),
    prev: this.translate('configurator.form.previous_step'),
    next: this.translate('configurator.form.next_step'),
    tax: this.translate('configurator.form.tax')
  };

  @Input() dataManager: ConfiguratorDataManager;
  @Input() formManager: ConfiguratorFormManager;
  @Input() imgUrl: string;

  @Output() next = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();
  @Output() onImgUrlChanged = new EventEmitter<string>();


  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private productService: ProductService) {
    super(provider, sanitizer);
  }

  ngOnInit() {}

}
