import {SiteBase} from '../site-base';
import {EventEmitter} from '@angular/core';
import {ConfiguratorFormManager} from './configurator.form-manager';

export class ConfiguratorBase extends SiteBase {
  imageId = 'config-img';
  next: any;
  prev: any;
  formManager: ConfiguratorFormManager;
  dataManager: any;
  onImgUrlChanged: EventEmitter<any>;

  actionNext(e) {
    e.preventDefault();
    this.next.emit(true);
  }

  actionPrev(e) {
    e.preventDefault();
    this.prev.emit(true);
  }

  actionSelect(item) {
    this.formManager.toggleSelection(item);
    this.processChanges();
  }

  actionRemoveSelection(item) {
    this.formManager.unselect(item);
    this.processChanges();
  }

  processChanges() {
    this.formManager.calculatePrice();
    this.formManager.calculateConfigurationCode();
    this.actionLoadConfigurationImage();
    this.formManager.store();
  }

  actionLoadConfigurationImage() {
    // todo: spinner
    // const url = this.dataManager.getConfigurationImageUrl(this.formManager.getConfigurationCode());
    // const img = document.getElementById(this.imageId);
    // img.onload = () => this.afterImageLoaded();
    // img.setAttribute('src', url);
    this.imgUrlChanged();
  }

  imgUrlChanged() {
    this.onImgUrlChanged.emit('url');
  }

  afterImageLoaded(): any {
    // console.log('AFTER');
    // todo: end spinner
  }
}
