import {Injectable} from '@angular/core';
import {AppDataProvider} from '../../app-data.provider';
import {forEach} from '@angular/router/src/utils/collection';
import {BookingDataManager} from '../booking.data-manager';
import {BookingFormManager} from '../booking.form-manager';
import {BookingMetaManager} from '../booking-meta.manager';

@Injectable()
export class BookingManagerProviderService {

  private _dataManager: BookingDataManager;
  private _formManager: BookingFormManager;
  private _metaManager: BookingMetaManager;

  get dataManager(): BookingDataManager {
    return this._dataManager;
  }

  set dataManager(value: BookingDataManager) {
    this._dataManager = value;
  }

  get formManager(): BookingFormManager {
    return this._formManager;
  }

  set formManager(value: BookingFormManager) {
    this._formManager = value;
  }

  get metaManager(): BookingMetaManager {
    return this._metaManager;
  }

  set metaManager(value: BookingMetaManager) {
    this._metaManager = value;
  }

}
