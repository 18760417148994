

import {Injectable} from '@angular/core';
import {AppConfig} from '../../app.config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Collection} from '../../race-sims/models/collection.model';
import {Translation} from '../models/translation.model';
import {SettingIndex} from '../../race-sims/models/settings/setting.index.model';

@Injectable()
export class AppDataServcice {
  private siteItemsUrl = `${AppConfig.serverUrl}/api/v1/site-items/items-public`;
  private settingsUrl = `${AppConfig.serverUrl}/api/v1/settings`;

  constructor(private http: HttpClient) { }



  siteItems(): Observable<Collection<Translation>> {
    return this.http.get<Collection<Translation>>(this.siteItemsUrl);
  }

  settings(): Observable<Collection<SettingIndex>> {
    return this.http.get<Collection<SettingIndex>>(this.settingsUrl);
  }

}
