import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {CoachMetaValue} from '../../models/meta/values/coach-meta-value.model';
import {PriceMetaInterface} from './contracts/price-meta.interface';
import {AmountMetaInterface} from './contracts/amount-meta.interface';
import {CountMetaInterface} from './contracts/count-meta.interface';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';
import {LocationMetaValue} from '../../models/meta/values/location-meta-value.model';
import {CateringMetaValue} from '../../models/meta/values/catering-meta-value.model';

export interface CateringMetaContract {
  key: string;
  price: number;
  is_hidden: boolean;
  is_checked: boolean;
  is_locked: boolean;
  is_multiplied_by_amount: boolean;
  is_multiplied_by_booking_count: boolean;
  translations: {
    en: {
      label: string;
      description: string;
    },
    de: {
      label: string;
      description: string;
    }
  };
}

export class CateringMetaService extends CommonMetaService<CateringMetaContract, CateringMetaValue> implements PriceMetaInterface, ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.catering;
  static changeEventIdentifier = 'meta.' + CateringMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService) {
    super(managerService);
    const val = this.getMetaValue() !== undefined ? this.getMetaValue() : new CateringMetaValue(this.getMetaSetting().is_checked, this.getPrice());
    this.setMetaValue(val, false);
  }

  getMetaType() {
    return CateringMetaService.metaType;
  }

  getChangeEventIdentifier() {
    return CateringMetaService.changeEventIdentifier;
  }

  getLabel() {
    return this.getTranslatedAttr('label');
  }

  getDescription() {
    return this.getTranslatedAttr('description');
  }

  getPrice(isChecked = null): number {
    const setting = this.getMetaSetting();
    let price = setting.price;
    if (setting.is_multiplied_by_amount) {
      const amountType = this.managerService.metaManager.getAmountMetaType();
      const amountMetaService = this.managerService.metaManager.getService<AmountMetaInterface>(amountType);
      const amount = amountMetaService.getAmount();
      price *= amount;
    }
    if (setting.is_multiplied_by_booking_count) {
      const countType = this.managerService.metaManager.getCountMetaType();
      const countMetaService = this.managerService.metaManager.getService<CountMetaInterface>(countType);
      const count = countMetaService.getCount();
      price *= count;
    }
    const metaValue = this.getMetaValue();
    isChecked = isChecked === null ? setting.is_checked : isChecked;
    if (metaValue !== undefined) {
      isChecked = metaValue.is_checked;
    }
    price = isChecked ? price : 0;
    this.setMetaValueSilently(new CateringMetaValue(isChecked, price));
    return price;
  }

  setMetaValueSilently(val) {
    this.managerService.metaManager.setMeta(this.getMetaType(), val).then();
  }

  isValid() {
    const metaValue = this.getMetaValue();
    return metaValue.is_checked !== undefined && metaValue.price !== undefined;
  }
}
