import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SiteBase} from "../site-base";
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import {AppDataProvider} from "../../common/app-data.provider";
import {ScriptHelper} from '../../shared/helpers/scripts.helper';

@Component({
  selector: 'app-science-and-education',
  templateUrl: './science-and-education.component.html',
  styleUrls: ['./science-and-education.component.less']
})
export class ScienceAndEducationComponent extends SiteBase implements OnInit, AfterViewInit {

  constructor(public meta: Meta, private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('meta.science_and_education.title'));
    this.meta.updateTag({ name: 'title', content: this.translate('meta.science_and_education.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.science_and_education.description') });
  }

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }
}
