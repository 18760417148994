import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfiguratorDataManager} from '../configurator.data-manager';
import {ConfiguratorFormManager} from '../configurator.form-manager';
import {ConfiguratorBase} from '../configurator-base';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-configurator-step-3',
  templateUrl: './configurator-step-3.component.html',
  styleUrls: ['./configurator-step-3.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfiguratorStep3Component extends ConfiguratorBase implements OnInit {

  t = {
    title: this.translate('configurator.form.title'),
    totalCost: this.translate('configurator.form.total_cost'),
    selectedOptions: this.translate('configurator.form.selected_options'),
    stepTitle: this.translate('configurator.form.step_3_title'),
    stepSubtitle: this.translate('configurator.form.step_3_subtitle'),
    computer: this.translate('configurator.form.computer'),
    software: this.translate('configurator.form.software_and_support'),
    prev: this.translate('configurator.form.previous_step'),
    next: this.translate('configurator.form.next_step'),
    tax: this.translate('configurator.form.tax')
  };

  @Input() dataManager: ConfiguratorDataManager;
  @Input() formManager: ConfiguratorFormManager;
  @Input() imgUrl: string;

  @Output() next = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();
  @Output() onImgUrlChanged = new EventEmitter<string>();


  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
