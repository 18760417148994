import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef, forwardRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {SiteBase} from '../site-base';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {BookingFormManager} from '../../common/simulator-booking/booking.form-manager';
import {BookingDataManager} from '../../common/simulator-booking/booking.data-manager';
import {AcademyLocation} from '../../race-sims/models/academy-location.model';
import {BookingService} from '../../race-sims/services/booking.service';
import {Collection} from '../../race-sims/models/collection.model';
import {AvailableTimeRequest} from '../../race-sims/models/available-time-request.model';
import {HttpErrorResponse} from '@angular/common/http';
import {BookingTime} from '../../race-sims/models/booking-time.model';
import {BookingPriceRequest} from '../../race-sims/models/booking-price-request.model';
import {BookingPriceResponse} from '../../race-sims/models/booking-price-response.model';
import {BookingSettingService} from '../../common/simulator-booking/services/booking-setting.service';
import {MetaDirective} from '../../common/simulator-booking/meta.directive';
import {AmountMetaComponent} from './meta/amount-meta/amount-meta.component';
import {BookingDefinitions} from '../../common/simulator-booking/booking-definitions';
import {IMetaComponent} from '../../common/simulator-booking/IMetaComponent';
import {BookingMetaManager} from '../../common/simulator-booking/booking-meta.manager';
import {BookingManagerProviderService} from '../../common/simulator-booking/services/booking-manager-provider.service';
import {BookingServiceConnectors} from '../../common/simulator-booking/booking-service-connectors';
import {PriceMetaService} from '../../common/simulator-booking/services/meta/price-meta.service';
import {SimulatorBookingService} from '../../common/simulator-booking/services/simulator-booking.service';
import {BookingConnectors} from '../../shared/booking-connectors';
import {AppDataProvider} from '../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.less']
})
export class BookingComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('booking.form.title'),
    step1: this.translate('booking.form.step_1'),
    step2: this.translate('booking.form.step_2'),
    step1Subtitle: this.translate('booking.form.step_1_subtitle'),
    step2Subtitle: this.translate('booking.form.step_2_subtitle'),
    selEvtType: this.translate('booking.form.sel_event_type'),
    selNumSims: this.translate('booking.form.sel_num_simulators'),
    selAcademy: this.translate('booking.form.sel_academy'),
    selDate: this.translate('booking.form.sel_date'),
    selTime: this.translate('booking.form.choose_time'),
    infoNote: this.translate('booking.form.info_note'),
    discountNote: this.translate('booking.form.discount_note'),
    totalPrice: this.translate('booking.form.total_price'),
    name: this.translate('booking.form.name'),
    surname: this.translate('booking.form.surname'),
    countryCode: this.translate('booking.form.country_code'),
    phoneNum: this.translate('booking.form.phone_num'),
    email: this.translate('booking.form.email'),
    aggreeTermsText: this.translate('booking.form.aggree_with_terms'),
    aggreeTermsLinkText: this.translate('booking.form.aggree_with_terms_link_text'),
    aggreeTermsLinkUrl: this.safeUrl('booking.form.aggree_with_terms_link_url'),
    noteSelectedDate: this.translate('booking.form.note_selected_date'),
    btnSendMsg: this.translate('booking.form.btn_send_message'),
    noteBookingResponse: this.translate('booking.form.note_booking_response'),

    saveSuccess: this.translate('booking.form.on_send_success_message'),
    saveError: this.translate('booking.form.on_send_fail_message')
  };

  isChangedFromPreselected = false;
  selectedType = null;

  typeSelectId = 'sp-type';
  simulatorsSelectId = 'sp-simulators';
  academySelectId = 'sp-academy';
  datepickerId = 'datepicker';

  $type: any;
  $simulators: any;
  $academy: any;
  $datepicker: any;

  formManager: BookingFormManager;
  dataManager: BookingDataManager;
  metaManager: BookingMetaManager;
  priceEmpty = true;
  timesEmpty = true;

  @ViewChild(MetaDirective) appMeta: MetaDirective;
  metaComponents: { key: string, componentRef: ComponentRef<any> }[];

  constructor(private titleService: Title,
              protected provider: AppDataProvider,
              protected sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private bookingService: BookingService,
              private bookingSettingService: BookingSettingService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private bookingManagerProviderService: BookingManagerProviderService,
              private simulatorBookingService: SimulatorBookingService
  ) {
    super(provider, sanitizer);

    this.dataManager = new BookingDataManager(this.provider.getLocations(), this.provider.getSettingGroup('booking'), this.bookingSettingService);
    this.formManager = new BookingFormManager(this.dataManager);
    this.metaManager = new BookingMetaManager();
    this.bookingManagerProviderService.dataManager = this.dataManager;
    this.bookingManagerProviderService.formManager = this.formManager;
    this.bookingManagerProviderService.metaManager = this.metaManager;
  }

  loadMeta() {
    this.preselectType();
    const type = this.formManager.eventType;
    const metaList = this.bookingSettingService.getMetaListForBookingType(type);
    // clear container
    const viewContainerRef = this.appMeta.viewContainerRef;
    viewContainerRef.clear();
    this.metaComponents = [];

    for (const item of metaList) {
      const connector = BookingConnectors.getConnector(item.key);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory<any>(connector.component);
      const componentRef = viewContainerRef.createComponent(componentFactory);
      // componentRef.instance.BookingDataManager = this.dataManager;
      // componentRef.instance.BookingFormManager = this.formManager;
      // componentRef.instance.BookngMetaManager = this.metaManager;
      this.metaComponents.push({key: item.key, componentRef: componentRef});
    }
  }

  ngOnInit() {
    this.loadMeta();

    this.titleService.setTitle(this.t.title + ' - ' + this.provider.getSetting('site_name'));

  }

  preselectType() {
    if(!this.isChangedFromPreselected) {
      this.selectedType = this.route.snapshot.paramMap.get('type');
      if (!isNullOrUndefined(this.selectedType)) {
        let exists = false;
        for (const item of this.dataManager.eventTypes) {
          if (item.key === this.selectedType) {
            exists = true;
            this.formManager.eventType = item.key;
          }
        }
        if (!exists) {
          this.selectedType = this.dataManager.eventTypes[0].key;
          this.formManager.eventType = this.selectedType;
        }
      }
    }
  }

  ngAfterViewInit() {
    const that = this;

    this.$type = $(`#${this.typeSelectId}`).selectpicker();
    // // this.$type.on('click', function(){console.log('clicked')});
    // this.$simulators = $(`#${this.simulatorsSelectId}`).selectpicker();
    // this.$academy = $(`#${this.academySelectId}`).selectpicker();
    // this.$datepicker = $(`#${this.datepickerId}`).datetimepicker({
    //   autoclose: true,
    //   pickTime: false,
    //   format: 'd. M yyyy',
    //   componentIcon: '.mdi.mdi-calendar',
    //   navIcons: {
    //     rightIcon: 'mdi mdi-chevron-right',
    //     leftIcon: 'mdi mdi-chevron-left'
    //   },
    //   useCurrent: true,
    //   pickerPosition: 'bottom-left'
    // });
    //
    this.$type.on('changed.bs.select', function (e, clickedIndex) {
      that.onTypeChange(clickedIndex);
    });
    //
    // this.$academy.on('changed.bs.select', function (e, clickedIndex) {
    //   that.onAcademyChange(clickedIndex);
    // });
    //
    // this.$simulators.on('changed.bs.select', function (e, clickedIndex) {
    //   that.onSimulatorsChange(clickedIndex);
    // });
    //
    // this.$datepicker.on('changeDate', function (e) {
    //   that.onDateChange(e.date);
    // });

  }

  onAcademyChange(index) {
    this.formManager.academy = this.dataManager.locations[index].id;
    if (this.formManager.isDateSelected()) {
      this.loadTimes();
    }
  }

  onSimulatorsChange(index) {
    this.formManager.simulatorCount = this.dataManager.simulators[index];
    this.actionLoadPrice();
  }

  onTypeChange(index) {
    this.formManager.eventType = this.dataManager.eventTypes[index].key;
    this.metaManager.clear();
    this.isChangedFromPreselected = true;
    this.loadMeta();
    if (this.formManager.isDateSelected()) {
      this.loadTimes();
    }
  }


  onDateChange(date: Date) {
    this.formManager.date = `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getDate()}`;
    this.priceEmpty = true;
    this.loadTimes();
  }

  onTimeChecked(time: BookingTime) {
    this.formManager.toggleTime(time);
    this.actionLoadPrice();
  }

  actionLoadPrice() {
    if (this.formManager.isBookingFilled()) {
      const selectionConfig = {
        eventType: this.formManager.eventType,
        numSims: this.formManager.simulatorCount,
        location: this.formManager.academy,
        date: this.formManager.date
      };
      const bookingPriceRequest = BookingPriceRequest.fromSelectionConfig(selectionConfig, this.formManager.times);
      this.bookingService.price(bookingPriceRequest).subscribe((res: BookingPriceResponse) => this.onPriceRequestSuccess(res));
    } else {
      this.priceEmpty = true;
    }
  }

  onPriceRequestSuccess(res: BookingPriceResponse) {
    this.formManager.price = res.price;
    this.priceEmpty = false;
  }


  loadTimes() {
    const selectionConfig = {
      eventType: this.formManager.eventType,
      numSims: this.formManager.simulatorCount,
      location: this.formManager.academy,
      date: this.formManager.date
    };
    this.priceEmpty = true;
    this.timesEmpty = false;
    this.formManager.emptyTimes();
    const availableTimeRequest = AvailableTimeRequest.fromSelectionConfig(selectionConfig);
    this.bookingService.availableTimes(availableTimeRequest).subscribe(times => {
        this.dataManager.times = times;
        this.timesEmpty = false;
      },
      (errorResponse: HttpErrorResponse) => this.onLoadTimesError(errorResponse)
    );
  }

  onLoadTimesError(response: HttpErrorResponse) {
  }

  actionSubmitBooking() {
    const model = this.formManager.getModel();
    model.meta = this.metaManager.getAll();
    // console.log(model);
    this.simulatorBookingService.create(model).subscribe(res => this.afterSave(res), err => this.onSaveError(err));
    //this.bookingService.post(model).subscribe(res => this.afterSave(res), (error: HttpErrorResponse) => this.onSaveError(error));
  }

  afterSave(res) {
    alert(this.t.saveSuccess);
  }

  onSaveError(res) {
    alert(this.t.saveError);
  }

  isComplete(): boolean {
    return this.formManager.isComplete() && this.metaManager.isValid();
  }
}
