import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';

import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-general-business-offer',
  templateUrl: './general-business-offer.component.html',
  styleUrls: ['./general-business-offer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class GeneralBusinessOfferComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
