import {Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-academy-rent',
  templateUrl: './academy-rent.component.html',
  styleUrls: ['./academy-rent.component.less']
})
export class AcademyRentComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
