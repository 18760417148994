import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {DateRangeBookingMetaValue} from '../../models/meta/values/date-range-booking-meta.value';
import {CountMetaInterface} from './contracts/count-meta.interface';
import * as moment from 'moment';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';
import {InDayHourlyBookingMetaValue} from '../../models/meta/values/in-day-hourly-booking-meta.value';


interface DateRangeBookingMetaContract {
  key: string;
  future_booking_days_num: number;
  translations: {
    en: {
      label_from: string,
      label_to: string
    },
    de: {
      label_from: string,
      label_to: string
    }
  };
}

export class DateRangeBookingMetaService extends CommonMetaService<DateRangeBookingMetaContract, DateRangeBookingMetaValue>
  implements CountMetaInterface, ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.date_range_booking;
  static changeEventIdentifier = 'meta.' + DateRangeBookingMetaService.metaType + '.change';
  id: number = undefined;

  constructor(protected managerService: BookingManagerProviderService, id?) {
    super(managerService);
    if (id) {
      this.id = id;
    }
    const val = this.getMetaValue() !== undefined ? this.getMetaValue() : new DateRangeBookingMetaValue();
    this.setMetaValue(val, false);
  }

  getMetaType(): string {
    return DateRangeBookingMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return DateRangeBookingMetaService.changeEventIdentifier;
  }

  getFromLabel() {
    return this.getTranslatedAttr('label_from');
  }

  getToLabel() {
    return this.getTranslatedAttr('label_to');
  }

  getCount() {
    const metaValue = this.getMetaValue();
    if (metaValue.from === undefined || metaValue.to === undefined) {
      return 0;
    }
    const dateFrom = moment(metaValue.from);
    const dateTo = moment(metaValue.to);
    return dateFrom.diff(dateTo, 'days');
  }

  isValid() {
    const metaValue = this.getMetaValue();
    return metaValue.from !== undefined && metaValue.to !== undefined;
  }
}
