import {LangHelper} from '../../../../race-sims/helpers/lang-helper';
import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {AmountMetaValue} from '../../models/meta/values/amount-meta-value.model';
import {BookingSettingService} from '../booking-setting.service';
import {AmountMetaInterface} from './contracts/amount-meta.interface';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';

export interface AmountMetaContract {
  key: string;
  max: number;
  name_en: string;
  name_de: string;
}

export class AmountMetaService extends CommonMetaService<AmountMetaContract, AmountMetaValue>
  implements AmountMetaInterface, ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.amount;
  static changeEventIdentifier = 'meta.' + AmountMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    super(managerService);
    const val = this.getMetaValue() !== undefined ? this.getMetaValue() : new AmountMetaValue(this.getAmounts()[0]);
    this.setMetaValue(val, false);
  }

  static getAmountFromValue(val) {
    return val.num;
  }

  getChangeEventIdentifier(): string {
    return AmountMetaService.changeEventIdentifier;
  }

  getMetaType(): string {
    return AmountMetaService.metaType;
  }

  getLabel() {
    const lang = LangHelper.getLang();
    const meta = this.getMetaSetting();
    return meta['name_' + lang];
  }

  getAmounts(): number[] {
    const locationLimit = this.getLocationAmountLimit();
    let allowedAmount = this.getMetaSetting().max;
    if (locationLimit) {
      allowedAmount = Math.min(locationLimit, allowedAmount);
    }

    const list = Array.from({length: allowedAmount}, (v, k) => k + 1);
    return list;
  }

  getLocationAmountLimit() {
    const locationId = this.managerService.metaManager.getLocationMeta().location_id;
    try {
      const location = this.managerService
        .dataManager
        .getLocationById(locationId);
      return location.capacity;
    } catch (e) {
      console.error('An error occured during loading of location.', 'Location with id "' + locationId + '" does not exist');
    }
  }

  getAmount() {
    return this.getMetaValue().num;
  }

  isValid() {
    const metaVal = this.getMetaValue();
    if (metaVal.num !== undefined) {
      return true;
    }
    return false;
  }
}
