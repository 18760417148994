export class OrderCreateContactDetail {
  customer: OrderCreateCustomer;

  constructor() {
    this.customer = new OrderCreateCustomer();
  }

  static clone(model: OrderCreateContactDetail) {
    const clone = new OrderCreateContactDetail();
    clone.customer = OrderCreateCustomer.clone(model.customer);
    return clone;
  }
}

export class OrderCreateCustomer {
  first_name: string;
  last_name: string;
  email: string;
  address: OrderCreateCustomerAddress;
  phone: OrderCreateCustomerPhone;
  company: OrderCreateCustomerCompany;

  constructor() {
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.address = new OrderCreateCustomerAddress();
    this.phone = new OrderCreateCustomerPhone();
    this.company = new OrderCreateCustomerCompany();
  }

  static clone(model: OrderCreateCustomer): OrderCreateCustomer {
    const clone = new OrderCreateCustomer();
    clone.first_name = model.first_name;
    clone.last_name = model.last_name;
    clone.email = model.email;
    clone.address = OrderCreateCustomerAddress.clone(model.address);
    clone.phone = OrderCreateCustomerPhone.clone(model.phone);
    clone.company = OrderCreateCustomerCompany.clone(model.company);
    return clone;
  }
}

export class OrderCreateCustomerAddress {
  country: string;
  city: string;
  street: string;
  street_number: string;
  zip: string;

  constructor() {
    this.country = '';
    this.city = '';
    this.street = '';
    this.street_number = '';
    this.zip = '';
  }

  static clone(model: OrderCreateCustomerAddress): OrderCreateCustomerAddress {
    const clone = new OrderCreateCustomerAddress();
    clone.country = model.country;
    clone.city = model.city;
    clone.street = model.street;
    clone.street_number = model.street_number;
    clone.zip = model.zip;
    return clone;
  }
}

export class OrderCreateCustomerCompany {
  name: string;
  tax_id: string;

  constructor() {
    this.name = '';
    this.tax_id = '';
  }

  static clone(model: OrderCreateCustomerCompany): OrderCreateCustomerCompany {
    const clone = new OrderCreateCustomerCompany();
    clone.name = model.name;
    clone.tax_id = model.tax_id;
    return clone;
  }
}

export class OrderCreateCustomerPhone {
  country_code: string;
  number: string;

  constructor() {
    this.country_code = '';
    this.number = '';
  }

  static clone(model: OrderCreateCustomerPhone): OrderCreateCustomerPhone {
    const clone = new OrderCreateCustomerPhone();
    clone.country_code = model.country_code;
    clone.number = model.number;
    return clone;
  }
}

export class OrderCreateItem {
  id: number;
  amount: number;

  constructor(id: number) {
    this.id = id;
    this.amount = 1;
  }

  static clone(model: OrderCreateItem): OrderCreateItem {
    const clone = new OrderCreateItem(model.id);
    clone.amount = model.amount;
    return clone;
  }
}

export class OrderCreate {
  delivery_type: string;
  payment_type: string;
  note: string;
  billing_details: OrderCreateContactDetail;
  different_delivery_address: boolean;
  shipping_details: OrderCreateContactDetail;
  simulator_base: number;
  order_items: OrderCreateItem[];
  currency: string;

  constructor() {
    this.delivery_type = '';
    this.payment_type = '';
    this.note = '';
    this.billing_details = new OrderCreateContactDetail();
    this.different_delivery_address = false;
    this.shipping_details = new OrderCreateContactDetail();
    this.simulator_base = null;
    this.order_items = null;
    this.currency = "EUR";
  }

  static clone(model: OrderCreate): OrderCreate {
    const clone = new OrderCreate();
    clone.delivery_type = model.delivery_type;
    clone.payment_type = model.payment_type;
    clone.note = model.note;
    clone.simulator_base = model.simulator_base;
    clone.different_delivery_address = model.different_delivery_address;

    clone.order_items = model.order_items.map(item => OrderCreateItem.clone(item));
    clone.billing_details = OrderCreateContactDetail.clone(model.billing_details);
    clone.shipping_details = OrderCreateContactDetail.clone(model.shipping_details);
    return clone;
  }

  static getCleanedClone(model: OrderCreate) {
    let clone = OrderCreate.clone(model);
    clone = OrderCreate.cleanCompanyData(clone);
    if (!clone.different_delivery_address) {
      clone.shipping_details = undefined;
    }
    return clone;
  }

  private static cleanCompanyData(model: OrderCreate) {
    if (model.billing_details.customer.company.tax_id === '' && model.billing_details.customer.company.name === '') {
      model.billing_details.customer.company = undefined;
    }
    if (model.shipping_details.customer.company.tax_id === '' && model.shipping_details.customer.company.name === '') {
      model.shipping_details.customer.company = undefined;
    }
    return model;
  }
}
