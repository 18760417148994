import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-contact-showroom',
  templateUrl: './contact-showroom.component.html',
  styleUrls: ['./contact-showroom.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ContactShowroomComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('contact.showroom.title'),
    imgUrl: this.safeUrl('contact.showroom.img_url'),
    imgAlt: this.translate('contact.showroom.img_alt'),
    addressTitle: this.translate('contact.showroom.address_title'),
    addressLine1: this.translate('contact.showroom.address_line_1'),
    addressLine2: this.translate('contact.showroom.address_line_2'),
    addressLine3: this.translate('contact.showroom.address_line_3'),
    mail: this.translate('contact.showroom.mail'),
    hours1: this.translate('contact.showroom.hours_line_1'),
    hours2: this.translate('contact.showroom.hours_line_2')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
