import {ProductListItem} from '../../../race-sims/models/products/product-list-item.model';
import {DomSanitizer} from '@angular/platform-browser';

declare const $: any;


export class ConfigImage {

  private config: any;
  private selection: ProductListItem[];
  private selector: string;
  private $container: any;

  constructor(config: any, selection: ProductListItem[], $container: any, private sanitizer: DomSanitizer) {
    this.selection = selection;
    this.$container = $container;
    this.config = JSON.parse(config);
    this.render(selection);
  }

  render(selection: ProductListItem[]) {
    this.$container.empty();
    for (const type of this.config) {
      for (const item of selection) {
        if (item.type === type) {
          const extPos = item.image.lastIndexOf('.');
          let imageUrl = item.image.substr(0, extPos);
          imageUrl += '_conf.png';
          this.$container.append($('<div class="config-image-layer" style="background-image:url(' + imageUrl + ');"></div>\n'));
          break;
        }
      }
    }

  }
}
