import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../site-base';
import {DomSanitizer, Title, Meta} from '@angular/platform-browser';
import {AppDataProvider} from '../../common/app-data.provider';
import {ScriptHelper} from '../../shared/helpers/scripts.helper';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.less']
})
export class SupportComponent extends SiteBase implements OnInit, AfterViewInit {

  constructor(public meta: Meta, private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  this.titleService.setTitle(this.translate('meta.support.title'));
  this.meta.updateTag({ name: 'title', content:this.translate('meta.support.name')});
  this.meta.updateTag({ name: 'description', content: this.translate('meta.support.description') });

  }

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }

}
