import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user.model';
import {AppConfig} from '../../app.config';
import {IndexService} from '../shared/services/index.service';
import {Collection} from '../models/collection.model';
import {DetailService} from '../shared/services/detail.service';
import {OrderIndex} from '../models/orders/order-index.model';
import {Order} from '../models/order.model';
import {OrderDetail} from '../models/orders/order-detail.model';
import {OrderCreate} from '../models/orders/order-create.model';

@Injectable()
export class OrderService {

  private usersUrl = `${AppConfig.serverUrl}/api/v1/orders`;

  constructor(private http: HttpClient) {
  }

  post(order: OrderCreate): Observable<OrderDetail> {
    return this.http.post<OrderDetail>(`${this.usersUrl}`, order);
  }

  confirm(id: number): Observable<any> {
    return this.http.get<any>(`${this.usersUrl}/confirm/${id}`);
  }

}
