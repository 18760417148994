import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-support-landing',
  templateUrl: './support-landing.component.html',
  styleUrls: ['./support-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportLandingComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('support.landing.title'),
    text: this.translate('support.landing.text'),
    phone: this.translate('support.landing.phone'),
    phoneSafe: this.sanitizer.bypassSecurityTrustUrl('tel:' + this.translate('support.landing.phone')),
    mail: this.translate('support.landing.mail'),
    title1: this.translate('support.landing.title1'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
