import {LangHelper} from '../../race-sims/helpers/lang-helper';
import {AcademyLocation} from '../../race-sims/models/academy-location.model';
import {BookingTime} from '../../race-sims/models/booking-time.model';
import {AppDataProvider, KeyVal} from '../app-data.provider';
import {BookingSettingService} from './services/booking-setting.service';

export class BookingDataManager {


  private _settings: KeyVal[];

  constructor(locations: AcademyLocation[], settings: KeyVal[], private _bookingSettingService: BookingSettingService) {
    this._locations = locations;
    // console.log(locations);
    this._settings = settings;
    this._eventTypes = {
      en: this._bookingSettingService.getBookingTypes('en'),
      de: this._bookingSettingService.getBookingTypes('de')
    };
    // console.log(this._eventTypes);
    this.setupDiscounts();
  }

  private _eventTypes: {
    en: [{ key: string, value: string }],
    de: [{ key: string, value: string }]
  };

  get eventTypes(): [{ key: string, value: string }] {
    return this._eventTypes[LangHelper.getLang()];
  }

  private _locations: AcademyLocation[];

  get locations(): AcademyLocation[] {

    return this._locations;
  }

  set locations(value: AcademyLocation[]) {
    this._locations = value;
  }

  private _times: BookingTime[];

  get times(): BookingTime[] {
    return this._times;
  }

  set times(times: BookingTime[]) {
    this._times = times;
  }

  private _simulators = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  get simulators(): number[] {
    return this._simulators;
  }

  private _discounts: number[];

  get discounts(): number[] {
    return this._discounts;
  }

  set discounts(discounts: number[]) {
    this._discounts = discounts;
  }

  get bookingSettingService() {
    return this._bookingSettingService;
  }

  setNumberOfSimulators(num: number) {
    const sims = [];
    for (let i = 1; i <= num; i++) {
      sims.push(i);
    }
    this._simulators = sims;
  }

  getLocationById(id: number) {
    for (const location of this.locations) {
      if (location.id === id) {
        return location;
      }
    }
  }

  getSimQuantityDiscountPercent(amount: number) {
    return this._discounts[amount];
  }

  getSimQuantityDiscount(amount: number) {
    const percent = this.getSimQuantityDiscountPercent(amount);
    if (percent === 0) {
      return '';
    }
    return `(${percent}% OFF)`;
  }

  private setupDiscounts() {
    const maxDiscount = Number.parseInt(AppDataProvider.getValByKey(this._settings, 'booking_discount_percent_max'));
    const disc2 = Number.parseInt(AppDataProvider.getValByKey(this._settings, 'booking_discount_percent_at_2_simulators'));
    const disc3 = Number.parseInt(AppDataProvider.getValByKey(this._settings, 'booking_discount_percent_at_3_simulators'));
    const disc4 = Number.parseInt(AppDataProvider.getValByKey(this._settings, 'booking_discount_percent_at_4_simulators'));
    this._discounts = [0, 0, disc2, disc3, disc4, maxDiscount, maxDiscount, maxDiscount, maxDiscount, maxDiscount, maxDiscount];
  }

}
