import {CourseBookingDetail} from './course-booking-detail.model';

export class CourseBookingForm {

  id?: number;
  course_id: number;
  simulator_amount: number;
  price: number;
  customer: {
    id?: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: {
      id?: number,
      number: string,
      country_code: string
    }
  };

  constructor(courseId: number) {
    this.simulator_amount = 1;
    this.course_id = courseId;
    this.price = 0;
    this.customer = {
      first_name: '',
      last_name: '',
      email: '',
      phone: {
        number: '',
        country_code: ''
      }
    };
  }

  public static fromDetail(detail: CourseBookingDetail): CourseBookingForm {
    const model = new CourseBookingForm(detail.course_id);
    model.id = detail.id;
    model.course_id = detail.course_id;
    model.price = detail.price;
    model.simulator_amount = detail.simulator_amount;
    model.customer = {
      id: detail.customer.id,
      first_name: detail.customer.first_name,
      last_name: detail.customer.last_name,
      email: detail.customer.email,
      phone: {
        id: detail.customer.phone.id,
        number: detail.customer.phone.number,
        country_code: detail.customer.phone.country_code
      }
    };

    return model;

  }
}
