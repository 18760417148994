import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { SiteBase } from '../site-base';
import { AppDataProvider } from '../../common/app-data.provider';
import { ScriptHelper } from '../../shared/helpers/scripts.helper';

@Component({
  selector: 'app-what-makes-the-difference',
  templateUrl: './what-makes-the-difference.component.html',
  styleUrls: ['./what-makes-the-difference.component.less']
})
export class WhatMakesTheDifferenceComponent extends SiteBase implements OnInit, AfterViewInit {

  constructor(public meta: Meta, private titleService: Title, protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate('meta.what_makes_the_difference.title'));
    this.meta.updateTag({ name: 'title', content: this.translate('meta.what_makes_the_difference.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.what_makes_the_difference.description') });
  }

  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }
}
