import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-championship-landing',
  templateUrl: './championship-landing.component.html',
  styleUrls: ['./championship-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ChampionshipLandingComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('championship.landing.title'),
    text: this.translate('championship.landing.text'),
    bestTimes: this.translate('championship.landing.best_times'),
    drivers: this.translate('championship.drivers.title'),
    sponsors: this.translate('championship.sponsors.title'),
    informationTitle: this.translate('championship.information.title'),
    informationText: this.translate('championship.information.text'),
    informationCircuitTitle: this.translate('championship.information.circuit_title'),
    informationCircuitText: this.translate('championship.information.circuit_text'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();
  }
}
