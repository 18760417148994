import {ConfiguratorRepository} from './configurator.repository';
import {OrderCreate, OrderCreateItem} from '../../race-sims/models/orders/order-create.model';
import {ProductListItem} from '../../race-sims/models/products/product-list-item.model';
import {ExchangeRate} from '../../race-sims/models/products/exchange-rate.model';
import {ProductType} from './configurator.data-manager';
import {LangHelper} from '../../race-sims/helpers/lang-helper';
import {OrderService} from '../../race-sims/services/order.service';
import {OrderDetail} from '../../race-sims/models/orders/order-detail.model';
import {isNullOrUndefined} from 'util';

export class ConfiguratorDetailForm {
  name: string;
  surname: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  country: string;
  city: string;
  zip: string;
  street: string;
  streetNo: string;
  company: string;
  vat: string;

  constructor() {
    this.name = '';
    this.surname = '';
    this.email = '';
    this.phoneCode = '41';
    this.phoneNumber = '';
    this.country = '';
    this.city = '';
    this.zip = '';
    this.street = '';
    this.streetNo = '';
    this.company = '';
    this.vat = '';
  }
}

export class ConfiguratorFormManager {

  private radioTypes = [
    ProductType.Base,
    ProductType.Wheel,
    ProductType.Pedals,
    ProductType.Monitor,
    ProductType.Computer
  ];

  private optionalTypes = [
    ProductType.Monitor,
    ProductType.Computer,
    ProductType.License,
    ProductType.Support,
    ProductType.Software,
    ProductType.License,
    ProductType.Accessory,
    ProductType.AccessoryBelt,
    ProductType.AccessoryDashboard,
    ProductType.Motion,
    ProductType.Handbrake,
    ProductType.Shifter,
    ProductType.Wheel,
    ProductType.Pedals
  ];

  public deliveryTypes = [
    // {
    //   value: 'rfa_shipping',
    //   text: 'Shipping by RFA crew (EU only)',
    //   text_de: 'RFA Lieferwagen (nur EU)'
    // },
    // {
    //   value: 'standard_delivery',
    //   text: 'Shipping by delivery service',
    //   text_de: 'Standard Versand'
    // },
    // {
    //   value: 'personal',
    //   text: 'Personal collection',
    //   text_de: 'Selbstabholung'
    // },
    {
      value: 'shipping',
      prefix_text: 'Shipping (+/- ',
      prefix_text_de: 'Versand (+/- ',
      price: 1.10,
      postfix_text: '/km)',
      text: 'Shipping (+/- € 1.10/km)',
      text_de: 'Versand (+/- € 1.10/km)'
    },
    {
      value: 'shipping+training',
      prefix_text: 'Shipping + Training (+/- ',
      prefix_text_de: 'Versand + Einführung (+/- ',
      price: 1.80,
      postfix_text: '/km)',
      text: 'Shipping + Training (+/- € 1.80/km)',
      text_de: 'Versand + Einführung (+/- € 1.80/km)'
    },
  ];

  public paymentTypes = [
    {
      value: 'transfer',
      text: 'Bank transfer',
      text_de: 'Banküberweisung',
      disabled: false,
      disableCondition: () => false
    },
    // {
    //   value: 'credit',
    //   text: 'Credit card',
    //   disabled: false,
    //   disableCondition: () => false
    // },
    // {
    //   value: 'paypal',
    //   text: 'PayPal',
    //   disabled: false,
    //   disableCondition: () => false
    // },
    {
      value: 'cash',
      text: 'On personal collection',
      text_de: 'Barzahlung',
      disabled: false,
      disableCondition: () => this.isDeliveryNotSelected('personal')
    }
  ];

  private repository: ConfiguratorRepository;
  private selection: ProductListItem[];
  private price: number;
  private currency: string;
  public exchange_rates: ExchangeRate[];
  private showCurrencySelect: boolean;
  private configurationCode: string;
  public delivery: string;
  public payment: string;
  public billingForm: ConfiguratorDetailForm;
  public differentDelivery: boolean;
  public deliveryForm: ConfiguratorDetailForm;
  public order: OrderDetail = null;
  public step = 1;
  private completed = false;

  constructor() {
    this.repository = ConfiguratorRepository.getInstance();
    this.selection = [];
    this.billingForm = new ConfiguratorDetailForm();
    this.deliveryForm = new ConfiguratorDetailForm();
    this.differentDelivery = false;
    this.price = 0;
    this.showCurrencySelect = false;
    this.currency = "EUR";
    this.exchange_rates = [{name:"EUR", rate: 1.0}];
    this.configurationCode = '';
    this.setPayment(this.paymentTypes[0].value);
    this.setDelivery(this.deliveryTypes[0].value);
  }

  public getTranslatedDeliveryTypes() {
    let list = [];
    for (const item of this.deliveryTypes) {
      let text: string;
      let price = `${this.getCurrency()} ${(item.price * this.getExchangeRate()).toFixed(2)}`;
      if (LangHelper.getLang() === 'de') {
        text = `${item.prefix_text_de} ${price} ${item.postfix_text}`;
      } else {
        text = `${item.prefix_text} ${price} ${item.postfix_text}`;
      }
      list.push({value: item.value, text: text});
    }
    return list;
  }

  public getTranslatedPaymentTypes() {
    const list = [];
    for (const item of this.paymentTypes) {
      let text: string;
      if (LangHelper.getLang() === 'de') {
        text = item.text_de;
      } else {
        text = item.text;
      }
      list.push({value: item.value, text: text, disabled: item.disabled, disableCondition: item.disableCondition});
    }
    return list;
  }

  public isSelected(item: ProductListItem) {
    console.log(this.selection);
    for (const selectedItem of this.selection) {
      if (selectedItem.id === item.id) {
        return true;
      }
    }
    return false;
  }

  public isOptional(item: ProductListItem) {
    for (const type of this.optionalTypes) {
      if (item.type === type) {
        return true;
      }
    }
    return false;
  }

  /**
   * Toggles selection items with radio and checkbox checking.
   * @param {ProductListItem} item
   */
  public toggleSelection(item: ProductListItem) {
    if (this.isSelected(item) && !this.isRadioType(item.type)) {
      console.log("1");
      this.unselect(item);
    } else {
      if (this.isRadioType(item.type)) {
        console.log("2");
        if (this.isSelected(item) && this.isOptional(item)) {
          console.log("3");
                    // this.switchRadioSelection(item);
          this.unselect(item);
        } else if (!this.isSelected(item) && !this.hasTypeSelection(item.type)) {
          console.log("4");
          this.addSelection(item);
        } else if(!this.isSelected(item) && this.hasTypeSelection(item.type)) {
          console.log("5");
          this.switchRadioSelection(item);
        }
        else{
          console.log("7");
          this.unselect(item);
        }
      } else {
        console.log("6");
        this.addSelection(item);
      }
    }
  }

  public hasTypeSelection(type: ProductType) {
    for (const item of this.selection) {
      if (item.type === type) {
        return true;
      }
    }
    return false;
  }

  /**
   * Adds item to selection without checking
   * @param {ProductListItem} item
   */
  public addSelection(item: ProductListItem) {
    this.selection.push(item);
  }

  private switchRadioSelection(item: ProductListItem) {
    for (const index in this.selection) {
      if (this.selection[index].type === item.type) {
        this.selection[index] = item;
      }
    }
  }

  private unselectType(type: ProductType) {
    for (const index in this.selection) {
      const idx = Number.parseInt(index);
      if (this.selection[idx].type === type) {
        this.selection.splice(idx, 1);
      }
    }
  }

  public unselect(item: ProductListItem) {
    for (const index in this.selection) {
      if (this.selection[index].id === item.id) {
        this.selection.splice(Number.parseInt(index), 1);
      }
    }
  }

  public getRadioTypes() {
    return this.radioTypes;
  }

  public isRadioType(type: ProductType) {
    for (const radioType of this.radioTypes) {
      if (type === radioType) {
        return true;
      }
    }
    return false;
  }

  public calculatePrice() {
    let price = 0;
    for (const item of this.selection) {
      price += item.price;
    }
    this.price = price;
  }

  public getFormattedPrice() {
    return this.formatPrice(this.price * this.getExchangeRate());
  }

  public getCurrency() {
    return this.currency;
  }

  public getExchangeRate() {
    return this.exchange_rates.find(r => r.name == this.currency).rate
  }

  public onCurrencySelect(event) { 
    this.setCurrency(event.target.value)
  }

  public setCurrency(name) { 
    this.currency = name;    
  }

  getCurrences() {
    return this.exchange_rates.map(i => i.name);
  }

  public setExchangeRates(rates) {
    this.showCurrencySelect = rates.length > 0
    this.exchange_rates = rates;
  }
 
  public formatPrice(price) {
    return LangHelper.getLocalizedNumberFormatter(this.getCurrency()).format(price);
  }

  public getSelection() {
    return this.selection;
  }

  public isStored() {
    return this.repository.isStored('orderForm') && !this.repository.retrieve('orderForm').completed;
  }

  store() {
    const data = {
      selection: this.selection,
      price: this.price,
      configurationCode: this.configurationCode,
      delivery: this.delivery,
      payment: this.payment,
      billingForm: this.billingForm,
      differentDelivery: this.differentDelivery,
      deliveryForm: this.deliveryForm,
      order: this.order,
      step: this.step,
      completed: this.completed,
      currency: this.currency
    };
    this.repository.store(data, 'orderForm');
  }

  retrieve() {
    const data = this.repository.retrieve('orderForm');
    this.selection = data.selection;
    this.price = data.price;
    this.configurationCode = data.configurationCode;
    this.delivery = data.delivery;
    this.payment = data.payment;
    this.billingForm = data.billingForm;
    this.differentDelivery = data.differentDelivery;
    this.deliveryForm = data.deliveryForm;
    this.order = data.order;
    this.completed = data.completed;
    this.step = !isNullOrUndefined(data.step) ? data.step : 1;
    this.checkPaymentRules();
    this.currency = data.currency;
    console.log(this.currency, data.currency, data);
    
  }

  public calculateConfigurationCode() {
    let code = '';
    for (const base of this.selection) {
      if (base.type === ProductType.Base) {
        code += base.id + '-';
        for (const wheel of this.selection) {
          if (wheel.type === ProductType.Wheel) {
            code += wheel.id + '-';
            for (const pedals of this.selection) {
              if (pedals.type === ProductType.Pedals) {
                code += pedals.id;
                for (const monitor of this.selection) {
                  if (monitor.type === ProductType.Monitor) {
                    code += '-' + monitor.id;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.configurationCode = code;
  }

  public getConfigurationCode() {
    return this.configurationCode;
  }

  public isDeliverySelected(type: string) {
    return this.delivery === type;
  }

  public isPaymentSelected(type: string) {
    return this.payment === type;
  }

  public isDeliveryNotSelected(type: string) {
    return !this.isDeliverySelected(type);
  }

  public setDelivery(type: string) {
    this.delivery = type;
    this.checkPaymentRules();
  }

  private checkPaymentRules() {
    for (const item of this.paymentTypes) {
      item.disabled = item.disableCondition();
      if (item.disabled && this.payment === item.value) {
        this.payment = this.paymentTypes[0].value;
      }
    }
  }

  public setPayment(type: string) {
    this.payment = type;
  }

  public isValid(billingForm, deliveryForm, deliveryChecked) {
    return (billingForm.valid && (!deliveryChecked || deliveryForm.valid));
  }


  private dtoCreateOrderItems(dto: OrderCreate) {
    dto.order_items = [];
    for (const item of this.selection) {
      if (item.type === ProductType.Base) {
        dto.simulator_base = item.id;
      } else {
        const dtoItem = new OrderCreateItem(item.id);
        dto.order_items.push(dtoItem);
      }
    }
  }

  private dtoFillOrderDetail(dto: OrderCreate) {
    dto.billing_details.customer.first_name = this.billingForm.name;
    dto.billing_details.customer.last_name = this.billingForm.surname;
    dto.billing_details.customer.email = this.billingForm.email;
    dto.billing_details.customer.phone.country_code = this.billingForm.phoneCode;
    dto.billing_details.customer.phone.number = this.billingForm.phoneNumber;
    dto.billing_details.customer.address.country = this.billingForm.country;
    dto.billing_details.customer.address.city = this.billingForm.city;
    dto.billing_details.customer.address.street = this.billingForm.street;
    dto.billing_details.customer.address.street_number = this.billingForm.streetNo;
    dto.billing_details.customer.address.zip = this.billingForm.zip;

    if (this.billingForm.company !== '' && this.billingForm.vat !== '') {
      dto.billing_details.customer.company.name = this.billingForm.company;
      dto.billing_details.customer.company.tax_id = this.billingForm.vat;
    } else {
      dto.billing_details.customer.company = null;
    }

    if (this.differentDelivery) {
      dto.shipping_details.customer.first_name = this.deliveryForm.name;
      dto.shipping_details.customer.last_name = this.deliveryForm.surname;
      dto.shipping_details.customer.email = this.deliveryForm.email;
      dto.shipping_details.customer.phone.country_code = this.deliveryForm.phoneCode;
      dto.shipping_details.customer.phone.number = this.deliveryForm.phoneNumber;
      dto.shipping_details.customer.address.country = this.deliveryForm.country;
      dto.shipping_details.customer.address.city = this.deliveryForm.city;
      dto.shipping_details.customer.address.street = this.deliveryForm.street;
      dto.shipping_details.customer.address.street_number = this.deliveryForm.streetNo;
      dto.shipping_details.customer.address.zip = this.deliveryForm.zip;

      if (this.deliveryForm.company !== '' && this.deliveryForm.vat !== '') {
        dto.shipping_details.customer.company.name = this.deliveryForm.company;
        dto.shipping_details.customer.company.tax_id = this.deliveryForm.vat;
      } else {
        dto.shipping_details.customer.company = null;
      }
    }
  }

  public createDraft(service: OrderService, successCallback: () => void, errorCallback: (err) => void) {
    const dto = new OrderCreate();
    this.dtoCreateOrderItems(dto);
    this.dtoFillOrderDetail(dto);
    dto.different_delivery_address = this.differentDelivery;
    dto.delivery_type = this.delivery;
    dto.payment_type = this.payment;
    dto.currency = this.currency;

    service.post(dto).subscribe(res => this.afterDraftSuccess(res, successCallback), err => this.afterDraftError(err, errorCallback));
  }

  public isDraft() {
    return !isNullOrUndefined(this.order);
  }

  private afterDraftSuccess(res: OrderDetail, callback: () => void) {
    this.order = res;
    this.store();
    callback();
  }

  private afterDraftError(error: any, callback: (err) => void) {
    callback(error);
  }

  public getOrderPrice() {
    let price = 0;
    for (const item of this.order.order_items) {
      price += Number.parseInt(item.price);
    }
    return this.formatPrice(price * this.getExchangeRate());
  }

  public getDeliveryName(type: string) {
    for (const delivery of this.deliveryTypes) {
      if (delivery.value === type) {
        let price = `${this.getCurrency()} ${(delivery.price * this.getExchangeRate()).toFixed(2)}`;
        if (LangHelper.getLang() === 'de') {
          return `${delivery.prefix_text_de} ${price} ${delivery.postfix_text}`;
        } else {
          return `${delivery.prefix_text} ${price} ${delivery.postfix_text}`;
        }
        /* if (LangHelper.getLang() === 'de') {
          return delivery.text_de;
        } else {
          return delivery.text;
        } */
      }
    }
    return 'Undefined';
  }

  public getPaymentName(type: string) {
    for (const payment of this.paymentTypes) {
      if (payment.value === type) {
        if (LangHelper.getLang() === 'de') {
          return payment.text_de;
        } else {
          return payment.text;
        }
      }
    }
    return 'Undefined';
  }

  public confirmOrder(service: OrderService, callback: () => void) {
    service.confirm(this.order.id).subscribe(res => this.afterConfirmSuccess(res, callback), err => this.afterConfirmError(err));
  }

  private afterConfirmSuccess(res, callback: () => void) {
    this.completed = true;
    this.store();
    callback();
  }

  private afterConfirmError(err) {
  }
}
