import {LangHelper} from '../../../../race-sims/helpers/lang-helper';
import {BookingManagerProviderService} from '../booking-manager-provider.service';

export abstract class CommonMetaService<T extends any, U> {

  protected constructor(protected managerService: BookingManagerProviderService) {
    this.managerService = managerService;
  }

  abstract getMetaType(): string;

  abstract getChangeEventIdentifier(): string;

  getTranslatedAttr(attr: string) {
    const lang = LangHelper.getLang();
    const meta = this.getMetaSetting();
    return meta.translations[lang][attr];
  }

  getEventType(): string {
    return this.managerService.formManager.eventType;
  }

  getMetaSetting(): T {
    return this.managerService
      .dataManager
      .bookingSettingService
      .getMeta<T>(this.getEventType(), this.getMetaType());
  }

  getMetaValue(): U {
    return this.managerService.metaManager.getMeta(this.getMetaType());
  }

  setMetaValue(val: U, triggerChangeEvent = true) {
    const promise = this.managerService.metaManager.setMeta(this.getMetaType(), val)
    if (triggerChangeEvent) {
      promise.then(() => this.onChange());
    }
  }


  onChange() {
    // console.log('Dispatching: ' + this.getChangeEventIdentifier());

    const e = new Event(this.getChangeEventIdentifier());
    document.dispatchEvent(e);
  }
}
