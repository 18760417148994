import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';
import {AvailabilitySchedule} from '../models/availability-schedule.interface';
import {Observable} from 'rxjs/internal/Observable';
import {SimulatorBooking} from '../models/simulator-booking.model';
import {map} from 'rxjs/operators';
import {BookingCreate} from '../../../race-sims/models/booking-create.model';
import {BookingDetail} from '../../../race-sims/models/booking-detail.model';

@Injectable()
export class SimulatorBookingService {

  private baseUrl = AppConfig.serverUrl + '/api/v2/bookings';
  private basePublicUrl = AppConfig.serverUrl + '/api/v2/public/bookings';

  private getBaseurl() {
    if (AppConfig.requireAuth) {
      return this.baseUrl;
    }
    return this.basePublicUrl;
  }

  constructor(private http: HttpClient) {
  }

  getAvailabilitySchedule<T extends AvailabilitySchedule>(model: SimulatorBooking, T): Observable<T> {
    const t = new T();
    return this.http.post<T>(`${this.basePublicUrl}/${model.type}/availability-schedule`, model).pipe<T>(
      map(res => {
        t.availableList = res;
        return t;
      })
    );
  }

  getSimulatorStatus(date): Observable<any>{
    const payload = {
      date: date
    }
  var cUrl = AppConfig.serverUrl + '/api/v2/bookings/teambuilding/inactive-simulators'
  return this.http.post<any>(`${cUrl}`,payload)
}

  create(model: BookingCreate): Observable<any> {
    return this.http.post<any>(`${this.getBaseurl()}/${model.type}`, model);
  }


  addChampionship(data:any): Observable<any> {
    console.log(data);
    var cUrl = AppConfig.serverUrl + '/api/v2/championship_store';
    return this.http.post<any>(`${cUrl}`, data);
  }

  addAcademy(data:any): Observable<any> {
    console.log(data);
    var cUrl = AppConfig.serverUrl + '/api/v2/bookings/teambuilding';
    return this.http.post<any>(`${cUrl}`, data);
  }


  getTimeSlots(data:any): Observable<any> {
    console.log(data);
    var cUrl = AppConfig.serverUrl + '/api/v2/get_timeslots';
    return this.http.post<any>(`${cUrl}`, data);
  }

  getTimeSlotsBooking(data:any): Observable<any> {
    console.log(data);
    var cUrl = AppConfig.serverUrl + '/api/v2/get_timeslots_booking';
    return this.http.post<any>(`${cUrl}`, data);
  }

  getMemberData(data:any): Observable<any> {
    console.log(data);
    var cUrl = AppConfig.serverUrl + '/api/v2/get_member_data';
    return this.http.post<any>(`${cUrl}`, data);
  }


  getTimeSlotsForIS(data:any): Observable<any> {
    console.log(data);
    var cUrl = AppConfig.serverUrl + '/api/v2/get_timeslots_for_is';
    return this.http.post<any>(`${cUrl}`, data);
  }

  update(model: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${model.type}`, model);
  }

  get(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}`);
  }

  getSession(): Observable<any> {
    var cUrl = AppConfig.serverUrl + '/api/v2/sync_member_data';
    return this.http.get<any>(`${cUrl}`);
  }

  setStatus(id: number, status: string) {
    return this.http.get<BookingDetail>(`${this.baseUrl}/set-status/${id}/${status}`);
  }

  destroy(id: number) {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
