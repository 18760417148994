import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-championship-drivers-results',
  templateUrl: './championship-drivers-results.component.html',
  styleUrls: ['./championship-drivers-results.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ChampionshipDriversResultsComponent extends SiteBase implements OnInit {



  t = {
    title: this.translate('championship.drivers.title'),
    bestTitle: this.translate('championship.drivers.best_title'),
    bestName: this.translate('championship.drivers.best_name'),
    bestCountry: this.translate('championship.drivers.best_country'),
    bestTime: this.translate('championship.drivers.best_time'),
    showFullList: this.translate('championship.drivers.show_full_list')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }


}
