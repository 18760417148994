import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-support-simulators',
  templateUrl: './support-simulators.component.html',
  styleUrls: ['./support-simulators.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportSimulatorsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('support.simulators.title'),
    text_1: this.translate('support.simulators.text_1'),
    url_1: this.translate('support.simulators.url_1'),
    alt_1: this.translate('support.simulators.alt_1'),
    text_2: this.translate('support.simulators.text_2'),
    url_2: this.translate('support.simulators.url_2'),
    alt_2: this.translate('support.simulators.alt_2'),
    text_3: this.translate('support.simulators.text_3'),
    url_3: this.translate('support.simulators.url_3'),
    alt_3: this.translate('support.simulators.alt_3'),
    text_4: this.translate('support.simulators.text_4'),
    url_4: this.translate('support.simulators.url_4'),
    alt_4: this.translate('support.simulators.alt_4'),
    title1: this.translate('support.simulators.title1'),
    text_5: this.translate('support.simulators.text_5'),
    url_5: this.translate('support.simulators.url_5'),
    alt_5: this.translate('support.simulators.alt_5'),
    text_6: this.translate('support.simulators.text_6'),
    url_6: this.translate('support.simulators.url_6'),
    alt_6: this.translate('support.simulators.alt_6'),
    text_7: this.translate('support.simulators.text_7'),
    url_7: this.translate('support.simulators.url_7'),
    alt_7: this.translate('support.simulators.alt_7'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}

