import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ProductService } from "../../race-sims/services/product.service";
import { Collection } from "../../race-sims/models/collection.model";
import { ProductListItem } from "../../race-sims/models/products/product-list-item.model";
import { ConfiguratorDataManager } from "./configurator.data-manager";
import { ActivatedRoute } from "@angular/router";
import { ConfiguratorFormManager } from "./configurator.form-manager";
import { ScriptHelper } from "../../shared/helpers/scripts.helper";
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { SiteBase } from "../site-base";
import { ConfigImage } from "./config-image/config-image.model";
import { ConfigImageComponent } from "./config-image/config-image.component";
import { AppDataProvider } from "../../common/app-data.provider";
import { ExchangeRate } from "../../race-sims/models/products/exchange-rate.model";

declare const $: any;

@Component({
  selector: "app-configurator",
  templateUrl: "./configurator.component.html",
  styleUrls: ["./configurator.component.less"],
})
export class ConfiguratorComponent extends SiteBase
  implements OnInit, AfterViewInit {
  public dataManager: ConfiguratorDataManager;
  imgUrl = "";
  configImgSelector = "#config-image";
  configImage: ConfigImage;

  @ViewChild(ConfigImageComponent) configImageComponent: ConfigImageComponent;

  constructor(
    private titleService: Title,
    private service: ProductService,
    private route: ActivatedRoute,
    public formManager: ConfiguratorFormManager,
    protected provider: AppDataProvider,
    public sanitizer: DomSanitizer,
    public meta: Meta
  ) {
    super(provider, sanitizer);
    this.dataManager = ConfiguratorDataManager.getInstance();
  }

  ngOnInit() {
    this.titleService.setTitle(
      this.translate('meta.configurator.title'),
    );
    this.meta.updateTag({ name: 'title', content: this.translate('meta.configurator.name')});
    this.meta.updateTag({ name: 'description', content: this.translate('meta.configurator.description') });

    //this.formManager.setExchangeRates(this.service.getExchangeRates());
    this.service.getExchangeRates().subscribe(
      data => this.formManager.setExchangeRates(<Collection<ExchangeRate>>data)
    );

    this.route.data.subscribe(
      (data: { collection: Collection<ProductListItem> }) =>
        this.onDataReceived(data)
    );

  }

  ngAfterViewInit() {
    ScriptHelper.initScrollMenu();
  }

  onDataReceived(data) {
    console.log("data--", data);
    this.dataManager.setItems(data.collection);

    if (this.formManager.isStored()) {
      this.loadFromStorage();
      this.imgUrl = this.dataManager.getConfigurationImageUrl(
        this.formManager.getConfigurationCode()
      );
    } else {
      this.loadNew();
      this.imgUrl = this.dataManager.getConfigurationImageUrl(
        this.formManager.getConfigurationCode()
      );
    }
  }

  private loadFromStorage() {
    this.formManager.retrieve();
  }

  private loadNew() {
    this.formManager.addSelection(this.dataManager.getItems().bases[0]);

    // Commented for Task 15
    // this.formManager.addSelection(this.dataManager.getItems().wheels[0]);
    // this.formManager.addSelection(this.dataManager.getItems().pedals[0]);

    this.formManager.calculatePrice();
    this.formManager.calculateConfigurationCode();
  }

  changeImgUrl(url: string) {
    // this.imgUrl = url;
    this.configImageComponent.reload(this.formManager.getSelection());
  }

  goToNextStep(value: boolean) {
    const link = $("#sec-configurator-top-link");
    link.click();
    this.formManager.step++;
    // skip step 3
    if (this.formManager.step == 3) this.formManager.step++;
    this.formManager.store();
  }

  goToPrevStep(value: boolean) {
    const link = $("#sec-configurator-top-link");
    link.click();
    this.formManager.step--;
    // skip step 3
    if (this.formManager.step == 3) this.formManager.step--;
    this.formManager.store();
  }
}
