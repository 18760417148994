import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {Router} from '@angular/router';
import {AppDataProvider} from '../../../common/app-data.provider';
import {LangHelper} from '../../../race-sims/helpers/lang-helper';

@Component({
  selector: 'app-products-items',
  templateUrl: './products-items.component.html',
  styleUrls: ['./products-items.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsItemsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('products.events.title'),
    showdetails: this.translate('general.variables.btn_more_info'),
  };

  routerLinks = {
    0: LangHelper.getLang() === 'en' ? 'https://drive.google.com/file/d/1f9ztaI2ZGEMJu-mjA-mguqEAIh5KEYuA/view?usp=sharing' : 'https://drive.google.com/file/d/1NP9ON0ftAvECsRQ-HRAUoAWCqDvm32NX/view?usp=sharing',
    1: '/stage1-pro',
    2: '/stage3-pro',
    3: '/science-and-education',
  };

  shouldOpenExternal = {
    0: true,
    1: false,
    2: false,
    3: false,
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private router: Router) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }


}
