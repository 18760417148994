import {Component, OnInit} from '@angular/core';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {CustomStickersMetaService} from '../../../../common/simulator-booking/services/meta/custom-stickers-meta.service';
import {CustomStickerMetaValue} from '../../../../common/simulator-booking/models/meta/values/custom-sticker-meta.value';

@Component({
  selector: 'app-custom-stickers-meta',
  templateUrl: './custom-stickers-meta.component.html',
  styleUrls: ['./custom-stickers-meta.component.less']
})
export class CustomStickersMetaComponent implements OnInit {

  t = {
    label: 'label',
    description: 'description'
  };

  private service: CustomStickersMetaService;
  isChecked: boolean;

  constructor(private managerService: BookingManagerProviderService) {
    this.service = new CustomStickersMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.isChecked = this.service.getMetaSetting().is_checked;
    this.initTranslations();
  }

  initTranslations(){
    this.t.label = this.service.getLabel();
    this.t.description = this.service.getDescription();
  }
  ngOnInit() {
  }

  onCustomStickersChange(value: boolean) {
    this.service.setMetaValue(new CustomStickerMetaValue(value));
  }
}
