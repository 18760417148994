import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {InDayHourlyBookingMetaValue} from '../../models/meta/values/in-day-hourly-booking-meta.value';
import {InDayHourlyBookingAvailabilityScheduleItem} from '../../models/in-day-hourly-booking-availability-schedule.model';
import {CountMetaInterface} from './contracts/count-meta.interface';
import {isNullOrUndefined} from 'util';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';
import {LocationMetaValue} from '../../models/meta/values/location-meta-value.model';

interface InDayHourlyBookingMetaContract {
  key: string;
  length: number;
  translations: {
    en: {
      date_label: string,
      time_label: string,
      selection_info: string
    },
    de: {
      date_label: string,
      time_label: string,
      selection_info: string
    }
  };
}

export class InDayHourlyBookingMetaService extends CommonMetaService<InDayHourlyBookingMetaContract, InDayHourlyBookingMetaValue>
  implements CountMetaInterface, ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.in_day_hourly_booking;
  static changeEventIdentifier = 'meta.' + InDayHourlyBookingMetaService.metaType + '.change';

  id: number = undefined;

  constructor(protected managerService: BookingManagerProviderService, id?) {
    super(managerService);
    if (id) {
      this.id = id;
    }
    this.managerService.metaManager.registerService(this, this.getMetaType());

    const val = this.getMetaValue() !== undefined ? this.getMetaValue() : new InDayHourlyBookingMetaValue();
    this.setMetaValue(val, false);
  }

  getMetaType(): string {
    return InDayHourlyBookingMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return InDayHourlyBookingMetaService.changeEventIdentifier;
  }

  getDateLabel() {
    return this.getTranslatedAttr('date_label');
  }

  getTimeLabel() {
    return this.getTranslatedAttr('time_label');
  }

  getSelectionInfo() {
    return this.getTranslatedAttr('selection_info');
  }

  toggleTimeSelection(time: InDayHourlyBookingAvailabilityScheduleItem) {
    // console.log('SELECTION TOGGLED');
    const meta = this.getMetaValue();
    if (meta.times.includes(time.hour)) {
      meta.times.splice(meta.times.indexOf(time.hour), 1);
    } else {
      meta.times.push(time.hour);
    }
    this.setMetaValue(meta);
    return meta;
  }

  clearTimes() {
    // console.log('CLEARED TIMES');
    const meta = this.getMetaValue();
    meta.times = [];
    this.setMetaValue(meta);
  }

  getCount() {
    const metaValue = this.getMetaValue();
    if (isNullOrUndefined(metaValue.times)) {
      return 0;
    }
    return this.getMetaValue().times.length;
  }

  isValid() {
    const metaValue = this.getMetaValue();
    return metaValue.date !== undefined && metaValue.times !== undefined && metaValue.times.length > 0;
  }
}
