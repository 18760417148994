import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IMetaComponent} from '../../../../common/simulator-booking/IMetaComponent';
import {BookingDefinitions} from '../../../../common/simulator-booking/booking-definitions';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {RaceTrackMetaService} from '../../../../common/simulator-booking/services/meta/race-track-meta.service';
import {RaceTrackMetaValue} from '../../../../common/simulator-booking/models/meta/values/race-track-meta-value.model';
import {AppDataProvider} from '../../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-race-track-meta',
  templateUrl: './race-track-meta.component.html',
  styleUrls: ['./race-track-meta.component.less']
})
export class RaceTrackMetaComponent implements OnInit, AfterViewInit, IMetaComponent {

  get metaType() {
    return BookingDefinitions.metaTypes.race_track;
  }

  t = {
    label: 'label'
  };

  selectIdentifier = 'app-race-track-meta-select';

  private service: RaceTrackMetaService;

  $tracks: any;
  tracks: string[] = ['t1', 't2'];

  constructor(private managerService: BookingManagerProviderService, private appDataProvider: AppDataProvider) {
    this.service = new RaceTrackMetaService(this.managerService, this.appDataProvider);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.t.label = this.service.getLabel();
    this.tracks = this.service.getTracks();
    this.initTranslations();
  }

  initTranslations(){
    this.t.label = this.service.getLabel();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const that = this;
    this.$tracks = $(`#${this.selectIdentifier}`).selectpicker({liveSearch: true, size: 10});
    this.$tracks.on('changed.bs.select', function (e, clickedIndex) {
      that.onChange(clickedIndex);
    });
  }

  onChange(idx: number) {
    this.service.setMetaValue(new RaceTrackMetaValue(this.tracks[idx]));
  }
}
