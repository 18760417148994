import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class LanguageService{

  private language = new Subject<string>();

  constructor() {
  }

  languageChanged$ = this.language.asObservable();

  changeLanguage(language: string) {
    this.language.next(language);
  }
}
