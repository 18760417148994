import { Component, OnInit } from '@angular/core';
import {AppDataProvider} from "../../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";
import {SiteBase} from "../../site-base";

@Component({
  selector: 'app-wmtd-components',
  templateUrl: './wmtd-components.component.html',
  styleUrls: ['./wmtd-components.component.less']
})
export class WmtdComponentsComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
