import { Component, OnInit } from '@angular/core';
import {SiteBase} from "../../site-base";
import {AppDataProvider} from "../../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-sae-next-step',
  templateUrl: './sae-next-step.component.html',
  styleUrls: ['./sae-next-step.component.less']
})
export class SaeNextStepComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

  getMail(i) {
    const mailto = 'mailto:' + this.translate('contact.contacts.mail_' + i);
    return this.sanitizer.bypassSecurityTrustUrl(mailto);
  }

}
