import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AppDataProvider } from "../../../common/app-data.provider";

@Component({
  selector: "app-home-race-centers",
  templateUrl: "./home-race-centers.component.html",
  styleUrls: ["./home-race-centers.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeRaceCentersComponent implements OnInit {
  constructor(private provider: AppDataProvider) {}

  translate(key: string): string {
    return this.provider.getTranslation(key);
  }
  ngOnInit() {}
}
