import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-academy-map',
  templateUrl: './academy-map.component.html',
  styleUrls: ['./academy-map.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AcademyMapComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('academy.map.title'),
    acad1Title: this.translate('academy.map.academy_1_title'),
    acad1line1: this.translate('academy.map.academy_1_address_line_1'),
    acad1line2: this.translate('academy.map.academy_1_address_line_2'),
    acad1line3: this.translate('academy.map.academy_1_address_line_3'),
    acad1phone: this.translate('academy.map.academy_1_phone'),
    acad1phoneSafe: this.sanitizer.bypassSecurityTrustUrl('tel:' + this.translate('academy.map.academy_1_phone')),
    acad1mail: this.translate('academy.map.academy_1_mail'),
    acad1mailSafe: this.sanitizer.bypassSecurityTrustUrl('mailto: ' + this.translate('academy.map.academy_1_mail')),
    acad1hours1: this.translate('academy.map.academy_1_hours_line_1'),
    acad1hours2: this.translate('academy.map.academy_1_hours_line_2'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $('.scrollbar-inner').scrollbar();
    });
  }
}
