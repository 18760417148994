import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from './race-sims/services/auth.service';
import {AppConfig} from './app.config';
import {ScriptHelper} from './shared/helpers/scripts.helper';

declare const App: any;
declare const $: any;
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, AfterViewInit {



  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }

    });
  }


  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit(): void {
    // Not working - DOM removes the pillspy block and then breaks other pages too
    // ScriptHelper.initScrollMenu();
    ScriptHelper.scrollToId();
  }

}
