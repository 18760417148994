import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {CourseDetail} from '../../common/courses/course-detail.model';
import {CustomerService} from '../../race-sims/services/customer.service';
import {CourseService} from '../../common/course-bookings/services/course.service';


@Injectable()
export class CourseResolver implements Resolve<CourseDetail> {
  constructor(private service: CourseService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CourseDetail> {
    this.service.usePublicApi();
    const id = Number(route.paramMap.get('courseId'));
    return this.service.get(id);
  }

}
