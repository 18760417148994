import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {
  CateringMetaContract,
  CateringMetaService
} from '../../../../common/simulator-booking/services/meta/catering-meta.service';
import {CateringMetaValue} from '../../../../common/simulator-booking/models/meta/values/catering-meta-value.model';

@Component({
  selector: 'app-catering-meta',
  templateUrl: './catering-meta.component.html',
  styleUrls: ['./catering-meta.component.less']
})
export class CateringMetaComponent implements OnInit, AfterViewInit {

  t = {
    label: 'label',
    description: 'description'
  };
  service: CateringMetaService;
  metaSetting: CateringMetaContract;
  metaValue: CateringMetaValue;

  isChecked: boolean;
  constructor(private managerService: BookingManagerProviderService) {
    this.service = new CateringMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.metaSetting = this.service.getMetaSetting();
    this.metaValue = new CateringMetaValue(this.metaSetting.is_checked, this.metaSetting.price);
    this.isChecked = this.metaSetting.is_checked;

  }

  ngOnInit() {
    this.setTranslations();
  }

  setTranslations() {
    this.t.label = this.service.getLabel();
    this.t.description = this.service.getDescription();
  }

  ngAfterViewInit() {

  }

  onCoachChange(value: boolean) {
    // console.log(value);
    this.service.setMetaValue(new CateringMetaValue(value, this.service.getPrice()));
  }
}
