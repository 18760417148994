declare const $: any;

export class ScriptHelper {

  static initScrollMenu() {
    const headerHeight = $('#pillspy').height();
    const navHeight = $('.navbar').outerHeight();

    $(function () {
      $(document).off('click', '#pillspy a:not(a[data-parent*="#"]), .force-hash-link')
        .on('click', '#pillspy a:not(a[data-parent*="#"]), .force-hash-link', function (e) {
          // console.log('hash link');
        e.preventDefault();
        // TODO: MAY INTERFERE WITH OTHER LINKS
        // if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
          let target = $(this.hash);
          const offset = 92;
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            $('html,body').animate({
              scrollTop: target.offset().top - headerHeight - navHeight + 7
            }, 1000);
            return false;
          }
        // }
      });
    });

    $(document).ready(function () {
      const pillSpy = $('#pillspy');
      const pillSpyPosition = pillSpy.position();
      const isPill = pillSpy.get().length > 0;
      if (isPill) {
        pillSpy.get()[0].style.top = navHeight + 'px';
      }

      if (isPill) {
        $(window).scroll(function () {
          const windowPosition = $(window).scrollTop();
          if (windowPosition >= pillSpyPosition.top - navHeight) {
            pillSpy.addClass('stick');
          } else {
            pillSpy.removeClass('stick');
          }
        });
      }
    });

    $('#blinder').height(headerHeight);
    $('#navbar-blinder').height(navHeight);
    $('body').scrollspy({ target: '#pillspy', offset: headerHeight + navHeight - 5 });
  }
  static scrollToId() {
    if (window.location.hash) {
      $(document).ready(() => {
        const anchorId = window.location.hash.split('#')[1];
        const element = document.getElementById(anchorId);
        const offset = $('#pillspy').height() + $('.navbar').outerHeight() - 5;
        const verticalPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({top: verticalPosition, behavior: 'smooth'});
      });
    }
  }
}
