import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-about-us-landing',
  templateUrl: './about-us-landing.component.html',
  styleUrls: ['./about-us-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsLandingComponent extends SiteBase implements OnInit, AfterViewInit {

  // t = {
  //   title: this.translate('products.landing.title'),
  //   text: this.translate('products.landing.text'),
  //   showOptions: this.translate('general.variables.btn_show_options')
  // };
   t = {
    title: this.translate('about_us.landing.title'),
    text: this.translate('about_us.landing.text'),
    showHistory: this.translate('general.variables.btn_show_history'),
    about_us: this.translate('about_us.about_us.title'),
    history: this.translate('about_us.history.title'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
   
  }

}
