import {ChangeDetectionStrategy, Component, DoCheck, OnChanges, OnInit} from '@angular/core';
import {SiteBase} from '../../../site/site-base';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class FooterComponent extends SiteBase implements OnInit {

  mailto: any;
  phone: SafeUrl;


  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);

  }

  ngOnInit() {
    this.phone = this.sanitizer.bypassSecurityTrustUrl('tel:' + this.translate('general.footer.contact_phone'));
    this.mailto = this.sanitizer.bypassSecurityTrustUrl('mailto:' + this.translate('general.footer.contact_mail'));

  }

}
