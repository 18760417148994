import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BookingDefinitions} from '../../../../common/simulator-booking/booking-definitions';
import {IMetaComponent} from '../../../../common/simulator-booking/IMetaComponent';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {AmountMetaService} from '../../../../common/simulator-booking/services/meta/amount-meta.service';
import {AmountMetaValue} from '../../../../common/simulator-booking/models/meta/values/amount-meta-value.model';
import {BookingSettingService} from '../../../../common/simulator-booking/services/booking-setting.service';
import {BookingRelations} from '../../../../common/simulator-booking/booking-relations';

declare const $: any;

@Component({
  selector: 'app-amount-meta',
  templateUrl: './amount-meta.component.html',
  styleUrls: ['./amount-meta.component.less']
})
export class AmountMetaComponent implements OnInit, OnDestroy, AfterViewInit, IMetaComponent {

  $amount: any;
  selectIdentifier = 'app-amount-meta-select';
  amountTriggerEventName = 'booking-amount-meta.amount.trigger';
  amounts: number[];
  t = {
    label: 'label'
  };
  private service: AmountMetaService;
  private eventListeners: any[] = [];

  constructor(private managerService: BookingManagerProviderService, private bookingSettingService: BookingSettingService) {
    this.service = new AmountMetaService(this.managerService, this.bookingSettingService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
  }

  get metaType() {
    return BookingDefinitions.metaTypes.amount;
  }

  ngOnInit() {
    this.t.label = this.service.getLabel();
    this.initAmounts();
    const listener = () => this.reloadSelect();
    this.eventListeners.push({name: this.amountTriggerEventName, listener: listener});
    document.addEventListener(this.amountTriggerEventName, listener);
    this.setLocationChangeListeners();
  }

  initAmounts() {
    this.amounts = this.service.getAmounts();
    this.onAmountChange(0);
    document.dispatchEvent(new Event(this.amountTriggerEventName));
  }

  reloadSelect() {
    setTimeout(() => {
      // this.$amount.selectpicker('destroy');
      this.$amount.selectpicker('refresh');
      this.$amount.selectpicker('val', this.amounts[0]);
      // this.bindSelectAmountChange();
    }, 150); // TODO find a better solution

  }

  ngOnDestroy() {
    this.unsetEventListeners();
  }

  unsetEventListeners() {
    for (const item of this.eventListeners) {
      document.removeEventListener(item.name, item.listener);
    }
  }

  setEventListenersForEventNames(eventNames: string[], callback: (event: Event) => void) {
    for (const eventName of eventNames) {
      const listener = (event: Event) => callback(event);
      this.eventListeners.push({name: eventName, listener: listener});
      document.addEventListener(eventName, listener);
    }
  }

  setLocationChangeListeners() {
    this.setEventListenersForEventNames(BookingRelations.getLocationMetaEventNames(), this.onLocationChange.bind(this));
  }

  onLocationChange(event: Event) {
    // console.log('Catching: ' + event.type);
    this.initAmounts();
  }

  ngAfterViewInit(): void {
    this.$amount = $(`#${this.selectIdentifier}`).selectpicker();
    this.bindSelectAmountChange();
  }

  bindSelectAmountChange() {
    const that = this;
    this.$amount.on('changed.bs.select', function (e, clickedIndex) {
      that.onAmountChange(clickedIndex);
    });
  }

  onAmountChange(clickedIndex: number) {
    const val = new AmountMetaValue(this.amounts[clickedIndex]);
    this.service.setMetaValue(val);
  }
}
