import {AmountMetaService} from './services/meta/amount-meta.service';
import {LocationMetaService} from './services/meta/location-meta.service';

export class BookingRelations {

  static getAmountMetaEventNames() {
    return [
      AmountMetaService.changeEventIdentifier
    ];
  }

  static getLocationMetaEventNames() {
    return [
      LocationMetaService.changeEventIdentifier
    ];
  }
}
