import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Collection} from '../../race-sims/models/collection.model';
import {ProductListItem} from '../../race-sims/models/products/product-list-item.model';
import {ProductService} from '../../race-sims/services/product.service';


@Injectable()
export class ConfiguratorResolver implements Resolve<Collection<ProductListItem>> {

  constructor(private service: ProductService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Collection<ProductListItem>> {
    return this.service.all();
  }

}
