import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppDataProvider} from "../../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";
import {SiteBase} from "../../site-base";
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';

@Component({
  selector: 'app-wmtd-landing',
  templateUrl: './wmtd-landing.component.html',
  styleUrls: ['./wmtd-landing.component.less']
})
export class WmtdLandingComponent extends SiteBase implements OnInit, AfterViewInit {

  public YT: any;
  public video: any;
  public player: any;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  init() {
    /*let tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.id = 'yt-iframe-api-script';

    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);*/

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.id = 'iframe-api-script';

    const tag2 = document.createElement('script');
    tag2.src = 'https://www.youtube.com/s/player/7acefd5d/www-widgetapi.vflset/www-widgetapi.js';
    tag2.id = 'www-widgetapi-script';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag2, firstScriptTag);
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }

  ngOnInit() {
  }

  stopVideo() {
    this.player.stopVideo();
  }

  onPlayerStateChange(event) {
    /*switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          console.log('started ' + this.cleanTime());
        } else {
          console.log('playing ' + this.cleanTime())
        }
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log('paused' + ' @ ' + this.cleanTime());
        }
        break;
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        break;
    }*/
  };

  //utility
  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  };

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  };


  ngAfterViewInit(): void {
    ScriptHelper.initScrollMenu();

    this.init();
    this.video = 'jxFjHwsTt5k' //video id

    window['onYouTubeIframeAPIReady'] = (e) => {
      this.YT = window['YT'];
      this.player = new window['YT'].Player('player', {
        videoId: this.video,
        events: {
          'onStateChange': this.onPlayerStateChange.bind(this),
          'onError': this.onPlayerError.bind(this),
          'onReady': (e) => {
            e.target.playVideo();
          }
        }
      });
    };
  }

  ngOnDestroy() {
    const script1 = document.getElementById('www-widgetapi-script');
    if (script1) {
      script1.remove();
    }
    const script2 = document.getElementById('yt-iframe-api-script');
    if (script2) {
      script2.remove();
    }
  }
}
