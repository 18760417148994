import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-general-videos',
  templateUrl: './general-videos.component.html',
  styleUrls: ['./general-videos.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class GeneralVideosComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('general.videos.title'),
    vid1Title: this.translate('general.videos.video_1_title'),
    vid1Text: this.translate('general.videos.video_1_text'),
    vid1Link: this.safeResourceUrl('general.videos.video_1_link'),
    vid2Title: this.translate('general.videos.video_2_title'),
    vid2Text: this.translate('general.videos.video_2_text'),
    vid2Link: this.safeResourceUrl('general.videos.video_2_link'),
    vid3Title: this.translate('general.videos.video_3_title'),
    vid3Text: this.translate('general.videos.video_3_text'),
    vid3Link: this.safeResourceUrl('general.videos.video_3_link')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
