import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-simulator-photos',
  templateUrl: './simulator-photos.component.html',
  styleUrls: ['./simulator-photos.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimulatorPhotosComponent extends SiteBase implements OnInit {

  t = {
    showAll: this.translate('simulator.photos.show_all'),
    photos: this.translate('simulator.photos.title')
  };

  photoAmount;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
    this.photoAmount = Number.parseInt(this.provider.getSetting('academy_displayed_photos_count'));
  }

}
