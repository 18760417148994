import { Component, OnInit } from '@angular/core';
import {SiteBase} from "../site-base";
import {AppDataProvider} from "../../common/app-data.provider";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-rent-your-sim',
  templateUrl: './rent-your-sim.component.html',
  styleUrls: ['./rent-your-sim.component.less']
})
export class RentYourSimComponent extends SiteBase implements OnInit {

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
