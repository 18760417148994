import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-about-us-history',
  templateUrl: './about-us-history.component.html',
  styleUrls: ['./about-us-history.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsHistoryComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('about_us.history.title'),
    title_1: this.translate('about_us.history.title_1'),
    text_1: this.translate('about_us.history.text_1'),
    title_2: this.translate('about_us.history.title_2'),
    text_2: this.translate('about_us.history.text_2'),
    title_3: this.translate('about_us.history.title_3'),
    text_3: this.translate('about_us.history.text_3'),
    title_4: this.translate('about_us.history.title_4'),
    text_4: this.translate('about_us.history.text_4'),
    title_5: this.translate('about_us.history.title_5'),
    text_5: this.translate('about_us.history.text_5'),
    title_6: this.translate('about_us.history.title_6'),
    text_6: this.translate('about_us.history.text_6'),
    title_7: this.translate('about_us.history.title_7'),
    text_7: this.translate('about_us.history.text_7'),
    title_8: this.translate('about_us.history.title_8'),
    text_8: this.translate('about_us.history.text_8'),
    title_9: this.translate('about_us.history.title_9'),
    text_9: this.translate('about_us.history.text_9'),
    title_10: this.translate('about_us.history.title_10'),
    text_10: this.translate('about_us.history.text_10'),
    title_11: this.translate('about_us.history.title_11'),
    text_11: this.translate('about_us.history.text_11'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }

}
