import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IMetaComponent} from '../../../../common/simulator-booking/IMetaComponent';
import {BookingDefinitions} from '../../../../common/simulator-booking/booking-definitions';
import {AcademyLocation} from '../../../../race-sims/models/academy-location.model';
import {LocationMetaService} from '../../../../common/simulator-booking/services/meta/location-meta.service';
import {BookingManagerProviderService} from '../../../../common/simulator-booking/services/booking-manager-provider.service';
import {LocationMetaValue} from '../../../../common/simulator-booking/models/meta/values/location-meta-value.model';

declare const $: any;

@Component({
  selector: 'app-location-meta',
  templateUrl: './location-meta.component.html',
  styleUrls: ['./location-meta.component.less']
})
export class LocationMetaComponent implements OnInit, AfterViewInit, IMetaComponent {

  $academy: any;
  selectIdentifier = 'app-location-meta-select';
  t = {
    label: 'label'
  };
  locations: AcademyLocation[];
  service: LocationMetaService;

  constructor(private managerService: BookingManagerProviderService) {
    this.service = new LocationMetaService(this.managerService);
    this.managerService.metaManager.registerService(this.service, this.service.getMetaType());
    this.initTranslations();
  }

  initTranslations(){
    this.t.label = this.service.getLabel();
  }

  get metaType() {
    return BookingDefinitions.metaTypes.location;
  }

  ngOnInit() {
    this.t.label = this.service.getLabel();
    this.locations = this.service.getLocations();
  }

  ngAfterViewInit() {
    const that = this;
    this.$academy = $(`#${this.selectIdentifier}`).selectpicker();
    this.$academy.on('changed.bs.select', function (e, clickedIndex) {
      that.onLocationChange(clickedIndex);
    });
  }

  onLocationChange(clickedIndex: number) {
    const loc = new LocationMetaValue(this.locations[clickedIndex].id);
    this.managerService.metaManager.setMeta(this.metaType, loc);
    this.service.onChange();
  }

}
