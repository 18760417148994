import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, forwardRef, NgModule } from "@angular/core";

import { AppComponent } from "./app.component";

import { AppRoutingModule } from "./routing/app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./race-sims/services/auth.interceptor.service";

import { AcademyComponent } from "./site/academy/academy.component";
import { ChampionshipComponent } from "./site/championship/championship.component";
import { HomeComponent } from "./site/home/home.component";
import { ContactComponent } from "./site/contact/contact.component";
import { GeneralComponent } from "./site/general/general.component";
import { SimulatorComponent } from "./site/simulator/simulator.component";
import { Stage3ProComponent } from "./site/stage3-pro/stage3-pro.component";
import { ConfiguratorComponent } from "./site/configurator/configurator.component";
import { NavComponent } from "./template/partials/nav/nav.component";
import { FooterComponent } from "./template/partials/footer/footer.component";
import { HomeLandingComponent } from "./site/home/home-landing/home-landing.component";
import { HomeAcademyComponent } from "./site/home/home-academy/home-academy.component";
import { HomeEventComponent } from "./site/home/home-event/home-event.component";
import { HomeInstagramComponent } from "./site/home/home-instagram/home-instagram.component";
import { HomePartnersComponent } from "./site/home/home-partners/home-partners.component";
import { SimulatorLandingComponent } from "./site/simulator/simulator-landing/simulator-landing.component";
import { SimulatorPhotosComponent } from "./site/simulator/simulator-photos/simulator-photos.component";
import { SimulatorTechnicalDetailsComponent } from "./site/simulator/simulator-technical-details/simulator-technical-details.component";
import { Stage3ProLandingComponent } from "./site/stage3-pro/stage3-pro-landing/stage3-pro-landing.component";
import { Stage3ProPhotosComponent } from "./site/stage3-pro/stage3-pro-photos/stage3-pro-photos.component";
import { Stage3ProTechnicalDetailsComponent } from "./site/stage3-pro/stage3-pro-technical-details/stage3-pro-technical-details.component";
import { GeneralVideosComponent } from "./site/general/general-videos/general-videos.component";
import { GeneralBusinessOfferComponent } from "./site/general/general-business-offer/general-business-offer.component";
import { BookingComponent } from "./site/booking/booking.component";
import { GeneralBuildConfiguratorComponent } from "./site/general/general-build-configurator/general-build-configurator.component";
import { AcademyLandingComponent } from "./site/academy/academy-landing/academy-landing.component";
import { AcademyDriverTrainingComponent } from "./site/academy/academy-driver-training/academy-driver-training.component";
import { AcademyEventsComponent } from "./site/academy/academy-events/academy-events.component";
import { AcademyBookingComponent } from "./site/academy/academy-booking/academy-booking.component";
import { AcademyMapComponent } from "./site/academy/academy-map/academy-map.component";
import { ChampionshipLandingComponent } from "./site/championship/championship-landing/championship-landing.component";
import { ChampionshipDriversResultsComponent } from "./site/championship/championship-drivers-results/championship-drivers-results.component";
import { ChampionshipSponsorsComponent } from "./site/championship/championship-sponsors/championship-sponsors.component";
import { ContactLandingComponent } from "./site/contact/contact-landing/contact-landing.component";
import { ContactContactsComponent } from "./site/contact/contact-contacts/contact-contacts.component";
import { ContactFormComponent } from "./site/contact/contact-form/contact-form.component";
import { ContactShowroomComponent } from "./site/contact/contact-showroom/contact-showroom.component";
import { ConfiguratorStep1Component } from "./site/configurator/configurator-step-1/configurator-step-1.component";
import { ConfiguratorStep2Component } from "./site/configurator/configurator-step-2/configurator-step-2.component";
import { ConfiguratorStep3Component } from "./site/configurator/configurator-step-3/configurator-step-3.component";
import { ConfiguratorStep4Component } from "./site/configurator/configurator-step-4/configurator-step-4.component";
import { ConfiguratorStep5Component } from "./site/configurator/configurator-step-5/configurator-step-5.component";
import { ConfiguratorStep6Component } from "./site/configurator/configurator-step-6/configurator-step-6.component";
import { AppDataProvider } from "./common/app-data.provider";
import { AppDataServcice } from "./common/services/app-data.service";
import { SimulatorFaqComponent } from "./site/simulator/simulator-faq/simulator-faq.component";
import { Stage3ProFaqComponent } from "./site/stage3-pro/stage3-pro-faq/stage3-pro-faq.component";
import { LanguageService } from "./common/services/language.service";
import { AcademyCoursesComponent } from "./site/academy/academy-courses/academy-courses.component";
import { ProductService } from "./race-sims/services/product.service";
import { ConfiguratorResolver } from "./site/configurator/configurator.resolver";
import { ConfiguratorFormManager } from "./site/configurator/configurator.form-manager";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OrderService } from "./race-sims/services/order.service";
import { BookingService } from "./race-sims/services/booking.service";
import { SimulatorVideosComponent } from "./site/simulator/simulator-videos/simulator-videos.component";
import { Stage3ProVideosComponent } from "./site/stage3-pro/stage3-pro-videos/stage3-pro-videos.component";
import { AcademyVideosComponent } from "./site/academy/academy-videos/academy-videos.component";
import { InstagramService } from "./site/home/services/instagram.service";
import { ContactFormService } from "./site/contact/services/contact-form.service";
import { CourseService } from "./common/course-bookings/services/course.service";
import { CourseBookingComponent } from "./site/course-booking/course-booking.component";
import { CourseBookingService } from "./common/course-bookings/services/course-booking.service";
import { CourseResolver } from "./site/course-booking/course.resolver";
import { ConfigImageComponent } from "./site/configurator/config-image/config-image.component";
import { BookingSettingService } from "./common/simulator-booking/services/booking-setting.service";
import { MetaDirective } from "./common/simulator-booking/meta.directive";
import { MetaDirective1 } from "./common/championship/meta.directive";
import { AmountMetaComponent } from "./site/booking/meta/amount-meta/amount-meta.component";
import { CoachMetaComponent } from "./site/booking/meta/coach-meta/coach-meta.component";
import { LiveTimingMetaComponent } from "./site/booking/meta/live-timing-meta/live-timing-meta.component";
import { CustomStickersMetaComponent } from "./site/booking/meta/custom-stickers-meta/custom-stickers-meta.component";
import { RaceTrackMetaComponent } from "./site/booking/meta/race-track-meta/race-track-meta.component";
import { CarClassMetaComponent } from "./site/booking/meta/car-class-meta/car-class-meta.component";
import { LocationMetaComponent } from "./site/booking/meta/location-meta/location-meta.component";
import { ExclusiveAmountMetaComponent } from "./site/booking/meta/exclusive-amount-meta/exclusive-amount-meta.component";
import { InDayHourlyBookingMetaComponent } from "./site/booking/meta/in-day-hourly-booking-meta/in-day-hourly-booking-meta.component";
import { DateRangeBookingMetaComponent } from "./site/booking/meta/date-range-booking-meta/date-range-booking-meta.component";
import { PriceMetaComponent } from "./site/booking/meta/price-meta/price-meta.component";
import { BookingDefinitions } from "./common/simulator-booking/booking-definitions";
import { BookingManagerProviderService } from "./common/simulator-booking/services/booking-manager-provider.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { SimulatorBookingService } from "./common/simulator-booking/services/simulator-booking.service";
import { PriceMetaService } from "./common/simulator-booking/services/meta/price-meta.service";
import { BookingServiceConnectors } from "./common/simulator-booking/booking-service-connectors";
import { AcademyRentComponent } from "./site/academy/academy-rent/academy-rent.component";
import { NoteMetaComponent } from "./site/booking/meta/note-meta/note-meta.component";
import { CateringMetaComponent } from "./site/booking/meta/catering-meta/catering-meta.component";
import { PitBoxMetaComponent } from "./site/booking/meta/pit-box/pit-box-meta.component";
import { EnergyDrinkMetaComponent } from "./site/booking/meta/energy-drink/energy-drink-meta.component";
import { ProductsComponent } from "./site/products/products.component";
import { ProductsItemsComponent } from "./site/products/products-items/products-items.component";
import { ProductsLandingComponent } from "./site/products/products-landing/products-landing.component";
import { SupportComponent } from "./site/support/support.component";
import { SupportLandingComponent } from "./site/support/support-landing/support-landing.component";
import { SupportSimulatorsComponent } from "./site/support/support-simulators/support-simulators.component";
import { SupportFormComponent } from "./site/support/support-form/support-form.component";
import { AboutUsComponent } from "./site/about-us/about-us.component";
import { AboutUsLandingComponent } from "./site/about-us/about-us-landing/about-us-landing.component";
import { AboutUsDetailsComponent } from "./site/about-us/about-us-details/about-us-details.component";
import { AboutUsHistoryComponent } from "./site/about-us/about-us-history/about-us-history.component";
import { HomeRaceCentersComponent } from "./site/home/home-race-centers/home-race-centers.component";
import { HomeSwissSimracingSeriesComponent } from './site/home/home-swiss-simracing-series/home-swiss-simracing-series.component';
import { TestDriveComponent } from './site/test-drive/test-drive.component';
import { ScienceAndEducationComponent } from './site/science-and-education/science-and-education.component';
import { SaeLandingComponent } from './site/science-and-education/sae-landing/sae-landing.component';
import { SaeModelsComponent } from './site/science-and-education/sae-models/sae-models.component';
import { SaeSoftwareComponent } from './site/science-and-education/sae-software/sae-software.component';
import { SaeUsecasesComponent } from './site/science-and-education/sae-usecases/sae-usecases.component';
import { SaeNextStepComponent } from './site/science-and-education/sae-next-step/sae-next-step.component';
import { WhatMakesTheDifferenceComponent } from './site/what-makes-the-difference/what-makes-the-difference.component';
import { WmtdLandingComponent } from './site/what-makes-the-difference/wmtd-landing/wmtd-landing.component';
import { WmtdComponentsComponent } from './site/what-makes-the-difference/wmtd-components/wmtd-components.component';
import { WmtdAdvantagesComponent } from './site/what-makes-the-difference/wmtd-advantages/wmtd-advantages.component';
import { WmtdProductsComponent } from './site/what-makes-the-difference/wmtd-products/wmtd-products.component';
import { RentYourSimComponent } from './site/rent-your-sim/rent-your-sim.component';
import { OtherProductsComponent } from './site/other-products/other-products.component';
import { ScrollToTopComponent } from './template/partials/scroll-to-top/scroll-to-top.component';

@NgModule({
    declarations: [
        AppComponent,
        AcademyComponent,
        ChampionshipComponent,
        HomeComponent,
        ContactComponent,
        GeneralComponent,
        SimulatorComponent,
        Stage3ProComponent,
        ConfiguratorComponent,
        NavComponent,
        FooterComponent,
        HomeLandingComponent,
        HomeAcademyComponent,
        HomeEventComponent,
        HomeInstagramComponent,
        HomePartnersComponent,
        SimulatorLandingComponent,
        SimulatorPhotosComponent,
        SimulatorTechnicalDetailsComponent,
        Stage3ProLandingComponent,
        Stage3ProPhotosComponent,
        Stage3ProTechnicalDetailsComponent,
        GeneralVideosComponent,
        GeneralBusinessOfferComponent,
        BookingComponent,
        GeneralBuildConfiguratorComponent,
        AcademyLandingComponent,
        AcademyDriverTrainingComponent,
        AcademyEventsComponent,
        AcademyBookingComponent,
        AcademyMapComponent,
        ChampionshipLandingComponent,
        ChampionshipDriversResultsComponent,
        ChampionshipSponsorsComponent,
        ContactLandingComponent,
        ContactContactsComponent,
        ContactFormComponent,
        ContactShowroomComponent,
        ConfiguratorStep1Component,
        ConfiguratorStep2Component,
        ConfiguratorStep3Component,
        ConfiguratorStep4Component,
        ConfiguratorStep5Component,
        ConfiguratorStep6Component,
        SimulatorFaqComponent,
        Stage3ProFaqComponent,
        AcademyCoursesComponent,
        SimulatorVideosComponent,
        Stage3ProVideosComponent,
        AcademyVideosComponent,
        CourseBookingComponent,
        ConfigImageComponent,
        MetaDirective,
        MetaDirective1,
        AmountMetaComponent,
        CoachMetaComponent,
        LiveTimingMetaComponent,
        CustomStickersMetaComponent,
        RaceTrackMetaComponent,
        CarClassMetaComponent,
        LocationMetaComponent,
        ExclusiveAmountMetaComponent,
        InDayHourlyBookingMetaComponent,
        DateRangeBookingMetaComponent,
        PriceMetaComponent,
        AcademyRentComponent,
        NoteMetaComponent,
        CateringMetaComponent,
        PitBoxMetaComponent,
        EnergyDrinkMetaComponent,
        ProductsComponent,
        ProductsItemsComponent,
        ProductsLandingComponent,
        SupportComponent,
        SupportLandingComponent,
        SupportSimulatorsComponent,
        SupportFormComponent,
        AboutUsComponent,
        AboutUsLandingComponent,
        AboutUsDetailsComponent,
        AboutUsHistoryComponent,
        HomeRaceCentersComponent,
        HomeSwissSimracingSeriesComponent,
        TestDriveComponent,
        ScienceAndEducationComponent,
        SaeLandingComponent,
        SaeModelsComponent,
        SaeSoftwareComponent,
        SaeUsecasesComponent,
        SaeNextStepComponent,
        WhatMakesTheDifferenceComponent,
        WmtdLandingComponent,
        WmtdComponentsComponent,
        WmtdAdvantagesComponent,
        WmtdProductsComponent,
        RentYourSimComponent,
        OtherProductsComponent,
        ScrollToTopComponent,
    ],
  entryComponents: [
    AmountMetaComponent,
    CoachMetaComponent,
    LiveTimingMetaComponent,
    CustomStickersMetaComponent,
    RaceTrackMetaComponent,
    CarClassMetaComponent,
    LocationMetaComponent,
    ExclusiveAmountMetaComponent,
    InDayHourlyBookingMetaComponent,
    DateRangeBookingMetaComponent,
    PriceMetaComponent,
    NoteMetaComponent,
    CateringMetaComponent,
    PitBoxMetaComponent,
    EnergyDrinkMetaComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    LanguageService,
    ProductService,
    InstagramService,
    BookingService,
    ContactFormService,
    OrderService,
    ConfiguratorFormManager,
    ConfiguratorResolver,
    CourseService,
    CourseBookingService,
    CourseResolver,
    BookingSettingService,
    BookingDefinitions,
    BookingManagerProviderService,
    SimulatorBookingService,
    {
      provide: APP_INITIALIZER,
      useFactory: (prov: AppDataProvider) => () => prov.load(),
      deps: [AppDataProvider],
      multi: true,
    },
    AppDataProvider,
    AppDataServcice,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
