import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {SiteBase} from '../../../site/site-base';
import {AppDataProvider} from '../../../common/app-data.provider';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.less']
})
export class ScrollToTopComponent extends SiteBase implements OnInit {
  windowScrolled: boolean;

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit() {
  }

}
