import {BookingDefinitions} from './booking-definitions';
import {ValidableMetaServiceInterface} from './services/meta/contracts/validable-meta-service.interface';
import {hasOwnProperty} from 'tslint/lib/utils';

export interface MetaEncapsulator {
  type: string;
  value: any;
}

export class BookingMetaManager {

  private meta: any = {};
  private registeredMetaServices: MetaEncapsulator[] = [];

  constructor() {
    // console.log('CONSTRUCTING META MNGR');
  }

  getAll() {
    return this.meta;
  }

  setAll(metaContent: any) {
    const metaClone = JSON.parse(JSON.stringify(metaContent));
    this.meta = metaClone;
  }

  getMeta<T>(name: string): T {
    if (!this.meta.hasOwnProperty(name)) {
      return undefined;
    }
    return this.meta[name];
  }

  async setMeta(name: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.meta[name] = data;
      resolve();
    });
  }

  getAmountMeta() {
    return this.getMetaFromListOfPossibleMetas(BookingDefinitions.amountMetaTypes).value;
  }

  getAmountMetaType() {
    return this.getMetaFromListOfPossibleMetas(BookingDefinitions.amountMetaTypes).type;
  }

  getCountMeta() {
    return this.getMetaFromListOfPossibleMetas(BookingDefinitions.countingMetaTypes).value;
  }

  getCountMetaType() {
    return this.getMetaFromListOfPossibleMetas(BookingDefinitions.countingMetaTypes).type;
  }

  getLocationMeta() {
    return this.getMetaFromListOfPossibleMetas(BookingDefinitions.locationMetaTypes).value;
  }

  getLocationMetaType(): string {
    return this.getMetaFromListOfPossibleMetas(BookingDefinitions.locationMetaTypes).type;
  }

  getPriceMetas(): MetaEncapsulator[] {
    return this.getMetaListFromListOfPossibleMetas(BookingDefinitions.priceMetaTypes);
  }

  getPriceMetaTypes(): string[] {
    return this.getMetaListFromListOfPossibleMetas(BookingDefinitions.priceMetaTypes).map(o => o.type);
  }

  clear(): void {
    this.meta = {};
    this.registeredMetaServices = [];
  }

  getMetaFromListOfPossibleMetas(list: string[]): MetaEncapsulator {
    for (const type of list) {
      if (this.meta.hasOwnProperty(type)) {
        return {type: type, value: this.meta[type]};
      }
    }
  }

  getMetaListFromListOfPossibleMetas(list: string[]): MetaEncapsulator[] {

    const found = [];
    for (const type of list) {
      if (this.meta.hasOwnProperty(type)) {
        found.push({type: type, value: this.meta[type]});
      }
    }

    return found;
  }

  registerService(service: any, type: string) {
    const encapsulatedService: MetaEncapsulator = {type: type, value: service};
    this.registeredMetaServices.push(encapsulatedService);
  }

  getService<T>(type: string): T {
    for (const item of this.registeredMetaServices) {
      if (item.type === type) {
        return item.value;
      }
    }
  }

  isValidable(service): service is ValidableMetaServiceInterface {
    return service.isValid !== undefined;
  }

  isValid() {
    // console.log(this.registeredMetaServices);
    for (const serviceEnc of this.registeredMetaServices) {
      if (this.isValidable(serviceEnc.value)) {
        const service = <ValidableMetaServiceInterface> serviceEnc.value;

        if (!service.isValid()) {
          // console.log(this.meta);
          return false;
        }
      }
    }
    return true;
  }
}
