import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ScriptHelper} from '../../../shared/helpers/scripts.helper';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {AppDataProvider} from '../../../common/app-data.provider';

declare const $: any;

@Component({
  selector: 'app-products-landing',
  templateUrl: './products-landing.component.html',
  styleUrls: ['./products-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsLandingComponent extends SiteBase implements OnInit, AfterViewInit {

  t = {
    title: this.translate('products.landing.title'),
    text: this.translate('products.landing.text'),
    showOptions: this.translate('general.variables.btn_show_options')
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
   
  }


}
