import {BookingDefinitions} from '../../booking-definitions';
import {BookingManagerProviderService} from '../booking-manager-provider.service';
import {CommonMetaService} from './common-meta.service';
import {AppDataProvider} from '../../../app-data.provider';
import {RaceTrackMetaValue} from '../../models/meta/values/race-track-meta-value.model';
import {ValidableMetaServiceInterface} from './contracts/validable-meta-service.interface';

interface RaceTrackMetaContract {
  key: string;
  data_source: string;
  data_source_identifier: string;
  translations: {
    en: {
      label: string;
    },
    de: {
      label: string;
    }
  };
}

export class RaceTrackMetaService extends CommonMetaService<RaceTrackMetaContract, RaceTrackMetaValue>
  implements ValidableMetaServiceInterface {

  static metaType = BookingDefinitions.metaTypes.race_track;
  static changeEventIdentifier = 'meta.' + RaceTrackMetaService.metaType + '.change';

  constructor(protected managerService: BookingManagerProviderService, private appProvider: AppDataProvider) {
    super(managerService);
    let metaVal = this.getMetaValue();
    metaVal = metaVal === undefined ? new RaceTrackMetaValue('--') : metaVal;
    this.setMetaValue(metaVal, false);
  }

  getMetaType(): string {
    return RaceTrackMetaService.metaType;
  }

  getChangeEventIdentifier(): string {
    return RaceTrackMetaService.changeEventIdentifier;
  }

  getLabel() {
    return this.getTranslatedAttr('label');
  }

  getTracks() {
    const meta = this.getMetaSetting();
    if (meta.data_source === 'settings') {
      const setting = this.appProvider.getSetting(meta.data_source_identifier);
      const tracks = JSON.parse(this.appProvider.getSetting(meta.data_source_identifier));
      tracks.unshift('--');
      return tracks;
    }
  }

  isValid() {
    return this.getMetaValue().selected_track !== undefined;
  }

}
