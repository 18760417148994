export class BookingDefinitions {

  static bookingTypes = {
    teambuilding: 'teambuilding',
    exclusive: 'exclusive',
    competition: 'competition',
    rent: 'rent',
    training: 'training'
  };


  static metaTypes = {
    pit_box: 'pit_box',
    energy_drink: 'energy_drink',
    catering: 'catering',
    coach: 'coach',
    live_timing: 'live_timing',
    custom_stickers: 'custom_stickers',
    race_track: 'race_track',
    car_class: 'car_class',
    location: 'location',
    amount: 'amount',
    exclusive_amount: 'exclusive_amount',
    in_day_hourly_booking: 'in_day_hourly_booking',
    date_range_booking: 'date_range_booking',
    price: 'price',
    note: 'note'
  };

  static amountMetaTypes = [
    BookingDefinitions.metaTypes.amount,
    BookingDefinitions.metaTypes.exclusive_amount
  ];

  static countingMetaTypes = [
    BookingDefinitions.metaTypes.in_day_hourly_booking,
    BookingDefinitions.metaTypes.date_range_booking
  ];

  static locationMetaTypes = [
    BookingDefinitions.metaTypes.location
  ];
  static priceMetaTypes = [
    BookingDefinitions.metaTypes.price,
    BookingDefinitions.metaTypes.coach,
    BookingDefinitions.metaTypes.energy_drink,
    BookingDefinitions.metaTypes.pit_box,
  ];

}
