import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SiteBase} from '../../site-base';
import {DomSanitizer} from '@angular/platform-browser';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-stage3-pro-landing',
  templateUrl: './stage3-pro-landing.component.html',
  styleUrls: ['./stage3-pro-landing.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Stage3ProLandingComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('stage3_pro.landing.title'),
    description: this.translate('stage3_pro.landing.description'),
    showFeatures: this.translate('general.variables.btn_show_features'),
    sendRequest: this.translate('general.variables.btn_send_request'),
    techDetails: this.translate('general.variables.btn_tech_details'),
    photos: this.translate('stage3_pro.photos.title'),
    videos: this.translate('stage3_pro.videos.title'),
    faq: this.translate('stage3_pro.faq.title'),
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer) {
    super(provider, sanitizer);
  }
  ngOnInit() {
  }

}
